import React from 'react';

import { Icon } from '@iconify/react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import Notification from './Notification';
import { NotificationIcon } from '../../types/enums';

const Notifications = () => {
  const { notifications } = useSelector(
    (state: RootState) => state.notifications
  );
  return (
    <Container>
      <div className='header row'>
        <Icon icon='bytesize:bell' className='bell' />
        <p className='font-size-24 text-blue title'>Notifications</p>
      </div>
      <NotificationsContainer>
        {notifications.map(notification => (
          <Notification key={notification.id} icon={NotificationIcon.WARNING} />
        ))}
      </NotificationsContainer>
    </Container>
  );
};

export default Notifications;

const Container = styled.div`
  padding-block: 29px 50px;
  & .header {
    gap: 20px;

    & .bell {
      font-size: 28px;
    }
  }
`;

const NotificationsContainer = styled.div`
  margin-top: 14px;
`;
