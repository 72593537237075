import api from '..';
import { IApiResponse, IUpdateUser } from '../../types/apiInterface';
import { IAppointement, IUser } from '../../types/interfaces';

export const getAllClients = (
  page: number,
  limit: number,
  key?: string,
  value?: string
) =>
  api.get<
    IApiResponse<{ users: IUser[]; totalPages: number; currentPage: number }>
  >(
    `/v1/user/all?page=${page}&limit=${limit}${
      key && value ? `&key=${key}&value=${encodeURIComponent(value)}` : ''
    }`
  );

export const updateClient = (clientId: string, data: IUpdateUser) =>
  api.patch(`/v1/user?id=${clientId}`, data);

export const getClientAppointments = (
  userId: string,
  page: number,
  limit: number,
  time: string,
  isPrevious: boolean
  // date: string
) =>
  api.get<
    IApiResponse<{
      appointments: IAppointement[];
      totalPages: number;
      currentPage: number;
    }>
  >(
    `/v1/appointment/user?userId=${userId}&isPrevious=${isPrevious}&time=${time}&page=${page}&limit=${limit}`
  );
