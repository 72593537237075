import React, { useState } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Icon } from '@iconify/react';
import assets from '../../../assets';
import Button from '../../../components/Button/Button';
import EditProfile from '../../../Dialogs/EditProfile/EditProfile';
import { IUser } from '../../../types/interfaces';

const Details = () => {
  const client: IUser = useLocation().state;

  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <Container>
      <div className='row space-between'>
        <div className='row inner-row'>
          <div className='img-container'>
            <img src={client.image_url || assets.preview} alt='' />
          </div>
          <div className='stats'>
            <p className='font-size-18 bold name'>
              {client.full_name} {client.last_name}
            </p>
            <Info>
              <div className='icon'>
                <Icon icon='mingcute:mail-line' className='i-icon' />
              </div>
              <p className='font-size-13 text-primary medium'>{client.email}</p>
            </Info>
            <Info>
              <div className='icon'>
                <Icon icon='ph:phone' className='i-icon' />
              </div>
              <p className='font-size-13 text-primary medium'>
                {client.mobile}
              </p>
            </Info>
            {(client.address?.length || client.city?.length) && (
              <Info>
                <div className='icon'>
                  <Icon icon='ion:location-outline' className='i-icon' />
                </div>

                <p className='font-size-13 text-primary medium'>
                  {client.address || client.city}
                </p>
              </Info>
            )}
          </div>
        </div>
        <Button label='Edit Profile' onClick={handleEdit} />
      </div>
      {edit && <EditProfile open={edit} setOpen={setEdit} user={client} />}
    </Container>
  );
};

export default Details;

const Container = styled.div`
  margin-block: 21px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 52px;
  border-radius: 6px;

  & .inner-row {
    gap: 29px;
    & .img-container {
      width: 138px;
      aspect-ratio: 1/1;
      overflow: hidden;
      background-color: black;
      border-radius: 6px;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  & .stats {
    display: flex;
    flex-direction: column;
    gap: 13.5px;

    & .name {
      line-height: 1;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & .icon {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.text.primary};
    display: grid;
    place-items: center;

    & .i-icon {
      font-size: 10px;
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;
