import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

const useNotifications = () => {
  const dispatch = useDispatch<Dispatch>();
  const { user } = useSelector((state: RootState) => state.auth);

  const handleGetNotifications = () => {
    if (user) {
      dispatch.notifications.handleGetNotifications('');
    }
  };

  useEffect(() => {
    handleGetNotifications();
    const interval = setInterval(handleGetNotifications, 300000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [user]);

  return null;
};

export default useNotifications;
