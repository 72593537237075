import React, { useState } from 'react';
import styled from 'styled-components';
import BankDetails from './BankDetails';
import JazzCash from './JazzCash';
import EasyPaisa from './EasyPaisa';
import classNames from 'classnames';

type banks = 'bank' | 'jazz-cash' | 'easy-paisa';

const Account = () => {
  const [active, setActive] = useState<banks>('bank');

  const handleChange = (b: banks) => {
    setActive(b);
  };

  return (
    <Container>
      <p className='font-size-24 medium text-primary'>
        Manage Your Account Details
      </p>
      <Tabs>
        <Tab
          className={classNames(active === 'bank' && 'active')}
          onClick={() => handleChange('bank')}
        >
          Bank Details
        </Tab>
        <Tab
          className={classNames(active === 'jazz-cash' && 'active')}
          onClick={() => handleChange('jazz-cash')}
        >
          Jazz Cash
        </Tab>
        <Tab
          className={classNames(active === 'easy-paisa' && 'active')}
          onClick={() => handleChange('easy-paisa')}
        >
          Easy Paisa
        </Tab>
      </Tabs>
      <div className='cont'>
        {active === 'bank' && <BankDetails />}
        {active === 'jazz-cash' && <JazzCash />}
        {active === 'easy-paisa' && <EasyPaisa />}
      </div>
    </Container>
  );
};

export default Account;

const Container = styled.div`
  & .cont {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 12px;
  margin-block: 30px 20px;
`;

const Tab = styled.div`
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.greyBg};
  transition: all 0.4s ease;
  border: 1px solid ${({ theme }) => theme.colors.border};
  cursor: pointer;
  &.active {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;
