import api from '..';
import { IApiResponse } from '../../types/apiInterface';
import { ICallMinutes } from '../../types/interfaces';

export const getAllCallMinutes = (
  page: number,
  limit: number,
  key?: string,
  value?: string
) =>
  api.get<
    IApiResponse<{ logs: ICallMinutes[]; totalPages: number; currentPage: number }>
  >(
    `/v1/logs/call?page=${page}&limit=${limit}${
      key && value ? `?key=${key}&value=${encodeURIComponent(value)}` : ''
    }`
  );