import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Icon } from '@iconify/react';
import Button from '../../../components/Button/Button';
import EditProfile from '../../../Dialogs/EditProfile/EditProfile';

import assets from '../../../assets';
import { IUser } from '../../../types/interfaces';

const Details: FC = () => {
  const lawyer: IUser = useLocation().state;

  const [editProfile, setEditProfile] = useState(false);

  const handleEditProfile = () => {
    setEditProfile(true);
  };

  return (
    <Container>
      <div className='main-row row space-between'>
        <Profile>
          <div className='row inner-row'>
            <div className='img-container'>
              <img src={lawyer.image_url || assets.preview} alt='' />
            </div>
            <div className='stats'>
              <p className='font-size-18 bold name'>
                {lawyer.full_name} {lawyer.last_name}
              </p>
              <Info>
                <div className='icon'>
                  <Icon icon='mingcute:mail-line' className='i-icon' />
                </div>
                <p className='font-size-13 text-primary medium'>
                  {lawyer.email}
                </p>
              </Info>
              <Info>
                <div className='icon'>
                  <Icon icon='ph:phone' className='i-icon' />
                </div>
                <p className='font-size-13 text-primary medium'>
                  {lawyer.mobile}
                </p>
              </Info>
              <Info>
                <div className='icon'>
                  <Icon icon='ion:location-outline' className='i-icon' />
                </div>
                <p className='font-size-13 text-primary medium'>
                  {lawyer.address || lawyer.city}
                </p>
              </Info>
            </div>
          </div>
          <Button
            className='btn'
            size='small'
            label='Edit Profile'
            onClick={handleEditProfile}
          />
        </Profile>
        <Experties>
          <p className='font-size-18 bold text-blue'>Lawyer Expertise</p>
          <div className='experties-container row'>
            {lawyer.categories.map(category => (
              <ExpertyChip>
                {/* <img src={assets.family} alt='' /> */}
                <p className='font-size-13 medium text-blue'>{category.name}</p>
              </ExpertyChip>
            ))}
            {/*             
            <ExpertyChip>
              <img src={assets.employee} alt='' />
              <p className='font-size-13 medium text-blue'>
                Family & Inheritance
              </p>
            </ExpertyChip>
            <ExpertyChip>
              <img src={assets.tax} alt='' />
              <p className='font-size-13 medium text-blue'>Employee & Labour</p>
            </ExpertyChip> */}
          </div>
        </Experties>
      </div>
      {editProfile && (
        <EditProfile
          open={editProfile}
          setOpen={setEditProfile}
          isLawyer
          user={lawyer}
        />
      )}
    </Container>
  );
};

export default Details;

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  padding: 40px 36px;

  & .main-row {
    gap: 30px;
    align-items: flex-start;
  }
`;

const Profile = styled.div`
  min-width: 500px;
  position: relative;
  & .inner-row {
    gap: 29px;
    & .img-container {
      width: 138px;
      aspect-ratio: 1/1;
      overflow: hidden;
      background-color: black;
      border-radius: 6px;
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  & .stats {
    display: flex;
    flex-direction: column;
    gap: 13.5px;

    & .name {
      line-height: 1;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  & .btn {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
const Experties = styled.div`
  & .experties-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 7px;
    margin-top: 21px;
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & .icon {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.text.primary};
    display: grid;
    place-items: center;

    & .i-icon {
      font-size: 10px;
      color: ${({ theme }) => theme.colors.text.primary};
    }
  }
`;

const ExpertyChip = styled.div`
  height: 44px;
  padding-inline: 18px;
  display: flex;
  align-items: center;
  gap: 7px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 7px;
`;
