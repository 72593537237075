import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Pagination from '../../components/Pagination/Pagination';

import navIcons from '../../assets/navIcons';
import { getFormatedDate } from '../../utils';
import Loading from '../../components/Loading/Loading';
import { ClipLoader } from 'react-spinners';
import theme from '../../theme';
import Select from '../../components/Select/Select';
import SearchBar from '../../components/SearchBar/SearchBar';
import Button from '../../components/Button/Button';
import classNames from 'classnames';

const CallMinutes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { callMinutes, totalPages, loading } = useSelector(
    (state: RootState) => state.callMinutes
  );

  const { isSideBarOpen } = useSelector((state: RootState) => state.utils);
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
    setSearch('');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearch(e.currentTarget.value);
  };

  const handleSearchCallMinutes = () => {
    setPage(1);
    dispatch.callMinutes.handleGetAllCallMinutes({
      page,
      limit,
      key: filter,
      value: search,
    });
  };

  useEffect(() => {
    dispatch.callMinutes.handleGetAllCallMinutes({
      page,
      limit,
    });
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (!filter) {
      handleSearchCallMinutes();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Container>
      <div className='header row'>
        <img src={navIcons.client} style={{ height: '25px' }} alt='' />
        <p className='font-size-24  title'>Call Minutes</p>
      </div>
      <div
        className='row'
        style={{ gap: '12px', height: '50px', marginTop: '30px' }}
      >
        <Select
          value={filter}
          onChange={handleFilter}
          containerClassName="select-container"
          search={true}
          options={[
            { label: filter ? 'Remove filter' : 'Select a filter', value: '' },
            { label: 'User Name', value: 'user_first_name' }, // Match backend keys
            { label: 'Lawyer Name', value: 'lawyer_first_name' },
          ]}
        />
        {filter && (
          <>
            <SearchBar value={search} onChange={handleSearch} />
            <Button
              label='Search'
              size='small'
              style={{ height: '44px', minWidth: '120px' }}
              onClick={handleSearchCallMinutes}
              loading={loading}
            />
          </>
        )}
      </div>
      {callMinutes.length === 0 && loading ? (
        <Loading />
      ) : (
        <>
          {' '}
          <TableContainer className={classNames(isSideBarOpen ? '' : 'large')}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      User Name
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>Lawyer Name</p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Start Time
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      End Time
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Minutes Calculated
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Date
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {callMinutes.map(callMinute => (
                  <tr key={callMinute.appointment_id}>
                    <td>{`${callMinute.user_first_name} ${callMinute.user_last_name}`}</td>
                    <td>{`${callMinute.lawyer_first_name} ${callMinute.lawyer_last_name}`}</td>
                    <td>{new Date(callMinute.start_time).toLocaleString()}</td>
                    <td>{new Date(callMinute.end_time).toLocaleString()}</td>
                    <td>{callMinute.total_min}</td>
                    <td>{getFormatedDate(callMinute.created_at)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {callMinutes.length > 0 && loading && (
              <Loader>
                <ClipLoader size={50} color={theme.colors.white} />
              </Loader>
            )}
          </TableContainer>
          <div style={{ marginTop: '53px' }}>
            <Pagination
              limit={limit}
              pageCount={totalPages}
              pageNumber={page}
              setPageNumber={setPage}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default CallMinutes;

const Container = styled.div`
  padding-block: 29px 50px;
  width: 100%;

  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  & .select-container {
    height: 44px;
    /* margin-top: -2px; */
  }
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 327px);
  margin-top: 19px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  position: relative;
  transition: 0s.4s ease;

  &.large {
    max-width: calc(100vw - 180px);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;

  & thead {
    & tr {
      & th {
        background-color: ${({ theme }) => theme.colors.greyBg};
        text-align: left;
        padding-block: 15px 10px;
        padding-inline: 25px;

        & input {
          margin-top: 2px;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        height: 54px;
        padding-inline: 25px;
        text-align: left;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
        font-size: 13px;
      }

      &:hover {
        & td {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:last-of-type {
        & td {
          border: none;
        }
      }
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;
