import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ICallMinutes } from '../../types/interfaces';
import axios from 'axios';
import { getAllCallMinutes } from '../../http/services/callMinutesService';

interface IState {
  loading: boolean;
  callMinutes: ICallMinutes[];
  totalPages: number;
}

export const callMinutes = createModel<RootModel>()({
  name: 'callMinutes',
  state: {
    loading: false,
    callMinutes: [],
    totalPages: 1,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      return {
        ...state,
        loading: payload,
      };
    },
    setCallMinutes(state, payload: ICallMinutes[]) {
      return {
        ...state,
        callMinutes: payload,
      };
    },
    setTotalPages(state, payload: number) {
      return {
        ...state,
        totalPages: payload,
      };
    },
  },
  effects: dispatch => ({
    async handleGetAllCallMinutes(payload: {
      page: number;
      limit: number;
      key?: string;
      value?: string;
    }) {
      const { page, limit, key, value } = payload;
      try {
        dispatch.callMinutes.setLoading(true);
        const { data } = await getAllCallMinutes(page, limit, key, value);
        dispatch.callMinutes.setCallMinutes(data.data.logs);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.error(err.response?.data.message);
        }
      } finally {
        dispatch.callMinutes.setLoading(false);
      }
    },
  }),
});
