import React, { FC } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const colors = [
  '#375D9A',
  '#FD7C05',
  '#FF5050',
  '#B071D7',
  '#00AAC0',
  '#00CCA9',
  '#964254',
  '#DA6CAD',
  '#6F8FD1',
];

interface IProps {
  colorIndex: number;
  title: string;
  count: string | number;
  path: string;
}

const Stats: FC<IProps> = ({ colorIndex, title, count, path }) => {
  return (
    <Link to={path} className='anchor'>
      <Card stroke={colors[colorIndex]}>
        <Stat>
          <p className='font-size-14 text-secondary'>{title}</p>
          <div className='row space-between'>
            <p className='count text-primary'>{count}</p>
          </div>
        </Stat>
      </Card>
    </Link>
  );
};

export default Stats;

const Card = styled.div<{ stroke: string }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  border-top: 3px solid ${({ stroke }) => stroke};
  overflow: hidden;
`;

const Stat = styled.div`
  padding-inline: 16px;
  padding-top: 16px;

  & .row {
    margin-top: 5px;
  }

  & .count {
    font-size: 48px;
  }
`;
