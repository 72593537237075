import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Button from '../../../components/Button/Button';
import assets from '../../../assets';
import TextField from '../../../components/TextField/TextField';
import UpdatePassword from '../../../Dialogs/UpdatePassword/UpdatePassword';
import { updateProfileImage } from '../../../http/services/authService';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import theme from '../../../theme';

const schema = object({
  full_name: string().required().label('Full name'),
  email: string().email().required().label('Email'),
  mobile: string().optional().label('Mobile number'),
  city: string().optional().label('City'),
  address: string().optional().min(3).label('Address'),
  gender: string()
    .required('Gender should be either male or female')
    .label('Gender'),
  last_name: string().required(),
});

const Profile = () => {
  const dispatch = useDispatch<Dispatch>();

  const { user, loading } = useSelector((state: RootState) => state.auth);

  const [updatePassword, setUpdatePassword] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);

  const [image, setImage] = useState<string | null>(null);

  const handleUpdatePassword = () => {
    setUpdatePassword(true);
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setProfileLoading(true);
      if (e.currentTarget.files?.length) {
        const file = e.currentTarget.files[0];
        setImage(URL.createObjectURL(file));
        const formData = new FormData();
        formData.append('profile', file);
        await updateProfileImage(user!.id, formData);
        dispatch.auth.handleGetCurrentUser();
      }
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data);
      }
    } finally {
      setProfileLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      mobile: '',
      city: '',
      address: '',
      gender: '',
      last_name: '1',
    },
    validationSchema: schema,
    onSubmit: values => {
      const { mobile, email, ...apiData } = values;
      dispatch.auth.handleUpdateAdminUser({ values: apiData, formik });
    },
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        full_name: user.full_name || '',
        email: user.email || '',
        mobile: user.mobile || '',
        city: user.city || '',
        address: user.address || '',
        gender: user.gender || '',
        last_name: '1',
      });
    }
    setImage(user?.image_url || null);
    // eslint-disable-next-line
  }, [user]);

  return (
    <Container>
      <p className='font-size-24 text-primary bold'>Profile</p>
      <div className='profile row space-between'>
        <div className='row inner'>
          <div className='progile-image'>
            <img src={image || assets.preview} alt='' />
          </div>
          <label htmlFor='profile-input' className='input-btn clickable'>
            {profileLoading ? (
              <ClipLoader size={20} color={theme.colors.text.primary} />
            ) : (
              'Change'
            )}
          </label>
          <input
            type='file'
            name=''
            accept='image/jpg, image/png, image/jpeg'
            id='profile-input'
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
        </div>
        <Button
          label='Update Information'
          type='submit'
          loading={loading}
          onClick={formik.handleSubmit}
        />
      </div>
      <Divider />
      <p className='font-size-18 text-primary bold'>Personal Information</p>
      <Grid>
        <TextField
          variant='secondary'
          name='full_name'
          label='Full Name'
          placeholder='Full Name'
          labelClassName='label'
          value={formik.values.full_name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          containerClassName='input'
          type='text'
          error={
            formik.touched.full_name && formik.errors.full_name
              ? formik.errors.full_name
              : ''
          }
        />
        <TextField
          variant='secondary'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          label='Email'
          placeholder='rizwansadiqisonline@gmail.com'
          labelClassName='label'
          containerClassName='input'
          type='email'
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ''
          }
        />
        <TextField
          variant='secondary'
          label='City'
          name='city'
          value={formik.values.city}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='City'
          labelClassName='label'
          containerClassName='input'
          type='text'
          error={
            formik.touched.city && formik.errors.city ? formik.errors.city : ''
          }
        />
        {/* <TextField
          variant='secondary'
          label='Address'
          name='address'
          value={formik.values.address}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder='Address'
          labelClassName='label'
          containerClassName='input'
          type='text'
          error={
            formik.touched.address && formik.errors.address
              ? formik.errors.address
              : ''
          }
        /> */}
        <GenderContainer>
          <p className='font-size-13 text-primary medium'>Gender</p>

          <div className='selector'>
            <div className='male box'>
              <input
                type='radio'
                name='gender'
                id='male'
                value='male'
                checked={formik.values.gender === 'male'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor='male'>Male</label>
            </div>
            <div className='female box'>
              <input
                type='radio'
                name='gender'
                id='female'
                value='female'
                checked={formik.values.gender === 'female'}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor='female'>Female</label>
            </div>
          </div>
          {formik.touched.gender && formik.errors.gender && (
            <p
              className='font-size-14 text-danger'
              style={{ marginTop: '4px' }}
            >
              {formik.errors.gender}
            </p>
          )}
        </GenderContainer>
        <div className='row'>
          <p
            className='font-size-16 text-blue semi-bold clickable'
            style={{ marginTop: '20px' }}
            role='button'
            onClick={handleUpdatePassword}
          >
            Change Account Password?
          </p>
        </div>
      </Grid>
      {updatePassword && (
        <UpdatePassword open={updatePassword} setOpen={setUpdatePassword} />
      )}
    </Container>
  );
};

export default Profile;

const Container = styled.div`
  & .profile {
    margin-top: 19px;

    & .row.inner {
      gap: 54px;
    }

    & .progile-image {
      height: 158px;
      width: 158px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.greyBg};
      overflow: hidden;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .input-btn {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 500;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      padding: 8px 22px;
      font-size: 13px;
      border-radius: 6px;
    }
  }
`;

const Divider = styled.div`
  margin-block: 33px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 33px 39px;
  margin-top: 23px;
  align-items: stretch;

  & .label {
    color: ${({ theme }) => theme.colors.text.primary};
    font-weight: 500 !important;
    font-size: 13px;
  }

  & .input {
    margin-top: 4px;
    border: 1px solid ${({ theme }) => theme.colors.primary + '61'}!important;
    & input {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 500;
    }
  }
`;

const GenderContainer = styled.div`
  & .selector {
    border: 1px solid ${({ theme }) => theme.colors.primary + '33'};
    height: 51px;
    border-radius: 6px;
    margin-top: 4px;
    display: flex;
    align-items: center;

    & .box {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0px 18px;
      & label {
        cursor: pointer;
      }

      & input[type='radio'] {
        appearance: none;
        -webkit-appearance: none;
        height: 19px;
        width: 19px;
        border: 1px solid ${({ theme }) => theme.colors.primary};
        border-radius: 50%;
        position: relative;

        &::before {
          content: '';
          width: 0px;
          height: 0px;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: ${({ theme }) => theme.colors.primary};
          transition: 0.3s ease;
        }

        & ~ label {
          color: ${({ theme }) => theme.colors.primary + '61'};
        }

        &:checked::before {
          height: 11px;
          width: 11px;
        }

        &:checked ~ label {
          color: ${({ theme }) => theme.colors.primary};
        }
      }

      &.male {
        border-right: 1px solid ${({ theme }) => theme.colors.primary + '33'};
      }
    }
  }
`;
