import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#173058',
    black: '#000000',
    grey: '#8A8C98',
    white: '#FFFFFF',
    border: '#A7B0C075',
    borderLight: '#EAEAEA',
    lightGrey: '#ECEEF0',
    greyBg: '#F7F7F7',
    orange: '#FF727B',
    blue: '#0F9EE1',
    text: {
      primary: '#000000',
      secondary: '#8A8C98',
      success: '#06B441',
      danger: '#FF3131',
    },
  },
  breakpoints: {
    '3xl': '1920px',
    '2xl': '1536px',
    xl: '1280px',
    lg: '1024px',
    md: '768px',
    sm: '576px',
    xs: '320px',
  },
};

export default theme;
