import React from 'react';
import { ClipLoader } from 'react-spinners';

import styled from 'styled-components';
import theme from '../../theme';

const Loading = () => {
  return (
    <Container>
      <ClipLoader size={50} color={theme.colors.primary} />
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  margin-block: 30px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
