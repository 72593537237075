import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  addHelpCategory,
  addHelpQuestion,
  addHelpSubCategory,
  deleteHelpCategory,
  deleteHelpSubCategory,
  deleteQuestion,
  getAllHelpCategories,
  getAllHelpSubCategories,
  getAllQuestions,
  updateHelpCategory,
  updateHelpSubCategory,
  updateQuestion,
} from '../../http/services/HelpService';
import { IHelpCategory, IHelpQuestion } from '../../types/interfaces';

import {
  IAddHelpCategoryPayload,
  IAddHelpSubCategoryPayload,
  IAddHelpQuestionPayload,
} from '../../types/modalInterface';
import axios from 'axios';

interface IState {
  loading: boolean;
  helpCategories: IHelpCategory[];
  helpSubCategories: IHelpCategory[];
  questions: IHelpQuestion[];
  selectedCategory: IHelpCategory | null;
  selectedSubCategory: IHelpCategory | null;
  selectedQuestion: IHelpQuestion | null;
  actionLoading: boolean;
}

export const help = createModel<RootModel>()({
  name: 'help',
  state: {
    loading: false,
    helpCategories: [],
    helpSubCategories: [],
    questions: [],
    selectedCategory: null,
    selectedSubCategory: null,
    selectedQuestion: null,
    actionLoading: false,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setHelpCategories(state, payload: IHelpCategory[]) {
      state.helpCategories = payload;
    },
    setHelpSubCategories(state, payload: IHelpCategory[]) {
      state.helpSubCategories = payload;
    },
    setQuestions(state, payload: IHelpQuestion[]) {
      state.questions = payload;
    },
    setSelectedCategory(state, payload: IHelpCategory | null) {
      state.selectedCategory = payload;
    },
    setSelectedSubCategory(state, payload: IHelpCategory | null) {
      state.selectedSubCategory = payload;
    },
    setSelectedQuestion(state, payload: IHelpQuestion | null) {
      state.selectedQuestion = payload;
    },
    setActionLoading(state, payload: boolean) {
      state.actionLoading = payload;
    },
  },
  effects: dispatch => ({
    // categories
    async handleGetAllCategories() {
      try {
        dispatch.help.setLoading(true);
        const { data } = await getAllHelpCategories();
        dispatch.help.setHelpCategories(data.data);
      } catch (err: any) {
      } finally {
        dispatch.help.setLoading(false);
      }
    },
    async handleAddHelpCategory(payload: IAddHelpCategoryPayload) {
      const { values, setOpen } = payload;
      try {
        dispatch.help.setLoading(true);
        await addHelpCategory(values);
        setOpen(false);
        dispatch.help.handleGetAllCategories();
        dispatch.utils.openSuccessModal('New category added successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          alert(err.response?.data.message);
        }
      } finally {
        dispatch.help.setLoading(false);
      }
    },

    async handleDeleteHelpCategory(id: string) {
      try {
        dispatch.help.setLoading(true);
        await deleteHelpCategory(id);
        dispatch.help.handleGetAllCategories();
        dispatch.utils.closeConfirmationModal();
      } catch (err: any) {
      } finally {
        dispatch.help.setLoading(false);
      }
    },
    async handleUpdateHelpCategory(payload: IAddHelpCategoryPayload, state) {
      const { values, setOpen } = payload;
      const id = state.help.selectedCategory!.id;
      try {
        dispatch.help.setLoading(true);
        await updateHelpCategory(values, id);
        dispatch.help.handleGetAllCategories();
        setOpen(false);
        dispatch.utils.openSuccessModal('Category updated successfully.');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.help.setLoading(false);
      }
    },

    // sub categories

    async handleGetAllSubCategories(parentId: string) {
      try {
        dispatch.help.setLoading(true);
        const { data } = await getAllHelpSubCategories(parentId);
        dispatch.help.setHelpSubCategories(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.help.setLoading(false);
      }
    },
    async handleAddSubCategory(payload: IAddHelpSubCategoryPayload) {
      const { values, setOpen } = payload;
      try {
        dispatch.help.setActionLoading(true);
        await addHelpSubCategory(values);
        dispatch.help.handleGetAllSubCategories(values.parentId);
        setOpen(false);
        dispatch.utils.openSuccessModal('New sub-category added successfully.');
      } catch (err: any) {
      } finally {
        dispatch.help.setActionLoading(false);
      }
    },
    async handleDeleteSubCategory(id: string, state) {
      try {
        dispatch.help.setActionLoading(true);
        await deleteHelpSubCategory(id);
        dispatch.help.handleGetAllSubCategories(
          state.help.selectedCategory!.id
        );
        dispatch.utils.closeConfirmationModal();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.help.setActionLoading(false);
      }
    },
    async handleUpdateSubCategory(payload: IAddHelpSubCategoryPayload, state) {
      try {
        dispatch.help.setActionLoading(true);
        const { values, setOpen } = payload;
        const id = state.help.selectedSubCategory!.id;
        await updateHelpSubCategory(values, id);
        dispatch.help.handleGetAllSubCategories(values.parentId);
        setOpen(false);
        dispatch.utils.openSuccessModal('Sub-category updated successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.help.setActionLoading(false);
      }
    },

    //  question

    async handleGetAllQuestions(categoryId: string) {
      try {
        dispatch.help.setLoading(true);
        const { data } = await getAllQuestions(categoryId);
        dispatch.help.setQuestions(data.data);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.help.setLoading(false);
      }
    },

    async handleAddQuestion(payload: IAddHelpQuestionPayload) {
      const { values, setOpen } = payload;
      try {
        dispatch.help.setLoading(true);
        await addHelpQuestion(values);
        dispatch.help.handleGetAllQuestions(values.faq_category_id);
        setOpen(false);
        dispatch.utils.openSuccessModal('New question added successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.help.setLoading(false);
      }
    },

    async handleUpdateQuestion(payload: IAddHelpQuestionPayload, state) {
      const { values, setOpen } = payload;
      try {
        const { selectedQuestion } = state.help;
        dispatch.help.setLoading(true);
        await updateQuestion(values, selectedQuestion!.id);
        dispatch.help.handleGetAllQuestions(selectedQuestion!.faq_category_id);
        setOpen(false);
        dispatch.utils.openSuccessModal('Question updated successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.help.setLoading(false);
      }
    },

    async handleDeleteQuestion(questionId: string, state) {
      try {
        const { selectedSubCategory } = state.help;
        dispatch.help.setLoading(true);
        await deleteQuestion(questionId);
        dispatch.help.handleGetAllQuestions(selectedSubCategory!.id);
        dispatch.utils.closeConfirmationModal();
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.help.setLoading(false);
      }
    },
  }),
});
