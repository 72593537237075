import React, { FC, InputHTMLAttributes } from 'react';

import styled from 'styled-components';
import assets from '../../assets';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  containerClassName?: string;
  placeholder?: string;
}

const SearchBar: FC<IProps> = props => {
  const { containerClassName, placeholder, ...rest } = props;
  return (
    <Container htmlFor='search-input' className={containerClassName}>
      <img src={assets.search} alt='' />
      <input
        type='search'
        placeholder={placeholder || 'Search Something'}
        id='search-input'
        {...rest}
      />
    </Container>
  );
};

export default SearchBar;

const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  width: 437px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  height: 46px;
  padding: 0px 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.greyBg};

  & img {
    opacity: 0.6;
  }

  &:has(input:focus),
  &:has(input:not(:placeholder-shown)) {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    & img {
      opacity: 1;
    }
  }

  & input {
    border: none;
    outline: none;
    height: 100%;
    flex-grow: 1;
    background-color: transparent;
    font-size: 12px;
    font-weight: 400;

    &::placeholder {
      color: ${({ theme }) => theme.colors.primary};
      opacity: 0.6;
    }
  }
`;
