import React from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import navIcons from '../../assets/navIcons';
import Details from './Components/Details';
import ClientHistory from './Components/ClientHistory';
import { IUser } from '../../types/interfaces';

const ClientDetails = () => {
  const client: IUser = useLocation().state;
  return (
    <Container>
      <div className='row header-row semi-bold'>
        <img src={navIcons.client} style={{ width: '22px' }} alt='' />
        <p className='font-size-24 bold title'>{client.full_name}</p>
      </div>
      <Details />
      <ClientHistory />
    </Container>
  );
};

export default ClientDetails;

const Container = styled.div`
  padding-block: 29px 50px;

  & .header-row {
    gap: 20px;

    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
