import { createModel } from '@rematch/core';
import { RootModel } from '.';
import axios from 'axios';
import { IReportType, IReports } from '../../types/interfaces';
import {
  getReportTypes,
  getReportedUsers,
} from '../../http/services/reportService';

interface IState {
  loading: boolean;
  totalPages: number;
  reports: IReports[];
  reportTypes: IReportType[];
}

export const reportedUsers = createModel<RootModel>()({
  name: 'reportedUsers',
  state: {
    loading: false,
    totalPages: 1,
    reports: [],
    reportTypes: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setTotalPages(state, payload: number) {
      state.totalPages = payload;
    },
    setReports(state, payload: IReports[]) {
      state.reports = payload;
    },
    setReportTypes(state, payload: IReportType[]) {
      state.reportTypes = payload;
    },
  },
  effects: dispatch => ({
    async handleGetReportedUsers(payload: {
      page: number;
      limit: number;
      key?: string;
      value?: string;
    }) {
      const { page, limit, key = '', value = '' } = payload;
      try {
        dispatch.reportedUsers.setLoading(true);
        const { data } = await getReportedUsers(page, limit, key, value);
        dispatch.reportedUsers.setReports(data.data.reports);
        dispatch.reportedUsers.setTotalPages(data.data.totalPages);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.reportedUsers.setLoading(false);
      }
    },
    async handleGetReportTypes() {
      try {
        dispatch.reportedUsers.setLoading(true);
        const { data } = await getReportTypes();
        dispatch.reportedUsers.setReportTypes(data.data);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.reportedUsers.setLoading(false);
      }
    },
  }),
});
