import React, { FC } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import DialogLayout from '../DialogLayout';
import Button from '../../components/Button/Button';

interface IProps {
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  onAction: () => void;
  actionLabel: string;
  loading?: boolean;
}

const ConfirmationDialog: FC<IProps> = props => {
  const { open, setOpen, message, onAction, actionLabel } = props;

  const dispatch = useDispatch<Dispatch>();

  const { loading, actionLoading } = useSelector(
    (state: RootState) => state.help
  );

  const handleClose = () => {
    if (setOpen) {
      setOpen(false);
    } else {
      dispatch.utils.closeConfirmationModal();
    }
  };

  return (
    <DialogLayout open={open}>
      <Container>
        <p className='font-size-14 semi-bold title'>{message}</p>
        <div className='row btn-row'>
          <Button
            label='Cancel'
            variant='outlined'
            className='btn'
            onClick={handleClose}
            disabled={loading || actionLoading}
          />
          <Button
            label={actionLabel}
            className='btn'
            loading={loading || actionLoading}
            onClick={onAction}
          />
        </div>
      </Container>
    </DialogLayout>
  );
};

export default ConfirmationDialog;

const Container = styled.div`
  padding: 24px 16px;
  width: 100%;
  max-width: 250px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  & .title {
    color: ${({ theme }) => theme.colors.text.primary};
  }

  & .btn-row {
    gap: 20px;
    margin-top: 34px;
    & .btn {
      flex: 1;
      min-width: 0px;
      height: 36px;
      font-size: 13px;
      font-weight: 600;
    }
  }
`;
