import React, { FC, useEffect } from 'react';

import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './theme/GlobalStyles';
import Router from './router/index';
import theme from './theme';
import { Token } from './types/enums';
import { useDispatch } from 'react-redux';
import { Dispatch } from './store';
import AgoraProvider from './contexts/AgoraContext';

const App: FC = () => {
  const token = localStorage.getItem(Token.TOKEN);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    if (token) {
      dispatch.auth.handleGetCurrentUser();
      dispatch.reportedUsers.handleGetReportTypes();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyles />
        <AgoraProvider>
          <Router />
        </AgoraProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
