import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { AgoraChat } from 'agora-chat';
import { IAgoraUserInfo, IUser } from '../../types/interfaces';
import axios from 'axios';
import { getChatUsers } from '../../http/services/userService';

interface IState {
  conversations: AgoraChat.ConversationItem[];
  users: IAgoraUserInfo | null;
  selectedChat: AgoraChat.ConversationItem | null;
  chatUsers: IUser[];
  loading: boolean;
  selected: IUser | null;
}

export const chat = createModel<RootModel>()({
  name: 'chat',
  state: {
    conversations: [],
    users: null,
    selectedChat: null,
    selected: null,
    chatUsers: [],
    loading: false,
  } as IState,
  reducers: {
    setConversations(state, payload: AgoraChat.ConversationItem[]) {
      state.conversations = payload;
    },
    setUsers(state, payload: IAgoraUserInfo | null) {
      state.users = payload;
    },
    setSelectedChat(state, payload: AgoraChat.ConversationItem | null) {
      state.selectedChat = payload;
    },
    setChatUsers(state, payload: IUser[]) {
      state.chatUsers = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setSelected(state, payload: IUser | null) {
      state.selected = payload;
    },
  },

  effects: dispatch => ({
    async HandleGetChatUsers() {
      try {
        dispatch.chat.setLoading(true);
        const { data } = await getChatUsers();
        dispatch.chat.setChatUsers(data.data);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data);
        }
      } finally {
        dispatch.chat.setLoading(false);
      }
    },
  }),
});
