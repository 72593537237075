import React, { useEffect } from 'react';

import { Icon } from '@iconify/react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';

import Notification from './Notification';
import { NotificationIcon } from '../../types/enums';
import dayjs from 'dayjs';

import { INotification } from '../../types/interfaces';

const Notifications = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector(
    (state: RootState) => state.notifications
  );

  useEffect(() => {
    dispatch.notifications.markAllAsSeen();
  }, [dispatch]);

  const groupNotifications = (notifications: INotification[]) => {
    const today = dayjs().startOf('day');
    const yesterday = dayjs().subtract(1, 'day').startOf('day');

    return {
      today: notifications.filter(notification =>
        dayjs(notification.created_at).isSame(today, 'day')
      ),
      yesterday: notifications.filter(notification =>
        dayjs(notification.created_at).isSame(yesterday, 'day')
      ),
      older: notifications.filter(notification =>
        dayjs(notification.created_at).isBefore(yesterday, 'day')
      ),
    };
  };

  const iconMapper = (title: string): NotificationIcon => {
    if (
      [
        'New Appointment Booked',
        'Appointment Rescheduled',
      ].includes(title)
    ) {
      return NotificationIcon.EVENT;
    }

    if (
      [
        'Payment Confirmation for Appointment',
        'Payment Request Withdrawal by Lawyer',
        'User Payment Received',
        'Lawyer Verification Request',
      ].includes(title)
    ) {
      return NotificationIcon.USER;
    }

    if (
      [
        'User Reports a Lawyer',
        'OTP Verification for Account Deactivation',
        'Account Reactivation Request',
      ].includes(title)
    ) {
      return NotificationIcon.WARNING;
    }

    return NotificationIcon.WARNING;
  };

  const groupedNotifications = groupNotifications(notifications);

  const NotificationSection = ({
    title,
    notifications,
  }: {
    title: string;
    notifications: INotification[];
  }) => (
    <>
      <SectionHeader>{title}</SectionHeader>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          data={notification}
          icon={iconMapper(notification.title)}
        />
      ))}
    </>
  );

  return (
    <Container>
      <div className='header row'>
        <Icon icon='bytesize:bell' className='bell' />
        <p className='font-size-24 text-blue title'>Notifications</p>
      </div>
      <NotificationsContainer>
        {groupedNotifications.today.length > 0 && (
          <NotificationSection
            title="Today"
            notifications={groupedNotifications.today}
          />
        )}
        {groupedNotifications.yesterday.length > 0 && (
          <NotificationSection
            title="Yesterday"
            notifications={groupedNotifications.yesterday}
          />
        )}
        {groupedNotifications.older.length > 0 && (
          <NotificationSection
            title="Older"
            notifications={groupedNotifications.older}
          />
        )}
        {notifications.length === 0 && (
          <EmptyState>No notifications yet!</EmptyState>
        )}
      </NotificationsContainer>
    </Container>
  );
};

export default Notifications;

const Container = styled.div`
  padding-block: 29px 50px;
  & .header {
    gap: 20px;

    & .bell {
      font-size: 28px;
    }
  }
`;

const NotificationsContainer = styled.div`
  margin-top: 14px;
`;

const SectionHeader = styled.p`
  margin-top: 20px;
`;

const EmptyState = styled.p`
  text-align: center;
  color: #777;
  font-size: 16px;
  margin-top: 20px;
`;
