import api from '..';
import {
  IAddHelpCategoryData,
  IAddHelpQuestionData,
  IAddHelpSubCategoryData,
  IApiResponse,
} from '../../types/apiInterface';
import { IHelpCategory, IHelpQuestion } from '../../types/interfaces';

// categories

export const addHelpCategory = (data: IAddHelpCategoryData) =>
  api.post('/v1/faq-category', data);

export const getAllHelpCategories = () =>
  api.get<IApiResponse<IHelpCategory[]>>('/v1/faq-category');

export const deleteHelpCategory = (id: string) =>
  api.delete(`/v1/faq-category/${id}`);

export const updateHelpCategory = (data: IAddHelpCategoryData, id: string) =>
  api.patch(`/v1/faq-category/${id}`, data);

// sub categories

export const getAllHelpSubCategories = (parentId: string) =>
  api.get<IApiResponse<IHelpCategory[]>>(`/v1/faq-category/${parentId}/sub`);

export const addHelpSubCategory = (data: any) =>
  api.post('/v1/faq-category/sub', data);

export const deleteHelpSubCategory = (id: string) =>
  api.delete(`/v1/faq-category/sub/${id}`);

export const updateHelpSubCategory = (
  data: IAddHelpSubCategoryData,
  id: string
) => api.patch(`/v1/faq-category/sub/${id}`, data);

// Questions

export const getAllQuestions = (categoryId: string) =>
  api.get<IApiResponse<IHelpQuestion[]>>(`/v1/faqs/all/${categoryId}`);

export const addHelpQuestion = (data: IAddHelpQuestionData) =>
  api.post(`/v1/faqs`, data);

export const deleteQuestion = (questionId: string) =>
  api.delete(`/v1/faqs/${questionId}`);

export const updateQuestion = (
  data: IAddHelpQuestionData,
  questionId: string
) => api.patch(`/v1/faqs/${questionId}`, data);
