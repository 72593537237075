import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import navIcons from '../../assets/navIcons';
import Stats from '../Dashboard/components/Stats';
import { formatNumber } from '../../utils';
import Pagination from '../../components/Pagination/Pagination';
import { getEarnings } from '../../http/services/paymentService';
import { ITransaction } from '../../types/interfaces';

const Earnings = () => {
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10);
  const [totalPages, setTotalPages] = React.useState(10);

  const [data, setData] = useState({
    total: 0,
    weekly: 0,
    monthly: 0,
  });

  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  const handleFetchEarnings = async () => {
    try {
      const { data } = await getEarnings({ page, limit });
      setData({
        total: data.data.total_earnings,
        weekly: data.data.weekly_earnings,
        monthly: data.data.monthly_earnings,
      });
      setTotalPages(data.data.totalPages);
      setTransactions(data.data.transactions);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleFetchEarnings();
    // eslint-disable-next-line
  }, [page]);
  return (
    <Container>
      <div className='header row'>
        <img src={navIcons.payment} style={{ height: '25px' }} alt='' />
        <p className='font-size-24 title'>Earnings</p>
      </div>
      <TilesGrid>
        <Stats
          colorIndex={0}
          title='Total Earnings'
          count={`PKR ${formatNumber(data.total)}`}
        />
        <Stats
          colorIndex={4}
          title='Last Week'
          count={`PKR ${formatNumber(data.weekly)}`}
        />
        <Stats
          colorIndex={8}
          title='Last Month'
          count={`PKR ${formatNumber(data.monthly)}`}
        />
      </TilesGrid>

      <p className='font-size-24 bold text-blue' style={{ marginTop: '30px' }}>
        Payments Received
      </p>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>
                <p className='font-size-14 text-primary semi-bold'>Name</p>
              </th>
              <th>
                <p className='font-size-14 text-primary semi-bold'>
                  Account Name
                </p>
              </th>
              <th>
                <p className='font-size-14 text-primary semi-bold'>Amount</p>
              </th>
              <th>
                <p className='font-size-14 text-primary semi-bold'>
                  Payment Method
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map(tx => (
              <tr key={tx.id}>
                <td>{tx.full_name}</td>
                <td>
                  <p>{tx.account_name}</p>
                </td>
                <td>PKR {formatNumber(tx.amount)}</td>
                <td>{tx.bank_name}</td>
              </tr>
            ))}
            {/* {lawyers.map(lawyer => (
                  <LawyerRow
                    lawyer={lawyer}
                    key={lawyer.id}
                    handleClick={handleClick}
                  />
                ))} */}
          </tbody>
        </Table>
      </TableContainer>
      <div style={{ marginTop: '53px' }}>
        <Pagination
          limit={limit}
          setPageNumber={setPage}
          pageNumber={page}
          pageCount={totalPages}
        />
      </div>
    </Container>
  );
};

export default Earnings;

const Container = styled.div`
  padding-block: 29px 50px;
  width: 100%;
  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const TilesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  gap: 19px;
  margin-top: 20px;
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 327px);
  margin-top: 19px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  position: relative;
  transition: 0s.4s ease;

  &.large {
    max-width: calc(100vw - 180px);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;

  & thead {
    & tr {
      & th {
        background-color: ${({ theme }) => theme.colors.greyBg};
        text-align: left;
        padding-block: 15px 10px;
        padding-inline: 25px;

        & input {
          margin-top: 2px;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        height: 54px;
        padding-inline: 25px;
        text-align: left;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
        font-size: 13px;

        & .user-profile {
          height: 34px;
          width: 34px;
          border-radius: 7px;
          object-fit: cover;
        }
      }

      &:hover {
        & td {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:last-of-type {
        & td {
          border: none;
        }
      }
    }
  }
`;
