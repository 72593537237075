import { FC, useState } from 'react';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';

import ConfirmationDialog from '../../Dialogs/ConfirmationDialog/ConfirmationDialog';

import assets from '../../assets';
import navIcons from '../../assets/navIcons';
import { ROUTES } from '../../constants';

const ProfileMenu: FC = () => {
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();

  const handleToggleMenu = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleProfileClick = () => {
    navigate(ROUTES.SETTINTS);
    handleClose();
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Container>
      <div
        className='user-profile clickable'
        role='button'
        onClick={handleToggleMenu}
      >
        <img src={user?.image_url || assets.preview} alt='' />
      </div>
      <div>
        <p className='font-size-16 medium text-primary'>{user?.full_name}</p>
        <p className='font-size-12 text-secondary'>Administrator</p>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu>
            <div className='menu-item row' onClick={handleProfileClick}>
              <div className='icon row'>
                <img src={assets.user} style={{ width: '11px' }} alt='' />
              </div>
              <p className='font-size-14'>Profile</p>
            </div>
            <div
              className='menu-item row'
              role='button'
              onClick={() => setOpenConfirmation(true)}
            >
              <div className='icon row'>
                <img src={navIcons.logout} style={{ width: '14px' }} alt='' />
              </div>
              <p className='font-size-14'>Log Out</p>
            </div>
          </Menu>
        </ClickAwayListener>
      )}
      {openConfirmation && (
        <ConfirmationDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          actionLabel='Logout'
          onAction={handleLogout}
          message='Are you sure you want to
      Logout?'
        />
      )}
    </Container>
  );
};

export default ProfileMenu;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;

  & .text-secondary {
    margin-top: 2px;
  }

  .user-profile {
    height: 46px;
    width: 46px;
    border-radius: 7px;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

const Menu = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 162px;
  background-color: ${({ theme }) => theme.colors.white};
  transform: translateY(calc(100% + 5px));
  border-radius: 6px;
  box-shadow: 1px 0.5px 8px 0px rgba(0, 0, 0, 0.1);
  padding-block: 19px;

  & .menu-item {
    gap: 15px;
    padding: 6px 19px;
    opacity: 0.6;
    cursor: pointer;
    & p {
      color: ${({ theme }) => theme.colors.primary};
    }
    & .icon {
      width: 14px;
      justify-content: center;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.greyBg};
      opacity: 1;
    }
  }
`;
