import api from '..';
import {
  IApiResponse,
  IUpcomingAppointmentsResponse,
  IUserInvoicesTrResponse,
} from '../../types/apiInterface';

export const getAllUpcomingAppointments = (data: {
  time: string;
  page: number;
  limit: number;
  key?: string;
  value?: string;
  val?: string;
}) =>
  api.get<IApiResponse<IUpcomingAppointmentsResponse>>(
    `/v1/appointment/upcoming?time=${data.time}&page=${data.page}&limit=${
      data.limit
    }${data.key && data.value ? `&key=${data.key}&value=${data.value}` : ''}${
      data.val ? `&filter=${data.val}` : ''
    }`
  );

export const rescheduleAppointment = (id: string, data: any) =>
  api.patch(`/v1/appointment/re-schedule/${id}`, data);

export const getAppointmentTransactions = (data: {
  page: number;
  limit: number;
  status: 4 | 0;
  key?: string;
  value?: string;
}) =>
  api.get<IApiResponse<IUserInvoicesTrResponse>>(
    `/v1/appointment-transaction?status=${data.status}&page=${
      data.page
    }&limit=${data.limit}${
      data.key && data.value
        ? `&key=${data.key}&value=${encodeURIComponent(data.value)}`
        : ''
    }`
  );

export const updateAppointMentStatus = (data: {
  appiontmentId: string;
  status: number;
}) =>
  api.patch(`/v1/appointment/${data.appiontmentId}`, { status: data.status });
