import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IConfirmationModal {
  message: string;
  action: () => void | Promise<void>;
  actionTitle: string;
}

interface IState {
  confirmationModal: boolean;
  confirmationMessage: string;
  confirmationAction: () => void;
  confirmationActionTitle: string;
  successMessage: string;
  successModal: boolean;
  isSideBarOpen: boolean;
}

export const utils = createModel<RootModel>()({
  name: 'utils',
  state: {
    confirmationModal: false,
    confirmationMessage: '',
    confirmationAction: () => {},
    confirmationActionTitle: '',
    successMessage: '',
    successModal: false,
    isSideBarOpen: true,
  } as IState,
  reducers: {
    openConfirmationModal(state, payload: IConfirmationModal) {
      state.confirmationModal = true;
      state.confirmationMessage = payload.message;
      state.confirmationAction = payload.action;
      state.confirmationActionTitle = payload.actionTitle;
    },
    closeConfirmationModal(state) {
      state.confirmationModal = false;
      state.confirmationMessage = '';
      state.confirmationAction = () => {};
      state.confirmationActionTitle = '';
    },
    openSuccessModal(state, payload: string) {
      state.successMessage = payload;
      state.successModal = true;
    },
    closeSuccessModal(state) {
      state.successMessage = '';
      state.successModal = false;
    },
    setSideBarOpen(state, payload: boolean) {
      state.isSideBarOpen = payload;
    },
  },
});
