import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import Pagination from '../../../components/Pagination/Pagination';
import StatusChip from '../../../components/StatusChip/StatusChip';
import assets from '../../../assets';
import { IAppointement, IUser } from '../../../types/interfaces';
import axios from 'axios';
import { getClientAppointments } from '../../../http/services/clientService';
import { getCurrentTime } from '../../../utils';
import Loading from '../../../components/Loading/Loading';
import { ClipLoader } from 'react-spinners';
import theme from '../../../theme';
import classNames from 'classnames';

const ClientHistory = () => {
  const client: IUser = useLocation().state;

  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [time] = useState(getCurrentTime());
  const [totalPages, setTotalPages] = useState(0);

  const [appointments, setAppointments] = useState<IAppointement[]>([]);

  const { isSideBarOpen } = useSelector((state: RootState) => state.utils);

  const handleGetAppointments = async () => {
    try {
      setLoading(true);
      const { data } = await getClientAppointments(
        client.id,
        page,
        limit,
        time,
        true
      );
      setAppointments(data.data.appointments);
      setTotalPages(data.data.totalPages);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const getStatus = (status: number) => {
    switch (status) {
      case 0:
        return 'pending';
      case 1:
        return 'in progress';
      case 2:
        return 'completed';
      case 3:
        return 'canceled';
      default:
        return 'pending';
    }
  };

  useEffect(() => {
    handleGetAppointments();
    // eslint-disable-next-line
  }, [page]);

  return (
    <>
      {appointments.length === 0 && !loading ? (
        <></>
      ) : (
        <Container>
          <p className='font-size-24 title'>Client History</p>
          {appointments.length === 0 && loading ? (
            <Loading />
          ) : (
            <>
              <TableContainer
                className={classNames(isSideBarOpen ? '' : 'large')}
              >
                <Table>
                  <thead>
                    <tr>
                      <th>
                        <p className='font-size-14 text-primary semi-bold'>
                          Client Name
                        </p>
                      </th>
                      <th>
                        <p className='font-size-14 text-primary semi-bold'>
                          Lawyer Name
                        </p>
                      </th>
                      <th>
                        <p className='font-size-14 text-primary semi-bold'>
                          Case Type
                        </p>
                      </th>
                      <th>
                        <p className='font-size-14 text-primary semi-bold'>
                          Transaction
                        </p>
                      </th>
                      <th>
                        <p className='font-size-14 text-primary semi-bold'>
                          Transaction Method
                        </p>
                      </th>
                      <th>
                        <p className='font-size-14 text-primary semi-bold'>
                          Booking Status
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {appointments.map(appointment => (
                      <tr key={appointment.id}>
                        <td>{client.full_name}</td>
                        <td>{appointment.full_name}</td>
                        <td>{appointment.category_name}</td>
                        <td>5,000 PKR</td>
                        <td>
                          <div className='row' style={{ gap: '8px' }}>
                            <div className='icon row'>
                              <img src={assets.visa} alt='' />
                            </div>
                            <span style={{ font: 'inherit' }}>Visa Card</span>
                          </div>
                        </td>
                        <td>
                          <StatusChip label={getStatus(appointment.status)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {appointments.length > 0 && loading && (
                  <Loader>
                    <ClipLoader size={50} color={theme.colors.white} />
                  </Loader>
                )}
              </TableContainer>
              <div style={{ marginTop: '53px' }}>
                <Pagination
                  limit={limit}
                  pageCount={totalPages}
                  pageNumber={page}
                  setPageNumber={setPage}
                />
              </div>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default ClientHistory;

const Container = styled.div`
  & .title {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 327px);
  margin-top: 19px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  position: relative;
  transition: 0s.4s ease;

  &.large {
    max-width: calc(100vw - 200px);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;

  & thead {
    & tr {
      & th {
        background-color: ${({ theme }) => theme.colors.greyBg};
        text-align: left;
        padding-block: 15px 10px;
        padding-inline: 25px;

        & input {
          margin-top: 2px;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        height: 54px;
        padding-inline: 25px;
        text-align: left;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
        font-size: 13px;

        & .icon {
          width: 40px;
          height: 24px;
          border: 1px solid ${({ theme }) => theme.colors.borderLight};
          border-radius: 4px;
          display: grid;
          place-content: center;
        }
      }

      &:hover {
        & td {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:last-of-type {
        & td {
          border: none;
        }
      }
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;
