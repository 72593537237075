import React from 'react';

import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { InferType, object, string } from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';

import assets from '../../assets';

const schema = object({
  email: string().email().required().label('Email'),
});

export interface IForgotPasswordInitialValues
  extends InferType<typeof schema> {}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { loading } = useSelector((state: RootState) => state.auth);

  const formik = useFormik<IForgotPasswordInitialValues>({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch.auth.handleForgotPassword({ values, formik, navigate });
    },
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Content>
        <div className='row space-between w-100'>
          <div
            className='back-icon clickable'
            role='button'
            onClick={handleGoBack}
          >
            <img src={assets.arrowLeft} alt='' />
          </div>
          <p className='font-size-25 title'>Forgot Password ?</p>
          <div />
        </div>
        <p className='font-size-14 text-primary'>Please enter your email</p>
        <Form onSubmit={e => e.preventDefault()}>
          <TextField
            type='email'
            variant='secondary'
            placeholder='Email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            leftIcon={<img src={assets.mail} alt='' />}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
          />
          <div className='row justify-center'>
            <Button
              label='Next'
              type='submit'
              onClick={formik.handleSubmit}
              loading={loading}
              rightIcon={
                <Icon
                  icon='lets-icons:arrow-right'
                  style={{ fontSize: '25px' }}
                />
              }
            />
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default ForgotPassword;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background-image: url(${assets.lawBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  padding-block: 55px 61px;
  padding-inline: 106px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  & .title {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
