import { AxiosRequestConfig } from 'axios';
import api from '..';
import {
  IApiResponse,
  IChangePassword,
  IForgotPasswordData,
  IForgotPasswordResponse,
  ILoginData,
  ILoginResponse,
  IResetPasswordData,
  IUpdateUser,
} from '../../types/apiInterface';
import { ILawyer, IUser } from '../../types/interfaces';

export const login = (data: ILoginData, config: AxiosRequestConfig) =>
  api.post<ILoginResponse>('/v1/auth/login', data, config);

export const forgotPassword = (
  data: IForgotPasswordData,
  config?: AxiosRequestConfig
) =>
  api.post<IApiResponse<IForgotPasswordResponse>>(
    '/v1/auth/forgot-password',
    data,
    config
  );

export const resetPassword = (
  data: IResetPasswordData,
  token: string,
  config?: AxiosRequestConfig
) => api.post(`/v1/auth/recover-password/${token}`, data, config);

export const updateAdminUser = (data: IUpdateUser, id: string) =>
  api.patch(`/v1/user?id=${id}`, data);

export const changePassword = (data: IChangePassword) =>
  api.patch('/v1/user/change-password', data);

export const updateProfileImage = (id: string, data: FormData) =>
  api.patch(`/v1/user/image?id=${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getAgoraChatToken = () =>
  api.get<IApiResponse<string>>('/v1/chat/token');

export const getUserById = (userId: string) =>
  api.get<IApiResponse<IUser | ILawyer>>(`/v1/user/${userId}`);

export const getImageUrl = (url: string) =>
  api.post<IApiResponse<string>>('/v1/image', { image_url: url });
