import { createModel } from '@rematch/core';
import { RootModel } from '.';
import axios from 'axios';
import { getAllNotifications } from '../../http/services/notificationService';
import { INotification } from '../../types/interfaces';

interface IState {
  loading: boolean;
  notifications: INotification[];
  unreadCount: number;
}

export const notifications = createModel<RootModel>()({
  name: 'notifications',
  state: {
    loading: false,
    notifications: [],
    unreadCount: 0,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setNotifications(state, payload: INotification[]) {
      state.notifications = payload;
    },
    setUnreadCount(state, payload: number) {
      state.unreadCount = payload;
    },
  },
  effects: dispatch => ({
    async handleGetNotifications(_, state) {
      try {
        const { user } = state.auth;
        dispatch.notifications.setLoading(true);
        const { data } = await getAllNotifications(user!.id);
        dispatch.notifications.setNotifications(data.data.notifications);
        dispatch.notifications.setUnreadCount(data.data.count);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.notifications.setLoading(false);
      }
    },
  }),
});
