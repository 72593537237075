import dayjs from 'dayjs';

export const getFormatedHours = (time: string) => {
  const dummyDate = '2000-01-01';
  return dayjs(`${dummyDate} ${time}`).format('hh:mm A');
};

export const getCurrentTime = (): string => {
  return dayjs().format('HH:mm');
};

export const getFormatedDate = (date?: string) => {
  return dayjs(date).format('ddd, MMM DD YYYY').toString();
};

export const getEndTime = (endHours: string): string => {
  let [hours, minutes] = endHours.split(':').map(Number);
  const currentTime = new Date();
  currentTime.setHours(hours);
  currentTime.setMinutes(minutes);
  currentTime.setMinutes(currentTime.getMinutes() + 30);
  const resultTimeString = `${String(currentTime.getHours()).padStart(
    2,
    '0'
  )}:${String(currentTime.getMinutes()).padStart(2, '0')}`;

  return resultTimeString;
};

type DebounceFunction = (...args: any[]) => void;

export const debounce = <T extends DebounceFunction>(
  func: T,
  delay: number
): T => {
  let timerId: NodeJS.Timeout;

  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  } as T;
};

export const formatNumber = (number: number): string => {
  if (Math.abs(number) < 1000) {
    return number.toString();
  } else if (Math.abs(number) < 1000000) {
    return (number / 1000).toFixed(1) + 'K';
  } else if (Math.abs(number) < 1000000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else {
    return (number / 1000000000).toFixed(1) + 'B';
  }
};
