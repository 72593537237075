import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ILawyer } from '../../types/interfaces';
import axios from 'axios';

import {
  IGetLawyersPayload,
  IUpdateLawyerPayload,
} from '../../types/modalInterface';
import {
  getAllLawyers,
  updateLawyer,
  updateLawyerCategory,
} from '../../http/services/lawyerService';
import { getUserById } from '../../http/services/authService';
import { ROUTES } from '../../constants';
interface IState {
  loading: boolean;
  lawyers: ILawyer[];
  totalPages: number;
}

export const lawyers = createModel<RootModel>()({
  name: 'lawyers',
  state: {
    loading: false,
    lawyers: [],
    totalPages: 1,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setLawyers(state, payload: ILawyer[]) {
      state.lawyers = payload;
    },
    setTotalPages(state, payload: number) {
      state.totalPages = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllLawyers(payload: IGetLawyersPayload) {
      const { page, limit, key = '', value = '' } = payload;
      try {
        dispatch.lawyers.setLoading(true);
        const { data } = await getAllLawyers(page, limit, key, value);
        dispatch.lawyers.setLawyers(data.data.lawyers);
        dispatch.lawyers.setTotalPages(data.data.totalPages);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.lawyers.setLoading(false);
      }
    },
    async handleUpdateLawyer(payload: IUpdateLawyerPayload) {
      const { values, setOpen, navigate, id } = payload;
      try {
        dispatch.lawyers.setLoading(true);
        await updateLawyer(id, values);
        setOpen(false);

        const { data } = await getUserById(id);
        navigate(ROUTES.LAWYER_DETAILS.replace(':id', id), {
          state: data.data,
          replace: true,
        });
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.lawyers.setLoading(false);
      }
    },
    async handleUpdateLawyerCategories(payload: {
      categories: string[];
      lawyerId: string;
    }) {
      const { categories, lawyerId } = payload;
      try {
        dispatch.lawyers.setLoading(true);
        await updateLawyerCategory(categories, lawyerId);
        dispatch.utils.openSuccessModal('Profile updated successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.lawyers.setLoading(false);
      }
    },
  }),
});
