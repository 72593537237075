import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import navIcons from '../../assets/navIcons';
import SearchBar from '../../components/SearchBar/SearchBar';
import Select from '../../components/Select/Select';
import Invoice from './components/Invoice';
import Pagination from '../../components/Pagination/Pagination';
import classNames from 'classnames';
import Loading from '../../components/Loading/Loading';
import Button from '../../components/Button/Button';
import ImageViewDialog from '../../Dialogs/ImageViewDialog/ImageViewDialog';

const UserInvoices = () => {
  const dispatch = useDispatch<Dispatch>();
  const [active, setActive] = useState<4 | 0>(0);

  const [page, setPage] = useState(1);

  const [limit] = useState(10);
  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  const { transactions, totalPages, pending, approved, loading } = useSelector(
    (state: RootState) => state.appointmentTransations
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleTab = (index: 4 | 0) => {
    setActive(index);
  };
  const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
    setSearch('');
  };

  const handleSearchInvoices = () => {
    setPage(1);
    dispatch.appointmentTransations.handleGetAllTransactions({
      page: page,
      limit,
      status: active,
      key: filter,
      value: search,
    });
  };

  const handleFetchInvoices = () => {
    dispatch.appointmentTransations.handleGetAllTransactions({
      page,
      limit,
      status: active,
    });
  };

  useEffect(() => {
    handleSearchInvoices();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (page > 1) {
      setPage(1);
    } else {
      handleSearchInvoices();
    }
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    if (!filter) {
      handleFetchInvoices();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Container>
      <div className='row space-between'>
        <div className='row header'>
          <img src={navIcons.invoice} style={{ width: '20px' }} alt='' />
          <p className='font-size-24 title'>Appointment Invoices</p>
        </div>
      </div>
      <ButtonsRow>
        <div
          className={classNames('btn clickable', active === 0 && 'filled')}
          role='button'
          onClick={() => handleTab(0)}
        >
          <p className='font-size-14 semi-bold'>
            Pending <span style={{ fontWeight: '400' }}>({pending})</span>
          </p>
        </div>
        <div
          className={classNames('btn clickable', active === 4 && 'filled')}
          role='button'
          onClick={() => handleTab(4)}
        >
          <p className='font-size-14 semi-bold'>
            Approved <span style={{ fontWeight: '400' }}>({approved})</span>
          </p>
        </div>
      </ButtonsRow>
      <div className='row' style={{ gap: '16px' }}>
        <Select
          value={filter}
          onChange={handleFilter}
          containerClassName='select-container'
          search={true}
          options={[
            { label: 'Remove filters', value: '' },
            { label: 'Invoice id', value: 'invoice_id' },
            { label: 'Client name', value: 'client_name' },
            { label: 'Client email', value: 'client_email' },
            { label: 'Client mobile', value: 'client_mobile' },
            { label: 'Lawyer name', value: 'lawyer_name' },
            { label: 'Lawyer email', value: 'lawyer_email' },
            { label: 'Lawyer mobile', value: 'lawyer_mobile' },
          ]}
        />
        {filter && (
          <>
            <SearchBar value={search} onChange={handleSearch} />
            <Button
              label='Search'
              size='small'
              style={{ height: '44px', minWidth: '120px' }}
              onClick={handleSearchInvoices}
              loading={loading}
            />
          </>
        )}
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <InvoicesContainer>
            {transactions.map(tr => (
              <Invoice
                key={tr.id}
                tr={tr}
                handleSearchInvoices={handleSearchInvoices}
                setSelectedImage={setSelectedImage}
              />
            ))}
          </InvoicesContainer>
          <div className='pagination'>
            <Pagination
              pageCount={totalPages}
              pageNumber={page}
              limit={limit}
              setPageNumber={setPage}
            />
          </div>
        </>
      )}
      {selectedImage && (
        <ImageViewDialog
          open={!!selectedImage}
          close={() => setSelectedImage('')}
          image={selectedImage}
        />
      )}
    </Container>
  );
};

export default UserInvoices;

const Container = styled.div`
  padding-block: 29px 30px;

  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  & .btn {
    padding-inline: 34px;
  }

  & .pagination {
    margin-top: 35px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  margin-block: 26px 23px;

  & .btn {
    height: 43px;
    min-width: 169px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 6px;

    color: ${({ theme }) => theme.colors.primary};
    transition: 0.4s ease;

    &.filled {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const InvoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 23px;
  gap: 11px;
`;
