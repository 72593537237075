import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Stats from './components/Stats';
import { ROUTES } from '../../constants';
import { formatNumber } from '../../utils';
// import StatCard from './components/StatCard';
// import RecentActivity from './components/RecentActivity';
// import ActiveUsersChart from './components/ActiveUsersChart';
// import NewAppointments from './components/NewAppointments';

const Dashboard = () => {
  const dispatch = useDispatch<Dispatch>();

  const { stats } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch.auth.handleGetStats();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Grid>
        <Stats
          colorIndex={0}
          title='Clients'
          count={stats?.users || 0}
          path={ROUTES.CLIENTS}
        />
        <Stats
          colorIndex={1}
          title='Lawyers'
          count={stats?.lawyers || 0}
          path={ROUTES.LAWYERS}
        />
        <Stats
          colorIndex={2}
          title='Unverified Lawyers'
          count={stats?.unverifiedLawyers || 0}
          path={ROUTES.LAWYERS}
        />
        <Stats
          colorIndex={3}
          title='Appointments'
          count={stats?.appointments || 0}
          path={ROUTES.UPCOMING_APPOINTMENTS}
          type='All'
        />
        <Stats
          colorIndex={4}
          title='Pending Appointments'
          count={stats?.pendingAppointments || 0}
          path={ROUTES.UPCOMING_APPOINTMENTS}
          type='Pending'
        />
        <Stats
          colorIndex={5}
          title='Cancel Appointments'
          count={stats?.canceledAppointments || 0}
          path={ROUTES.UPCOMING_APPOINTMENTS}
          type='Cancelled'
        />
        <Stats
          colorIndex={6}
          title='Reported Users'
          count={stats?.reportedUsers || 0}
          path={ROUTES.REPORTED_USERS}
        />
        <Stats
          colorIndex={7}
          title='Earnings'
          count={`PKR ${formatNumber(stats?.total_earnings || 0)}`}
          path={ROUTES.EARNINGS}
        />
        <Stats
          colorIndex={8}
          title='Call minutes'
          count={stats?.total_min || 0}
          path={ROUTES.CALLMINUTES}
        />
        {/* <StatCard
          title='Total Cases'
          value='10,225'
          percentage={'+15.9'}
          stroke='#375D9A'
          colorId='blue'
        />
        <StatCard
          title='Settled Cases'
          value='5,545'
          percentage={'+10.1'}
          stroke='#FD7C05'
          colorId='orange'
        />
        <StatCard
          title='Earning in this Month'
          value='2,78,251 PKR'
          percentage={'-5.1'}
          stroke='#FF5050'
          colorId='red'
        />
        <StatCard
          title='Total View'
          value='100'
          percentage={'+9.9'}
          stroke='#B071D7'
          colorId='purple'
        /> */}
      </Grid>
      {/* <div className='row flex-row'>
        <ActiveUsersChart />
        <RecentActivity />
      </div>
      <NewAppointments /> */}
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  padding-block: 29px;

  & .flex-row {
    gap: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: stretch;
    margin-top: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  gap: 19px;
`;
