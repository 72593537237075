import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useNavigate } from 'react-router-dom';

import assets from '../../assets';
import Pagination from '../../components/Pagination/Pagination';
import classNames from 'classnames';
import { ITransaction } from '../../types/interfaces';
import { ROUTES } from '../../constants';
import Loading from '../../components/Loading/Loading';
import { ClipLoader } from 'react-spinners';
import theme from '../../theme';
import StatusChip from '../../components/StatusChip/StatusChip';
import SearchBar from '../../components/SearchBar/SearchBar';
import Button from '../../components/Button/Button';

const LawyerPayments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { isSideBarOpen } = useSelector((state: RootState) => state.utils);
  const [active, setActive] = useState<0 | 1>(0);

  const [search, setSearch] = useState('');

  const handleTab = (tab: 0 | 1) => {
    setActive(tab);
  };

  const { requests, totalPages, loading, approved, pending } = useSelector(
    (state: RootState) => state.payments
  );

  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const handleNavigate = (request: ITransaction) => {
    navigate(ROUTES.PAYMENT_DETAILS, {
      state: request,
    });
  };

  const handleSearch = () => {
    dispatch.payments.handleGetAllPaymentRequests({
      page,
      limit,
      status: active,
      ...(search && { full_name: search }),
    });
  };

  useEffect(() => {
    dispatch.payments.handleGetAllPaymentRequests({
      page,
      limit,
      status: active,
      ...(search && { full_name: search }),
    });
    // eslint-disable-next-line
  }, [page, limit]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      dispatch.payments.handleGetAllPaymentRequests({
        page,
        limit,
        status: active,
        ...(search && { full_name: search }),
      });
    }
    // eslint-disable-next-line
  }, [active]);

  return (
    <Container>
      <div className='header row'>
        <img src={assets.money} alt='' />
        <p className='font-size-24  title'>Lawyer Payment Requests</p>
      </div>
      <ButtonsRow>
        <div
          className={classNames('btn clickable', active === 0 && 'filled')}
          role='button'
          onClick={() => handleTab(0)}
        >
          <p className='font-size-14 semi-bold'>Pending ({pending})</p>
        </div>
        <div
          className={classNames('btn clickable', active === 1 && 'filled')}
          role='button'
          onClick={() => handleTab(1)}
        >
          <p className='font-size-14 semi-bold'>Approved ({approved})</p>
        </div>
        <div className='row' style={{ marginLeft: 'auto', gap: '8px' }}>
          <SearchBar
            placeholder='Search name'
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
          />
          <Button
            variant='outlined'
            label='Search'
            className='btn filled'
            disabled={!search}
            onClick={handleSearch}
            loading={loading}
          />
        </div>
      </ButtonsRow>
      {requests.length === 0 && loading ? (
        <Loading />
      ) : (
        <>
          <TableContainer className={classNames(isSideBarOpen ? '' : 'large')}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Lawyer Name
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Total Earnings
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Requested Amount
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Request Details
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {requests.map(req => (
                  <tr>
                    <td key={req.id}>
                      <div className='row' style={{ gap: '13px' }}>
                        <img
                          style={{
                            height: '46px',
                            width: '46px',
                            objectFit: 'cover',
                            borderRadius: '7px',
                          }}
                          src={req.imageUrl || assets.preview}
                          alt=''
                        />
                        <span>{req.full_name}</span>
                      </div>
                    </td>
                    <td>PKR {req.earnings}</td>
                    <td>PKR {req.without_fee_amount}</td>
                    <td>
                      {req.status !== 1 ? (
                        <div
                          className='row clickable'
                          onClick={() => handleNavigate(req)}
                          style={{ gap: '10px' }}
                        >
                          <span>Request Details</span>
                          <img src={assets.arrowRight} alt='' />
                        </div>
                      ) : (
                        <StatusChip label='completed' />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {requests.length > 0 && loading && (
              <Loader>
                <ClipLoader size={50} color={theme.colors.white} />
              </Loader>
            )}
          </TableContainer>
          <div style={{ marginTop: '53px' }}>
            <Pagination
              limit={limit}
              pageCount={totalPages}
              pageNumber={page}
              setPageNumber={setPage}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default LawyerPayments;

const Container = styled.div`
  padding-block: 29px 50px;
  width: 100%;

  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-block: 26px 23px;

  & .btn {
    height: 43px;
    min-width: 169px;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 6px;

    color: ${({ theme }) => theme.colors.primary};
    transition: 0.4s ease;

    &.filled {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 327px);
  margin-top: 19px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  position: relative;
  transition: 0s.4s ease;

  &.large {
    max-width: calc(100vw - 200px);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;

  & thead {
    & tr {
      & th {
        background-color: ${({ theme }) => theme.colors.greyBg};
        text-align: left;
        padding-block: 15px 10px;
        padding-inline: 25px;

        & input {
          margin-top: 2px;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        height: 96px;
        padding-inline: 25px;
        text-align: left;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
        font-size: 13px;
      }

      &:hover {
        & td {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:last-of-type {
        & td {
          border: none;
        }
      }
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;
