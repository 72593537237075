import { FC, useEffect, useState } from 'react';
import { getImageUrl } from '../../http/services/authService';
import assets from '../../assets';

const Image: FC<{ url: string }> = ({ url }) => {
  const [image, setImage] = useState('');

  const handleGetImage = async () => {
    try {
      const { data } = await getImageUrl(url);
      setImage(data.data);
    } catch (err: any) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    handleGetImage();
    // eslint-disable-next-line
  }, [url]);
  return (
    <>
      {image ? <img src={image} alt='' /> : <img src={assets.preview} alt='' />}
    </>
  );
};

export default Image;
