import React, { useState } from 'react';

import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import assets from '../../assets';
import { Icon } from '@iconify/react';
import theme from '../../theme';
import Button from '../../components/Button/Button';
import { ITransaction } from '../../types/interfaces';
import axios from 'axios';
import { updatePaymentRequests } from '../../http/services/paymentService';

const LawyerPaymentDetails = () => {
  const navigate = useNavigate();
  const pr = useLocation().state as ITransaction;
  const dispatch = useDispatch<Dispatch>();

  const [image, setImage] = useState<File | null>(null);

  const [loading, setLoading] = useState(false);

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files?.length) {
      setImage(files[0]);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('image', image as File);
      formData.append('status', '1');
      await updatePaymentRequests(pr.id, formData);
      dispatch.utils.openSuccessModal(
        'Payment request fullfiled successfully!'
      );
      navigate(-1);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <BackButton onClick={handleGoBack}>
        <div className='icon-container'>
          <Icon
            icon='ion:chevron-back-outline'
            color={theme.colors.primary}
            style={{ fontSize: '20px' }}
          />
        </div>
        <div className='row'>
          <img src={assets.money} alt='' />
          <p className='font-size-24 text-blue bold'>Request Details</p>
        </div>
      </BackButton>
      <Grid>
        <GridItem>
          <div className='wrapper'>
            <div className='row space-between'>
              <p className='font-size-18 text-primary semi-bold'>
                Total Earning
              </p>
              <p className='font-size-18 text-primary semi-bold'>
                PKR {pr.earnings}
              </p>
            </div>
          </div>
        </GridItem>
        <GridItem>
          <div className='wrapper'>
            <div className='row space-between'>
              <p className='font-size-18 text-primary semi-bold'>
                Requested Amount
              </p>
              <p className='font-size-18 text-primary semi-bold'>
                PKR {pr.without_fee_amount}
              </p>
            </div>
          </div>
        </GridItem>
        <GridItem>
          <div className='wrapper'>
            <p className='font-size-18 text-primary semi-bold title'>
              Bank Details
            </p>
            <div className='row space-between'>
              <p className='font-size-14 text-secondary'>Bank Name</p>
              <p className='font-size-14 text-secondary'>-</p>
              <p className='font-size-14 text-blue'>{pr.bank_name}</p>
            </div>
            <div className='row space-between'>
              <p className='font-size-14 text-secondary'>Branch Code</p>
              <p className='font-size-14 text-secondary'>-</p>
              <p className='font-size-14 text-blue'>{pr.branch_code}</p>
            </div>
            <div className='row space-between'>
              <p className='font-size-14 text-secondary'>Account Number</p>
              <p className='font-size-14 text-secondary'>-</p>
              <p className='font-size-14 text-blue'>{pr.account_number}</p>
            </div>
            <div className='row space-between'>
              <p className='font-size-14 text-secondary'>Account Title</p>
              <p className='font-size-14 text-secondary'>-</p>
              <p className='font-size-14 text-blue'>{pr.account_name}</p>
            </div>
          </div>
        </GridItem>
        <GridItem>
          <div className='wrapper'>
            <p className='font-size-18 text-primary semi-bold title'>
              Deductions
            </p>
            <div className='row space-between'>
              <p className='font-size-14 text-secondary'>Platform Fee</p>
              <p className='font-size-14 text-secondary'>-</p>
              <p className='font-size-14 text-blue'>
                PKR {pr.without_fee_amount - pr.amount}
              </p>
            </div>
            <div className='row space-between'>
              <p className='font-size-14 text-secondary'>
                Total Payable Amount
              </p>
              <p className='font-size-14 text-secondary'>-</p>
              <p className='font-size-14 text-blue'>PKR {pr.amount}</p>
            </div>
          </div>

          <div className='actions'>
            <p className='font-size-14 text-secondary'>
              <span style={{ color: theme.colors.text.danger }}>Note:</span>
              {'  '}
              Please confirm whether you have sent the payment to the Lawyer.
              Kindly upload the receipt and promptly notify the lawyer of the
              payment. <br /> Thank you.
            </p>
            <ButtonsContainer>
              {!loading && (
                <>
                  <input
                    onChange={handleImage}
                    type='file'
                    accept='image/jpg, image/png, image/jpeg'
                    name=''
                    id='img'
                    style={{ display: 'none' }}
                  />
                  <label htmlFor='img' className='upload-btn'>
                    {image ? (
                      <p className='font-size-12 text-blue'>{image.name}</p>
                    ) : (
                      <>
                        <img src={assets.upload} alt='' />
                        <p className='font-size-12 text-blue'>Upload Photo</p>
                      </>
                    )}
                  </label>
                </>
              )}

              <Button
                disabled={loading || !image}
                className='btn btn-contained'
                variant='contained'
                label='Submit'
                loading={loading}
                onClick={handleSubmit}
              />
            </ButtonsContainer>
          </div>
        </GridItem>
      </Grid>
    </Container>
  );
};

export default LawyerPaymentDetails;

const Container = styled.div`
  padding-block: 29px 50px;
  width: 100%;

  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const BackButton = styled.button`
  all: unset;
  gap: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & .icon-container {
    height: 40px;
    width: 40px;
    background-color: ${({ theme }) => theme.colors.greyBg};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  & .row {
    gap: 20px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 18px;
  margin-top: 20px;
`;

const GridItem = styled.div`
  & .wrapper {
    padding: 35px 42px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.borderLight};
    border-top: 3px solid #375d9a;

    & .row p.font-size-14 {
      line-height: 40px;
      min-width: 15ch;

      &:nth-child(2) {
        width: unset;
      }
    }

    & .title {
      margin-bottom: 14px;
    }
  }

  & .actions {
    margin-top: 26px;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 26px;

  & .btn {
    width: 156px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    &.contained {
      color: ${({ theme }) => theme.colors.white};
      &:disabled {
        opacity: 0.7;
      }
    }
  }

  & .upload-btn {
    width: 156px;
    cursor: pointer;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 6px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
`;
