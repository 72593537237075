import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IUpcomingAppointment } from '../../types/interfaces';
import axios from 'axios';
import { getAllUpcomingAppointments } from '../../http/services/appointmentService';

interface IState {
  loading: boolean;
  appointments: IUpcomingAppointment[];
  totalPages: number;
  selected: IUpcomingAppointment | null;
}

export const appointments = createModel<RootModel>()({
  name: 'appointments',
  state: {
    loading: false,
    appointments: [],
    totalPages: 1,
    selected: null,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setTotalPages(state, payload: number) {
      state.totalPages = payload;
    },
    setAppointments(state, payload: IUpcomingAppointment[]) {
      state.appointments = payload;
    },
    setSelected(state, payload: IUpcomingAppointment | null) {
      state.selected = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllUpcomingAppointments(payload: {
      time: string;
      page: number;
      limit: number;
      key?: string;
      value?: string;
      val?: string;
    }) {
      const { time, page, limit, key = '', value = '', val = '' } = payload;
      try {
        dispatch.appointments.setLoading(true);
        const { data } = await getAllUpcomingAppointments({
          time,
          page,
          limit,
          key,
          value,
          val,
        });
        dispatch.appointments.setAppointments(data.data.appointments);
        dispatch.appointments.setTotalPages(data.data.totalPages);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data);
        }
      } finally {
        dispatch.appointments.setLoading(false);
      }
    },
  }),
});
