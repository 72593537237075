import api from '..';
import { IApiResponse } from '../../types/apiInterface';
import { IReportType, IReports } from '../../types/interfaces';

export const getReportedUsers = (
  page: number,
  limit: number,
  key?: string,
  value?: string
) =>
  api.get<
    IApiResponse<{
      reports: IReports[];
      totalPages: number;
      currentPage: number;
    }>
  >(
    `/v1/user/report?page=${page}&limit=${limit}${
      key && value ? `&key=${key}&value=${encodeURIComponent(value)}` : ''
    }`
  );

export const getReportTypes = () =>
  api.get<IApiResponse<IReportType[]>>('/v1/reports-types');
