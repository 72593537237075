import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import Pagination from '../../components/Pagination/Pagination';

import { ROUTES } from '../../constants';
import navIcons from '../../assets/navIcons';
import { IUser } from '../../types/interfaces';
import LawyerRow from './LawyerRow';
import { ClipLoader } from 'react-spinners';
import theme from '../../theme';
import Loading from '../../components/Loading/Loading';
import Select from '../../components/Select/Select';
import SearchBar from '../../components/SearchBar/SearchBar';
import Button from '../../components/Button/Button';
import classNames from 'classnames';
import UpdatePlatformFee from '../../Dialogs/UpdatePlatformFee/UpdatePlatformFee';

const Lawyers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [openFee, setOpenFee] = useState(false);

  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const { totalPages, lawyers, loading } = useSelector(
    (state: RootState) => state.lawyers
  );

  const { isSideBarOpen } = useSelector((state: RootState) => state.utils);

  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const handleClick = (lawyer: IUser) => {
    navigate(ROUTES.LAWYER_DETAILS.replace(':id', lawyer.id), {
      state: lawyer,
    });
  };

  const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
    setSearch('');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearch(e.currentTarget.value);
  };

  const handleSearchLawyers = () => {
    dispatch.lawyers.handleGetAllLawyers({
      page,
      limit,
      key: filter,
      value: search,
    });
  };

  useEffect(() => {
    dispatch.lawyers.handleGetAllLawyers({
      page,
      limit,
    });
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (!filter) {
      handleSearchLawyers();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Container>
      <div className='header row'>
        <img src={navIcons.lawyer} style={{ height: '25px' }} alt='' />
        <p className='font-size-24  title'>Lawyers</p>
      </div>
      <div
        className='row'
        style={{ gap: '12px', height: '50px', marginTop: '30px' }}
      >
        <Select
          value={filter}
          onChange={handleFilter}
          containerClassName='select-container'
          search={true}
          options={[
            { label: filter ? 'Remove filter' : 'Select a filter', value: '' },
            { label: 'Name', value: 'full_name' },
            { label: 'Email', value: 'email' },
            { label: 'Contact', value: 'mobile' },
          ]}
        />
        {filter && (
          <>
            <SearchBar value={search} onChange={handleSearch} />
            <Button
              label='Search'
              size='small'
              style={{ height: '44px', minWidth: '120px' }}
              onClick={handleSearchLawyers}
              loading={loading}
            />
          </>
        )}
        <Button
          label='Update Platform Fee'
          size='small'
          style={{ height: '44px', minWidth: '120px', borderRadius: '4px' }}
          onClick={() => setOpenFee(true)}
        />
      </div>
      {lawyers.length === 0 && loading ? (
        <Loading />
      ) : (
        <>
          <TableContainer className={classNames(isSideBarOpen ? '' : 'large')}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>Name</p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>Email</p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Contact
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Experience
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Platform Fee
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Status
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Verified
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Lawyer Profile
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {lawyers.map(lawyer => (
                  <LawyerRow
                    lawyer={lawyer}
                    key={lawyer.id}
                    handleClick={handleClick}
                  />
                ))}
              </tbody>
            </Table>
            {lawyers.length > 0 && loading && (
              <Loader>
                <ClipLoader size={50} color={theme.colors.white} />
              </Loader>
            )}
          </TableContainer>
          <div style={{ marginTop: '53px' }}>
            <Pagination
              limit={limit}
              setPageNumber={setPage}
              pageNumber={page}
              pageCount={totalPages}
            />
          </div>
        </>
      )}
      {openFee && (
        <UpdatePlatformFee
          open={openFee}
          setOpen={setOpenFee}
          onUpdate={() =>
            dispatch.lawyers.handleGetAllLawyers({
              page,
              limit,
            })
          }
        />
      )}
    </Container>
  );
};

export default Lawyers;

const Container = styled.div`
  padding-block: 29px 50px;
  width: 100%;
  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 327px);
  margin-top: 19px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  position: relative;
  transition: 0s.4s ease;

  &.large {
    max-width: calc(100vw - 180px);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;

  & thead {
    & tr {
      & th {
        background-color: ${({ theme }) => theme.colors.greyBg};
        text-align: left;
        padding-block: 15px 10px;
        padding-inline: 25px;

        & input {
          margin-top: 2px;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        height: 54px;
        padding-inline: 25px;
        text-align: left;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
        font-size: 13px;

        & .user-profile {
          height: 34px;
          width: 34px;
          border-radius: 7px;
          object-fit: cover;
        }
      }

      &:hover {
        & td {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:last-of-type {
        & td {
          border: none;
        }
      }
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;
