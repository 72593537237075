import React, { FC, useEffect } from 'react';

import styled from 'styled-components';
import { useFormik } from 'formik';
import { InferType, object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import DialogLayout from '../DialogLayout';
import TextField from '../../components/TextField/TextField';
import Textarea from '../../components/Textarea/Textarea';
import Button from '../../components/Button/Button';

const schema = object({
  title: string().min(3).required().label('Title'),
  description: string().min(10).required().label('Description'),
});

export interface IAddHelpCategoryValues extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddCategory: FC<IProps> = props => {
  const { open, setOpen } = props;

  const dispatch = useDispatch<Dispatch>();

  const { loading, selectedCategory } = useSelector(
    (state: RootState) => state.help
  );

  const formik = useFormik<IAddHelpCategoryValues>({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      if (selectedCategory) {
        dispatch.help.handleUpdateHelpCategory({
          values,
          formik,
          setOpen,
        });
      } else {
        dispatch.help.handleAddHelpCategory({
          values,
          formik,
          setOpen,
        });
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedCategory) {
      formik.setValues({
        title: selectedCategory?.title || '',
        description: selectedCategory?.description || '',
      });
    }
    // eslint-disable-next-line
  }, [selectedCategory]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='header'>
          <p className='font-size-24 semi-bold title'>Add Category</p>
        </div>
        <Content>
          <TextField
            label='Category title'
            variant='secondary'
            placeholder=''
            value={formik.values.title}
            name='title'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.title && formik.errors.title
                ? formik.errors.title
                : ''
            }
          />
          <Textarea
            label='Category description'
            name='description'
            onChange={formik.handleChange}
            value={formik.values.description}
            onBlur={formik.handleBlur}
            rows={5}
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : ''
            }
          />
          <ButtonRow>
            <Button variant='outlined' label='Cancel' onClick={handleClose} />
            <Button
              variant='contained'
              label={selectedCategory ? 'Update' : 'Save'}
              loading={loading}
              onClick={formik.handleSubmit}
            />
          </ButtonRow>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default AddCategory;

const Container = styled.div`
  width: 100%;
  max-width: 568px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .header {
    background-color: ${({ theme }) => theme.colors.greyBg};
    padding-block: 28px 22px;
    padding-inline: 45px;

    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  max-height: 85vh;
`;

const Content = styled.div`
  padding-block: 31px 48px;
  padding-inline: 45px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  overflow: auto;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 17px;
  margin-top: 16px;

  & button {
    height: 36px;
    min-width: 0px;
    padding-inline: 22px;
    font-size: 13px;
    font-weight: 600;
  }
`;
