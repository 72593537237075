import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string, ref, InferType } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';

import assets from '../../assets';
import { ROUTES } from '../../constants';

const schema = object({
  code: string().required().min(6).max(6).label('Code'),
  password: string().required().min(8).label('Password'),
  confirm_password: string()
    .oneOf([ref('password'), ''], 'Confirm password must be same as password')
    .required()
    .label('Confirm password'),
});

export interface IResetPasswordInitialValues extends InferType<typeof schema> {}

const ResetPassword = () => {
  const navigate = useNavigate();
  const token: string = useLocation().state?.token;
  const dispatch = useDispatch<Dispatch>();

  const { loading } = useSelector((state: RootState) => state.auth);

  const formik = useFormik<IResetPasswordInitialValues>({
    initialValues: {
      code: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch.auth.handleResetPassword({ values, formik, navigate, token });
    },
  });

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.FORGOT_PASSWORD);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Content>
        <p className='font-size-25 title'>Please Enter Your New Password</p>
        <Form onSubmit={e => e.preventDefault()}>
          <TextField
            type='text'
            name='code'
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.code && formik.errors.code
                ? formik.errors.code
                : ''
            }
            variant='secondary'
            leftIcon={<img src={assets.user} alt='' />}
            placeholder='code'
          />
          <TextField
            type='password'
            variant='secondary'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ''
            }
            leftIcon={<img src={assets.lock} alt='' />}
            placeholder='Password'
          />
          <TextField
            type='password'
            variant='secondary'
            name='confirm_password'
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirm_password && formik.errors.confirm_password
                ? formik.errors.confirm_password
                : ''
            }
            leftIcon={<img src={assets.lock} alt='' />}
            placeholder='Confirm Password'
          />
          <div className='row justify-center'>
            <Button
              label='Save'
              type='submit'
              onClick={formik.handleSubmit}
              className='btn'
              loading={loading}
            />
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default ResetPassword;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  background-image: url(${assets.lawBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  padding-block: 55px 61px;
  padding-inline: 106px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 68px;

  & .title {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .btn {
    margin-top: 42px;
  }
`;
