import React, { FC } from 'react';

import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom';

import Rating from 'react-rating';

import theme from '../../../theme';
import { ILawyer } from '../../../types/interfaces';

const About: FC = () => {
  const lawyer: ILawyer = useLocation().state;

  return (
    <Container>
      <p className='font-size-18 bold text-blue'>About</p>
      <p className='font-size-13 text-secondary medium'>{lawyer.about}</p>
      <RatingContainer>
        <p className='font-size-16 text-blue semi-bold'>Rating</p>
        <div style={{ paddingTop: 2 }}>
          {/* @ts-ignore */}
          <Rating
            readonly
            initialRating={lawyer.rating || 0}
            emptySymbol={
              <Icon
                icon='teenyicons:star-outline'
                color={theme.colors.text.secondary}
                style={{
                  fontSize: 20,
                  paddingInline: '1px',
                }}
              />
            }
            fullSymbol={
              <Icon
                icon='teenyicons:star-solid'
                color='#FABE10'
                style={{
                  fontSize: 20,
                  paddingInline: '1px',
                }}
              />
            }
          />
        </div>
      </RatingContainer>
    </Container>
  );
};

export default About;

const Container = styled.div`
  padding: 38px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;

  & .font-size-13 {
    margin-top: 7px;
  }
`;

const RatingContainer = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  gap: 13px;
`;
