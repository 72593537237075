import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { InferType, number, object } from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import DialogLayout from '../DialogLayout';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import { updatePlatformFeeForAll } from '../../http/services/lawyerService';

const schema = object({
  value: number()
    .positive()
    .max(99)
    .min(0)
    .required('Required')
    .label('Platform fee(%)'),
});

interface IPlatformFee extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: () => void;
}

const UpdatePlatformFee: FC<IProps> = ({ open, setOpen, onUpdate }) => {
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch<Dispatch>();

  const [loading, setLoading] = useState(false);

  const formik = useFormik<IPlatformFee>({
    initialValues: {
      value: 10,
    },
    validationSchema: schema,
    onSubmit: async values => {
      try {
        setLoading(true);
        await updatePlatformFeeForAll(values.value);
        dispatch.utils.openSuccessModal(
          'Platform fee updated for all lawyers.'
        );
        setOpen(false);
        onUpdate();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='header'>
          <p className='font-size-24 semi-bold title'>Update Platform Fee</p>
          <p className='font-size-16' style={{ marginTop: '4px' }}>
            This change will affect all lawyers.
          </p>
          <div className='form'>
            <TextField
              variant='primary'
              type='number'
              label='Platform fee(%)'
              labelClassName='label'
              value={formik.values.value}
              name='value'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputClassName='text-input'
              error={
                formik.touched.value && formik.errors.value
                  ? formik.errors.value
                  : ''
              }
            />
          </div>
          <ButtonContainer>
            <Button variant='outlined' label='Cancel' onClick={handleClose} />
            <Button
              variant='contained'
              type='submit'
              label='Apply All'
              onClick={() => formik.handleSubmit()}
              loading={loading}
            />
          </ButtonContainer>
        </div>
      </Container>
    </DialogLayout>
  );
};

export default UpdatePlatformFee;

const Container = styled.div`
  width: 100%;
  max-width: 480px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .header {
    background-color: ${({ theme }) => theme.colors.greyBg};
    padding-block: 28px 22px;
    padding-inline: 25px;

    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }

    & .text-input {
      font-size: 18px;
      padding-inline: 12px;
      color: ${({ theme }) => theme.colors.primary};
    }

    & .label {
      margin-bottom: 6px;
    }

    & .form {
      margin-block: 80px 40px;
    }
  }
  max-height: 85vh;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  & button {
    flex-grow: 1;
  }
`;
