import React, { FC, useEffect } from 'react';

import styled from 'styled-components';
import { useFormik } from 'formik';
import { object, string, InferType } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useLocation } from 'react-router-dom';

import DialogLayout from '../DialogLayout';
import TextField from '../../components/TextField/TextField';
import Textarea from '../../components/Textarea/Textarea';
import Button from '../../components/Button/Button';
import { IAddHelpSubCategoryData } from '../../types/apiInterface';
import { IHelpCategory } from '../../types/interfaces';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const schema = object({
  title: string().min(3).required().label('Title'),
  description: string().min(10).required().label('Description'),
});

export interface IAddHelpSubCategoryValues extends InferType<typeof schema> {}

const AddSubCategory: FC<IProps> = props => {
  const { open, setOpen } = props;
  const parent: IHelpCategory = useLocation().state;

  const dispatch = useDispatch<Dispatch>();

  const { actionLoading, selectedSubCategory } = useSelector(
    (state: RootState) => state.help
  );

  const formik = useFormik<IAddHelpSubCategoryValues>({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      const apiData: IAddHelpSubCategoryData = {
        parentId: parent.id,
        ...values,
      };
      if (selectedSubCategory) {
        dispatch.help.handleUpdateSubCategory({
          values: apiData,
          formik,
          setOpen,
        });
      } else {
        dispatch.help.handleAddSubCategory({
          values: apiData,
          formik,
          setOpen,
        });
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedSubCategory) {
      formik.setValues({
        title: selectedSubCategory.title,
        description: selectedSubCategory.description,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='header'>
          <p className='font-size-24 semi-bold title'>Add Sub-Category</p>
        </div>
        <Content>
          <TextField
            label='Sub-Category title'
            variant='secondary'
            placeholder=''
            name='title'
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.title && formik.errors.title
                ? formik.errors.title
                : ''
            }
          />
          <Textarea
            label='Sub-Category description'
            name='description'
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={5}
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : ''
            }
          />
          <ButtonRow>
            <Button
              variant='outlined'
              label='Cancel'
              disabled={actionLoading}
              onClick={handleClose}
            />
            <Button
              variant='contained'
              label={selectedSubCategory ? 'Update' : 'Save'}
              loading={actionLoading}
              onClick={formik.handleSubmit}
            />
          </ButtonRow>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default AddSubCategory;

const Container = styled.div`
  width: 100%;
  max-width: 568px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .header {
    background-color: ${({ theme }) => theme.colors.greyBg};
    padding-block: 28px 22px;
    padding-inline: 45px;

    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  max-height: 85vh;
`;

const Content = styled.div`
  padding-block: 31px 48px;
  padding-inline: 45px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  overflow: auto;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 17px;
  margin-top: 16px;

  & button {
    height: 36px;
    min-width: 0px;
    padding-inline: 22px;
    font-size: 13px;
    font-weight: 600;
  }
`;
