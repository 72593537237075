import api from '..';
import { IApiResponse, IStats } from '../../types/apiInterface';
import { IUser } from '../../types/interfaces';

export const currentUser = () => api.get<IApiResponse<IUser>>('/v1/user');

export const getStats = () => api.get<IApiResponse<IStats>>('/v1/user/stats');

export const getChatUsers = () =>
  api.get<IApiResponse<IUser[]>>('/v1/user/chats');

export const getUserById = (id: string) => api.get(`/v1/user/${id}`);
