import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import navIcons from '../../assets/navIcons';
import Profile from './Components/Profile';
import classNames from 'classnames';
import Account from './Components/Account';

const Settings = () => {
  const dispatch = useDispatch<Dispatch>();

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (val: number) => {
    setActiveTab(val);
  };

  useEffect(() => {
    dispatch.payments.handleGetPaymentMethods();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='header row'>
        <img src={navIcons.settings} style={{ height: '25px' }} alt='' />
        <p className='font-size-24  text-blue title'>Settings</p>
      </div>

      <Tabs activeTab={activeTab}>
        <Tab
          className={classNames(activeTab === 0 && 'active')}
          onClick={() => handleChange(0)}
        >
          Profile
        </Tab>
        <Tab
          className={classNames(activeTab === 1 && 'active')}
          onClick={() => handleChange(1)}
        >
          Account
        </Tab>
        <div className='indicator'></div>
      </Tabs>

      <div className='content'>
        {activeTab === 0 && <Profile />}
        {activeTab === 1 && <Account />}
      </div>
    </Container>
  );
};

export default Settings;

const Container = styled.div`
  padding-block: 29px 50px;
  & .header {
    gap: 20px;
  }
  & .content {
    margin-top: 30px;
  }
`;

const Tabs = styled.div<{ activeTab: number }>`
  width: 100%;
  border-bottom: 5px solid ${({ theme }) => theme.colors.primary + '0D'};
  margin-top: 42px;
  display: flex;
  position: relative;

  & .indicator {
    width: 50%;
    position: absolute;
    bottom: -5px;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.primary};
    transition: 0.4s ease;
    left: ${({ activeTab }) => `calc(50% *${activeTab} )`};
  }
`;

const Tab = styled.div`
  width: 50%;
  padding-inline: 28px;
  padding-bottom: 19px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary + '99'};

  &.active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
