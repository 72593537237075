import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Button from '../../components/Button/Button';

import AddCaseCategory from '../../Dialogs/AddCaseCategory/AddCaseCategory';

import navIcons from '../../assets/navIcons';
import CategoryRow from './CategoryRow';
import SearchBar from '../../components/SearchBar/SearchBar';
import Loading from '../../components/Loading/Loading';
import classNames from 'classnames';

const Categories = () => {
  const dispatch = useDispatch<Dispatch>();
  const { categories, loading } = useSelector(
    (state: RootState) => state.categories
  );

  const [openModel, setOpenModal] = useState(false);
  const [search, setSearch] = useState('');

  const { isSideBarOpen } = useSelector((state: RootState) => state.utils);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearch(value);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    dispatch.categories.setSelected(null);
  };

  useEffect(() => {
    dispatch.categories.getAllCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='row space-between'>
        <div className='row header'>
          <img src={navIcons.category} style={{ width: '22px' }} alt='' />
          <p className='font-size-24 title'>Categories</p>
        </div>
        <Button
          label='+ Add Category'
          className='btn'
          onClick={handleOpenModal}
        />
      </div>
      {categories.length === 0 && loading ? (
        <Loading />
      ) : (
        <>
          <SearchContainer>
            <SearchBar value={search} onChange={handleChange} />
          </SearchContainer>
          <TableContainer className={classNames(isSideBarOpen ? '' : 'large')}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Category
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Published
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Actions
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {categories.map(category => {
                  if (
                    category.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return (
                      <CategoryRow
                        category={category}
                        key={category.id}
                        setOpenModal={setOpenModal}
                      />
                    );
                  } else {
                    return <></>;
                  }
                })}
              </tbody>
            </Table>
          </TableContainer>
        </>
      )}
      {openModel && <AddCaseCategory open={openModel} setOpen={setOpenModal} />}
    </Container>
  );
};

export default Categories;

const Container = styled.div`
  padding-block: 29px 50px;

  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  & .btn {
    padding-inline: 34px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 327px);
  margin-top: 19px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;

  transition: 0.4s ease;

  &.large {
    max-width: calc(100vw - 180px);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;
  table-layout: fixed;

  & thead {
    & tr {
      & th {
        background-color: ${({ theme }) => theme.colors.greyBg};
        text-align: left;
        padding-block: 15px 10px;
        padding-inline: 25px;

        & input {
          margin-top: 2px;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        height: 54px;
        padding-inline: 25px;
        text-align: left;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
        font-size: 13px;
      }

      &:hover {
        & td {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:last-of-type {
        & td {
          border: none;
        }
      }
    }
  }
`;

const SearchContainer = styled.div`
  display: flex;
  margin-top: 30px;
`;
