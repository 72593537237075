import { Models } from '@rematch/core';
import { auth } from './auth';
import { categories } from './categories';
import { help } from './help';
import { utils } from './utils';
import { lawyers } from './lawyers';
import { reportedUsers } from './reportedUsers';
import { clients } from './clients';
import { appointments } from './appointments';
import { notifications } from './notifications';
import { chat } from './chat';
import { appointmentTransations } from './appointmentTransactions';
import { payments } from './payments';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  categories: typeof categories;
  help: typeof help;
  utils: typeof utils;
  lawyers: typeof lawyers;
  reportedUsers: typeof reportedUsers;
  clients: typeof clients;
  appointments: typeof appointments;
  notifications: typeof notifications;
  chat: typeof chat;
  appointmentTransations: typeof appointmentTransations;
  payments: typeof payments;
}

export const models: RootModel = {
  auth,
  categories,
  help,
  utils,
  lawyers,
  reportedUsers,
  clients,
  appointments,
  notifications,
  chat,
  appointmentTransations,
  payments,
};
