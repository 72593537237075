import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Pagination from '../../components/Pagination/Pagination';
import { Icon } from '@iconify/react';
import theme from '../../theme';
import StatusChip from '../../components/StatusChip/StatusChip';
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog/ConfirmationDialog';
import {
  getCurrentTime,
  getEndTime,
  getFormatedDate,
  getFormatedHours,
} from '../../utils';
import assets from '../../assets';
import { ClipLoader } from 'react-spinners';
import Loading from '../../components/Loading/Loading';
import Select from '../../components/Select/Select';
import SearchBar from '../../components/SearchBar/SearchBar';
import Button from '../../components/Button/Button';
import EditAppointment from '../../Dialogs/EditAppointment/EditAppointment';
import { IUpcomingAppointment } from '../../types/interfaces';
import classNames from 'classnames';
// import EditAppointment from '../../Dialogs/EditAppointment/EditAppointment';

const UpcomingAppointments = () => {
  const dispatch = useDispatch<Dispatch>();

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [time] = useState(getCurrentTime());
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const { isSideBarOpen } = useSelector((state: RootState) => state.utils);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    setPage(1);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
    setSearch('');
  };

  const handleEditAppointment = (appointment: IUpcomingAppointment) => {
    dispatch.appointments.setSelected(appointment);
    setOpen(true);
  };

  const { appointments, totalPages, loading } = useSelector(
    (state: RootState) => state.appointments
  );

  const getStatus = (status: number) => {
    switch (status) {
      case 0:
        return 'pending';
      case 1:
        return 'in progress';
      case 2:
        return 'completed';
      case 3:
        return 'canceled';
      default:
        return 'pending';
    }
  };

  const handleSearchAppointments = () => {
    setPage(1);
    dispatch.appointments.handleGetAllUpcomingAppointments({
      time,
      page,
      limit,
      key: filter,
      value: search,
    });
  };

  useEffect(() => {
    dispatch.appointments.handleGetAllUpcomingAppointments({
      time,
      page,
      limit,
    });
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (!filter) {
      handleSearchAppointments();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Container>
      <div className='row header'>
        <img src={assets.deadline} alt='' />
        <p className='font-size-24 title'>Upcoming Appointments</p>
      </div>
      <div
        className='row'
        style={{ gap: '12px', height: '50px', marginTop: '30px' }}
      >
        <Select
          value={filter}
          onChange={handleFilter}
          containerClassName='select-container'
          search={true}
          options={[
            { label: filter ? 'Remove filter' : 'Select a filter', value: '' },
            { label: 'Client Name', value: 'client_name' },
            { label: 'Lawyer Name', value: 'lawyer_name' },
            { label: 'Case Category', value: 'category_name' },
          ]}
        />
        {filter && (
          <>
            <SearchBar value={search} onChange={handleSearch} />
            <Button
              label='Search'
              size='small'
              style={{ height: '44px', minWidth: '120px' }}
              onClick={handleSearchAppointments}
              loading={loading}
            />
          </>
        )}
      </div>
      {appointments.length === 0 && loading ? (
        <Loading />
      ) : (
        <>
          <TableContainer className={classNames(isSideBarOpen ? '' : 'large')}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Client Name
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Lawyer Name
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Case Category
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>Date</p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Start Time
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      End Time
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Status
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Action
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {appointments.map(appointment => (
                  <tr key={appointment.id}>
                    <td>{appointment.client_name}</td>
                    <td>{appointment.lawyer_name}</td>
                    <td>{appointment.category_name}</td>
                    <td>{getFormatedDate(appointment.date)}</td>
                    <td>{getFormatedHours(appointment.time)}</td>
                    <td>{getFormatedHours(getEndTime(appointment.time))}</td>
                    <td>
                      <StatusChip label={getStatus(appointment.status)} />
                    </td>
                    <td>
                      <div className='row' style={{ gap: '12px' }}>
                        <div
                          className='row clickable'
                          onClick={() => handleEditAppointment(appointment)}
                        >
                          <Icon
                            icon='mdi:file-edit-outline'
                            style={{ fontSize: 16 }}
                            color={theme.colors.text.secondary}
                          />
                        </div>
                        {/* <div
                          className='icon row clickable'
                          onClick={() => setOpenConfirmation(true)}
                        >
                          <img src={assets.bin} alt='' />
                        </div> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {appointments.length > 0 && loading && (
              <Loader>
                <ClipLoader size={50} color={theme.colors.white} />
              </Loader>
            )}
          </TableContainer>
          <div style={{ marginTop: '53px' }}>
            <Pagination
              pageCount={totalPages}
              limit={limit}
              pageNumber={page}
              setPageNumber={setPage}
            />
          </div>
        </>
      )}

      {openConfirmation && (
        <ConfirmationDialog
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          message='Are you sure you want to
          delete this Appointment?'
          actionLabel='Delete'
          onAction={() => {
            alert('delete');
            setOpenConfirmation(false);
          }}
        />
      )}
      {open && (
        <EditAppointment
          open={open}
          setOpen={setOpen}
          onUpdate={() =>
            dispatch.appointments.handleGetAllUpcomingAppointments({
              time,
              page,
              limit,
            })
          }
        />
      )}
    </Container>
  );
};

export default UpcomingAppointments;

const Container = styled.div`
  padding-block: 29px 50px;

  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 327px);
  margin-top: 19px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  overflow: auto;
  position: relative;

  &.large {
    max-width: calc(100vw - 180px);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 800px;

  & thead {
    & tr {
      & th {
        background-color: ${({ theme }) => theme.colors.greyBg};
        text-align: left;
        padding-block: 15px 10px;
        padding-inline: 12px;
        white-space: nowrap;
        overflow: hidden;
        min-width: 100px;

        & input {
          margin-top: 2px;
        }
      }
    }
  }

  & tbody {
    & tr {
      height: 54px;
      & td {
        height: 100%;
        padding-inline: 12px;
        text-align: left;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
        font-size: 13px;
      }

      &:hover {
        & td {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:last-of-type {
        & td {
          border: none;
        }
      }
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;
