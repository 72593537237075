import React, { FC, useEffect } from 'react';

import styled from 'styled-components';

interface IProps {
  open: boolean;
  children: React.ReactNode;
}

const DialogLayout: FC<IProps> = props => {
  const { open, children } = props;

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <>
      {open && (
        <Container>
          <div className='wrapper'>{children}</div>
        </Container>
      )}
    </>
  );
};

export default DialogLayout;

const Container = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 20;
  padding-block: 50px;

  & .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
`;
