import { createModel } from '@rematch/core';
import { RootModel } from '.';
import axios from 'axios';
import { getAppointmentTransactions } from '../../http/services/appointmentService';
import { IUserInvoiceTr } from '../../types/interfaces';

interface IState {
  loading: boolean;
  totalPages: number;
  pending: number;
  approved: number;
  transactions: IUserInvoiceTr[];
}

export const appointmentTransations = createModel<RootModel>()({
  name: 'appointmentTransations',
  state: {
    loading: false,
    totalPages: 0,
    pending: 0,
    approved: 0,
    transactions: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setTotalPages(state, payload: number) {
      state.totalPages = payload;
    },
    setPending(state, payload: number) {
      state.pending = payload;
    },
    setApproved(state, payload: number) {
      state.approved = payload;
    },
    setTransactions(state, payload: IUserInvoiceTr[]) {
      state.transactions = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllTransactions(payload: {
      page: number;
      limit: number;
      status: 4 | 0;
      key?: string;
      value?: string;
    }) {
      try {
        dispatch.appointmentTransations.setLoading(true);
        const { page, limit, status, key, value } = payload;
        const { data } = await getAppointmentTransactions({
          page,
          limit,
          status,
          key,
          value,
        });
        dispatch.appointmentTransations.setTotalPages(data.data.totalPages);
        dispatch.appointmentTransations.setPending(data.data.pending);
        dispatch.appointmentTransations.setApproved(data.data.approved);
        dispatch.appointmentTransations.setTransactions(data.data.transactions);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.appointmentTransations.setLoading(false);
      }
    },
  }),
});
