import React, { FC, useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import Switch from '../../components/Switch/Switch';
import { ICategory } from '../../types/interfaces';

import theme from '../../theme';

interface IProps {
  category: ICategory;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryRow: FC<IProps> = ({ category, setOpenModal }) => {
  const dispatch = useDispatch<Dispatch>();
  const { categories } = useSelector((state: RootState) => state.categories);

  const [active, setActive] = useState(category.isActive || false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setActive(!active);
    const categoryList = categories.map(c => {
      if (c.id === category.id) {
        return { ...c, isActive: checked };
      } else {
        return c;
      }
    });
    dispatch.categories.setCategories(categoryList);
    dispatch.categories.handleCategoryStatus({
      values: { isActive: checked, name: category.name },
      id: category.id,
    });
  };

  const handleUpdate = () => {
    dispatch.categories.setSelected(category);
    setOpenModal(true);
  };

  useEffect(() => {
    setActive(category.isActive);
  }, [category]);

  return (
    <tr>
      <td>
        <div className='row' style={{ gap: 10 }}>
          <img src={category.icon_url} style={{ maxWidth: 25 }} alt='' />
          {category.name}
        </div>
      </td>
      <td>
        <Switch checked={active} onChange={handleChange} />
      </td>
      <td>
        <div className='row' style={{ gap: '12px' }}>
          <div className='row clickable' role='button' onClick={handleUpdate}>
            <Icon
              icon='mdi:file-edit-outline'
              style={{ fontSize: 20 }}
              color={theme.colors.text.secondary}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CategoryRow;
