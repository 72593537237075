import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import DialogLayout from '../DialogLayout';
import navIcons from '../../assets/navIcons';
import Select from '../../components/Select/Select';
import assets from '../../assets';
import axios from 'axios';
import {
  getLawyerBusinessDays,
  getLawyerTimings,
} from '../../http/services/lawyerService';
import { getFormatedDate, getFormatedHours } from '../../utils';
import Button from '../../components/Button/Button';
import StatusChip from '../../components/StatusChip/StatusChip';
import { Icon } from '@iconify/react';
import ClickAwayListener from 'react-click-away-listener';
import TextField from '../../components/TextField/TextField';
import { IRescheduleAppointment } from '../../types/apiInterface';
import { rescheduleAppointment } from '../../http/services/appointmentService';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdate: () => void;
}

const EditAppointment: FC<IProps> = ({ open, setOpen, onUpdate }) => {
  const { selected } = useSelector((state: RootState) => state.appointments);

  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [slotError, setSlotError] = useState('');

  const [dateOptions, setDateOptions] = useState<
    Array<{ label: string; value: string | number }>
  >([]);

  const [timeSlots, setTimeSlots] = useState<
    Array<{ label: string; value: string | number }>
  >([]);

  const [values, setValues] = useState({
    category: selected?.category_name,
    time: selected?.time,
  });

  const [status, setStatus] = useState(selected?.status || 0);

  const [date, setDate] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    setValues({ ...values, [name]: value });
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getStatus = (status: number) => {
    switch (status) {
      case 0:
        return 'pending';
      case 1:
        return 'in progress';
      case 2:
        return 'completed';
      case 3:
        return 'canceled';
      default:
        return 'pending';
    }
  };

  const handleGetLawyerTimeDates = async () => {
    try {
      const { data } = await getLawyerBusinessDays(
        selected!.lawyer_id,
        getFormatedDate()
      );

      setDateOptions(
        data.data.timingsDetail.map(t => ({
          label: t.business_date,
          value: t.business_day,
        }))
      );

      setDate(
        data.data.timingsDetail.find(t => t.business_date === selected!.date)
          ?.business_day || ''
      );
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.message);
      }
    }
  };

  const handleGetTimings = async () => {
    try {
      setSlotError('');
      const { data } = await getLawyerTimings(
        selected!.lawyer_id,
        date,
        dateOptions.find(d => d.value === date)?.label,
        true
      );
      if (!data.data.timingsDetail.length) {
        setSlotError('No slots available for selected date!');
      }
      setTimeSlots(
        data.data.timingsDetail.map(td => ({
          label: `${getFormatedHours(td.business_time)}`,
          value: td.business_time,
        }))
      );
      const timeSlot =
        data.data.timingsDetail.find(td => td.business_time === selected!.time)
          ?.business_time || data.data.timingsDetail[0].business_time;
      setValues({ ...values, time: timeSlot });
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data.message);
      }
    }
  };

  const handleRescheduleAppointment = async () => {
    try {
      setLoading(true);
      if (date && values.time) {
        const apiData: IRescheduleAppointment = {
          date: getFormatedDate(dateOptions.find(d => d.value === date)!.label),
          time: getFormatedHours(values.time),
        };
        await rescheduleAppointment(selected!.id, apiData);
        setOpen(false);
        onUpdate();
      }
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetLawyerTimeDates();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (date) {
      handleGetTimings();
    }
    // eslint-disable-next-line
  }, [date]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='header'>
          <p className='font-size-24 semi-bold title'>Edit Appoitment</p>
        </div>
        <Content>
          <div className='field-container'>
            <TextField
              label='Category'
              variant='secondary'
              leftIcon={<img src={navIcons.category} alt='' />}
              value={selected?.category_name || ''}
              readOnly
            />
          </div>
          <div className='field-container'>
            <p className='font-size-16 text-secondary'>Date</p>
            <Select
              containerClassName='select-container'
              icon={<img src={assets.calender} alt='' />}
              value={date}
              onChange={e => setDate(e.currentTarget.value)}
              options={dateOptions}
            />
          </div>
          <div className='field-container'>
            <p className='font-size-16 text-secondary'>Time</p>
            <Select
              containerClassName='select-container'
              icon={<img src={assets.time} alt='' />}
              value={values.time}
              name='time'
              onChange={handleChange}
              options={timeSlots}
            />
            {slotError && (
              <p className='font-size-14 text-danger'>{slotError}</p>
            )}
          </div>
          <StatusMenu>
            <div className='row' style={{ gap: 24 }}>
              <p className='font-size-16 text-secondary'>Status:</p>
              <div className='selector row' style={{ position: 'relative' }}>
                <div
                  className='row clickable'
                  style={{ gap: '12px' }}
                  onClick={toggleMenu}
                >
                  <StatusChip label={getStatus(status)} />
                  <Icon icon='f7:chevron-down' />
                </div>
                {openMenu && (
                  <ClickAwayListener onClickAway={closeMenu}>
                    <div className='menu'>
                      <div
                        className='menu-item'
                        onClick={() => {
                          setStatus(0);
                          closeMenu();
                        }}
                      >
                        <p className='font-size-12'>Pending</p>
                      </div>
                      <div
                        className='menu-item'
                        onClick={() => {
                          setStatus(1);
                          closeMenu();
                        }}
                      >
                        <p className='font-size-12'>In Progress</p>
                      </div>
                      <div
                        className='menu-item'
                        onClick={() => {
                          setStatus(3);
                          closeMenu();
                        }}
                      >
                        <p className='font-size-12'>Canceled</p>
                      </div>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
          </StatusMenu>
          <ButtonRow>
            <Button
              variant='outlined'
              label='Cancel'
              disabled={loading}
              onClick={handleClose}
            />
            <Button
              variant='contained'
              label='Save'
              type='submit'
              loading={loading}
              disabled={loading || !!slotError}
              onClick={handleRescheduleAppointment}
            />
          </ButtonRow>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default EditAppointment;

const Container = styled.div`
  width: 100%;
  max-width: 568px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  overflow: hidden;
  & .header {
    background-color: ${({ theme }) => theme.colors.greyBg};
    padding-block: 28px 22px;
    padding-inline: 45px;

    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const Content = styled.div`
  padding-block: 31px 48px;
  padding-inline: 45px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .field-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  & .select-container {
    width: 100%;
    max-width: 100vw;

    & .selection {
      border-color: ${({ theme }) => theme.colors.primary};
      &:has(select:focus) {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
          rgb(51, 51, 51, 0.2) 0px 0px 0px 3px;
      }
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 17px;
  margin-top: 16px;

  & button {
    height: 36px;
    min-width: 0px;
    padding-inline: 22px;
    font-size: 13px;
    font-weight: 600;
  }
`;

const StatusMenu = styled.div`
  margin-top: 23px;
  display: none;

  & .menu {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 162px;
    background-color: ${({ theme }) => theme.colors.white};
    transform: translateY(calc(5px));
    border-radius: 6px;
    box-shadow: 1px 0.5px 8px 0px rgba(0, 0, 0, 0.1);
    padding-block: 19px;

    & .menu-item {
      gap: 15px;
      padding: 6px 19px;
      opacity: 0.6;
      cursor: pointer;
      & p {
        color: ${({ theme }) => theme.colors.primary};
      }
      & .icon {
        width: 14px;
        justify-content: center;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.greyBg};
        opacity: 1;
      }
    }
  }
`;
