import React, { FC } from 'react';

import styled from 'styled-components';

interface IProps {
  src?: string;
  alt?: string;
}

const Avatar: FC<IProps> = props => {
  const { src, alt } = props;
  return (
    <Container>
      <div className='img-container'>
        <img src={src} alt={alt} />
      </div>
    </Container>
  );
};

export default Avatar;

const Container = styled.div`
  position: relative;
  & .img-container {
    height: 51px;
    width: 51px;
    background-color: ${({ theme }) => theme.colors.text.secondary};
    border-radius: 100%;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
