import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IUser } from '../../types/interfaces';
import { IUpdateLawyerPayload } from '../../types/modalInterface';
import { getAllClients, updateClient } from '../../http/services/clientService';
import axios from 'axios';
import { ROUTES } from '../../constants';
import { getUserById } from '../../http/services/authService';

interface IState {
  loading: boolean;
  clients: IUser[];
  totalPages: number;
}

export const clients = createModel<RootModel>()({
  name: 'clients',
  state: {
    loading: false,
    clients: [],
    totalPages: 1,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setClients(state, payload: IUser[]) {
      state.clients = payload;
    },
    setTotalPages(state, payload: number) {
      state.totalPages = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllClients(payload: {
      page: number;
      limit: number;
      key?: string;
      value?: string;
    }) {
      const { page, limit, key, value } = payload;
      try {
        dispatch.clients.setLoading(true);
        const { data } = await getAllClients(page, limit, key, value);
        dispatch.clients.setClients(data.data.users);
        dispatch.clients.setTotalPages(data.data.totalPages);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.clients.setLoading(false);
      }
    },
    async handleUpdateClient(payload: IUpdateLawyerPayload) {
      const { id, values, setOpen, navigate } = payload;
      try {
        dispatch.clients.setLoading(true);
        await updateClient(id, values);
        const { data } = await getUserById(id);
        setOpen(false);
        dispatch.utils.openSuccessModal('Profile updated successfully');
        navigate(ROUTES.CLIENT_DETAILS.replace(':id', id), {
          state: data.data,
          replace: true,
        });
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.clients.setLoading(false);
      }
    },
  }),
});
