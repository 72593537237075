import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import Login from '../pages/Login/Login';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import { ROUTES } from '../constants';
import DashboardLayout from '../Layouts/DashboardLayout/DashboardLayout';
import Dashboard from '../pages/Dashboard/Dashboard';
import Clients from '../pages/Clients/Clients';
import Categories from '../pages/Categories/Categories';
import UpcomingAppointments from '../pages/UpcomingAppointments/UpcomingAppointments';
import ClientDetails from '../pages/ClientDetails/ClientDetails';
import Lawyers from '../pages/Lawyers/Lawyers';
import HelpCenter from '../pages/HelpCenter/HelpCenter';
import SubCategories from '../pages/HelpCenter/SubCategories';
import Questions from '../pages/HelpCenter/Questions';
import LawyerDetails from '../pages/LawyerDetails/LawyerDetails';
import ReportedUsers from '../pages/ReportedUsers/ReportedUsers';
import Notifications from '../pages/Notifications/Notifications';
import Messages from '../pages/Messages/Messages';
import Settings from '../pages/settings/Settings';
import RedirectOutlet from './RedirectOutlet/RedirectOutlet';
import PrivateOutlet from './PrivateOutlet/PrivateOutlet';
import UserInvoices from '../pages/UserInvoices/UserInvoices';
import LawyerPayments from '../pages/LawyerPayments/LawyerPayments';
import LawyerPaymentDetails from '../pages/LawyerPaymentDetails/LawyerPaymentDetails';

const Index = () => {
  return (
    <Routes>
      <Route path='/' element={<RedirectOutlet />}>
        <Route path='' index element={<Navigate to={ROUTES.LOGIN} replace />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      </Route>

      <Route path='/' element={<PrivateOutlet />}>
        <Route path='' element={<DashboardLayout />}>
          <Route element={<Navigate to={ROUTES.DASHBOARD} replace />} />
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route
            path={ROUTES.UPCOMING_APPOINTMENTS}
            element={<UpcomingAppointments />}
          />
          <Route path={ROUTES.CLIENTS} element={<Clients />} />
          <Route path={ROUTES.CLIENT_DETAILS} element={<ClientDetails />} />
          <Route path={ROUTES.LAWYERS} element={<Lawyers />} />
          <Route path={ROUTES.LAWYER_DETAILS} element={<LawyerDetails />} />
          <Route path={ROUTES.CATEGORIES} element={<Categories />} />
          <Route path={ROUTES.HELP_CENTER} element={<HelpCenter />} />
          <Route
            path={ROUTES.HELP_CENTER_SUB_CATEGORIES}
            element={<SubCategories />}
          />
          <Route
            path={ROUTES.HELP_CENTER_SUB_CATEGORIES_QUESTIONS}
            element={<Questions />}
          />
          <Route path={ROUTES.MESSAGES} element={<Messages />} />
          <Route path={ROUTES.REPORTED_USERS} element={<ReportedUsers />} />
          <Route path={ROUTES.SETTINTS} element={<Settings />} />
          <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
          <Route path={ROUTES.USER_INVOICES} element={<UserInvoices />} />
          <Route path={ROUTES.PAYMENT} element={<LawyerPayments />} />
          <Route
            path={ROUTES.PAYMENT_DETAILS}
            element={<LawyerPaymentDetails />}
          />
        </Route>
      </Route>
      <Route path='*' element={<Navigate to={ROUTES.DASHBOARD} replace />} />
    </Routes>
  );
};

export default Index;
