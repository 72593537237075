import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { InferType, number, object } from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import navIcons from '../../assets/navIcons';
import Details from './components/Details';
import About from './components/About';
import LawyerAppointments from './components/LawyerAppointments';
import AdviceSchedule from './components/AdviceSchedule';
import LicenseAndCNIC from './components/License&CNIC';
import { ILawyer } from '../../types/interfaces';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import { updatePlatformFee } from '../../http/services/lawyerService';

const schema = object({
  value: number()
    .positive()
    .max(99)
    .min(0)
    .required('Required')
    .label('Platform fee(%)'),
});

interface IPlatformFee extends InferType<typeof schema> {}

const LawyerDetails = () => {
  const dispatch = useDispatch<Dispatch>();
  const lawyer: ILawyer = useLocation().state;

  const [loading, setLoading] = useState(false);

  const formik = useFormik<IPlatformFee>({
    initialValues: {
      value: 0,
    },
    validationSchema: schema,
    onSubmit: async values => {
      try {
        setLoading(true);
        await updatePlatformFee(lawyer.id, {
          platform_fee_percentage: values.value,
          full_name: lawyer.full_name,
          last_name: lawyer.last_name,
          gender: lawyer.gender,
          address: lawyer.address,
          city: lawyer.city,
          about: lawyer.about,
        });
        dispatch.utils.openSuccessModal('Platform fee updated successfully!');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (lawyer) {
      formik.setValues({ value: lawyer.platform_fee_percentage });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='row header'>
        <img src={navIcons.lawyer} style={{ width: 26 }} alt='' />
        <p className='font-size-24'>{lawyer.full_name}</p>
      </div>
      <div className='lawyer-details'>
        <Details />
      </div>
      <div className='lawyer-details'>
        <UpdatePLatformFee>
          <p className='font-size-22 title'>Platform Fee</p>
          <div className='form' style={{ maxWidth: '420px' }}>
            <TextField
              variant='primary'
              type='number'
              label='Platform fee(%)'
              labelClassName='label'
              value={formik.values.value}
              name='value'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputClassName='text-input'
              error={
                formik.touched.value && formik.errors.value
                  ? formik.errors.value
                  : ''
              }
            />
          </div>
          <div className='button-container'>
            <Button
              variant='contained'
              label='Update Platform Fee'
              loading={loading}
              onClick={() => formik.handleSubmit()}
            />
          </div>
        </UpdatePLatformFee>
      </div>
      {lawyer.rating && <About />}
      <LawyerAppointments />
      <div className='lawyer-details'>
        <AdviceSchedule />
      </div>
      <LicenseAndCNIC />
    </Container>
  );
};

export default LawyerDetails;

const Container = styled.div`
  padding-block: 29px 50px;

  & .header {
    gap: 20px;
    color: ${({ theme }) => theme.colors.primary};
  }
  & .lawyer-details {
    margin-block: 21px;
  }
`;

const UpdatePLatformFee = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  padding: 40px 36px;

  & .text-input {
    font-size: 18px;
    padding-inline: 12px;
    color: ${({ theme }) => theme.colors.primary};
  }

  & .label {
    margin-bottom: 6px;
  }
  & .title {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
  }

  & .button-container {
    margin-top: 30px;
  }
`;
