import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { boolean, object, string } from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';

import TextField from '../../../components/TextField/TextField';
import Button from '../../../components/Button/Button';
import theme from '../../../theme';
import { updatePaymentMethod } from '../../../http/services/paymentService';

const schema = object({
  id: string().required().label('Id'),
  name: string().required().label('Bank Name'),
  account_title: string().required().label('Account Name'),
  account_number: string().required().label('Account Number'),
  is_active: boolean().required().label('Active'),
});

const BankDetails = () => {
  const dispatch = useDispatch<Dispatch>();
  const [loading, setLoading] = useState(false);
  const { paymentMethods } = useSelector((state: RootState) => state.payments);

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      account_title: '',
      account_number: '',
      is_active: true,
    },
    validationSchema: schema,
    onSubmit: async values => {
      const { id, ...rest } = values;
      try {
        setLoading(true);
        await updatePaymentMethod(id, rest);
        dispatch.utils.openSuccessModal('Bank details updated successfully!');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (paymentMethods.length) {
      const method = paymentMethods.find(
        method => method.name === 'Bank Transfer'
      );
      if (method) {
        formik.setValues({
          id: method.id,
          name: method.name,
          account_title: method.account_title,
          account_number: method.account_number,
          is_active: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [paymentMethods]);

  return (
    <Box>
      <div className='row' style={{ gap: '10px' }}>
        <Icon
          icon='mingcute:bank-line'
          fontSize={25}
          color={theme.colors.primary}
        />
        <p className='font-size-18 medium text-blue'>Bank Details</p>
      </div>
      <Form>
        <TextField
          variant='secondary'
          label='Account Name'
          required
          name='account_title'
          value={formik.values.account_title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.account_title && formik.errors.account_title
              ? formik.errors.account_title
              : ''
          }
        />
        <div />
        <TextField
          variant='secondary'
          label='Account Number'
          required
          name='account_number'
          value={formik.values.account_number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.account_number && formik.errors.account_number
              ? formik.errors.account_number
              : ''
          }
        />
        <div />
        <div style={{ paddingTop: '10px' }}>
          <Button
            label='Update Details'
            type={'submit'}
            onClick={formik.handleSubmit}
            loading={loading}
          />
        </div>
      </Form>
    </Box>
  );
};

export default BankDetails;

const Box = styled.div`
  padding: 20px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
`;
