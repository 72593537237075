import React, { SelectHTMLAttributes, FC } from 'react';
import { Icon } from '@iconify/react';

import styled from 'styled-components';
import classNames from 'classnames';

interface IProps extends SelectHTMLAttributes<HTMLSelectElement> {
  containerClassName?: string;
  icon?: React.ReactNode;
  options: Array<{ label: string; value: number | string }>;
  search?: boolean;
}

const Select: FC<IProps> = props => {
  const { containerClassName, icon, options, search, ...rest } = props;
  return (
    <Container className={containerClassName}>
      <SelectContainer hasIcon={!!icon} className='selection'>
        {icon && (
          <IconContainer className={classNames(search && 'search')}>
            {icon}
          </IconContainer>
        )}
        <select {...rest} className={classNames(search && 'search')}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className={classNames('arrow-icon', search && 'search')}>
          <Icon icon='akar-icons:chevron-down' className='select-icon' />
        </div>
      </SelectContainer>
    </Container>
  );
};

export default Select;

const Container = styled.div`
  width: 100%;
  max-width: 320px;
`;

const SelectContainer = styled.label<{ hasIcon: boolean }>`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  align-items: center;

  & select {
    -webkit-appearance: none;
    height: 48px;
    width: 100%;
    outline: none;
    border: none;
    padding-inline: ${({ hasIcon }) => (hasIcon ? '36px 16px' : '16px')};
    font-size: 13px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600;
    cursor: pointer;

    &.search {
      background-color: ${({ theme }) => theme.colors.greyBg};
      height: 44px;
    }
  }
  & .arrow-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 30px;
    background-color: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .select-icon {
      color: ${({ theme }) => theme.colors.primary};
      transition: 0.4s ease;
    }
    &.search {
      background-color: ${({ theme }) => theme.colors.greyBg};
    }
  }

  &:has(select:focus) {
    & .arrow-icon {
      & .select-icon {
        transform: rotate(180deg);
      }
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2px;
`;
