import React, { ChangeEvent, useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';
import theme from '../../../theme';
import Select from '../../../components/Select/Select';
import { ILawyer, ILawyerTiming } from '../../../types/interfaces';
import axios from 'axios';
import {
  getLawyerBusinessDays,
  getLawyerTimings,
} from '../../../http/services/lawyerService';
import { getFormatedDate, getFormatedHours } from '../../../utils';

const AdviceSchedule = () => {
  const lawyer: ILawyer = useLocation().state;

  const [morningTimings, setMorningTimings] = useState<ILawyerTiming[]>([]);
  const [nightTimings, setNightTimings] = useState<ILawyerTiming[]>([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [day, setDay] = useState('');

  const [options, setOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const handleGetLawyerTimingDays = async () => {
    try {
      const { data } = await getLawyerBusinessDays(
        lawyer.id,
        getFormatedDate()
      );

      console.log(data);

      setOptions(
        data.data.timingsDetail.map(td => ({
          label: td.business_date,
          value: td.business_day,
        }))
      );
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data.message);
      }
    }
  };

  const handleGetLawyerTimings = async () => {
    try {
      setLoading(true);
      const date = options.find(option => option.value === day)?.label;
      const { data } = await getLawyerTimings(lawyer.id, day, date, true);
      const timings = data.data.timingsDetail;
      setNightTimings(timings.filter(timing => timing.isEveningTime));
      setMorningTimings(timings.filter(timing => !timing.isEveningTime));
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setDay(value);
  };

  useEffect(() => {
    if (lawyer && options.length) {
      handleGetLawyerTimings();
    }
    // eslint-disable-next-line
  }, [day]);

  useEffect(() => {
    handleGetLawyerTimingDays();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (options.length) setDay(options[0].value);
    // eslint-disable-next-line
  }, [options]);

  return (
    <>
      {!!options.length && (
        <Container>
          <p className='font-size-18 text-blue bold'>
            Detailed Advice Schedule
          </p>
          <div className='selector'>
            <Select
              options={options}
              value={day}
              onChange={handleChange}
              icon={
                <Icon
                  icon='uil:calender'
                  style={{ fontSize: '18px' }}
                  color={theme.colors.primary}
                />
              }
            />
          </div>
          <FlexContainer className='row'>
            <div className='day-time'>
              <div className='row gap-8'>
                <Icon
                  icon='wi:day-sunny'
                  color={theme.colors.primary}
                  fontWeight='700'
                  style={{ fontSize: '25px' }}
                />
                <p className='font-size-13 text-blue semi-bold'>Morning Time</p>
              </div>
              <TimeChipsContainer>
                {morningTimings.map(time => (
                  <TimeChip key={time.id}>
                    {getFormatedHours(time.business_time)}
                  </TimeChip>
                ))}
              </TimeChipsContainer>
            </div>
            <div className='evening-time'>
              <div className='row gap-8'>
                <Icon
                  icon='tabler:moon-stars'
                  color={theme.colors.primary}
                  style={{ fontSize: '20px' }}
                />
                <p className='font-size-13 text-blue semi-bold'>Evening Time</p>
              </div>
              <TimeChipsContainer>
                {nightTimings.map(time => (
                  <TimeChip key={time.id}>
                    {getFormatedHours(time.business_time)}
                  </TimeChip>
                ))}
              </TimeChipsContainer>
            </div>
          </FlexContainer>
        </Container>
      )}
    </>
  );
};

export default AdviceSchedule;

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  padding: 41px 45px;

  & .selector {
    margin-block: 18px 32px;
    width: 170px;
  }

  margin-block: 43px 23px;
`;

const FlexContainer = styled.div`
  gap: 80px;
  align-items: flex-start;

  & .day-time,
  & .evening-time {
    flex: 1;
    flex-shrink: 0;
  }

  & .gap-8 {
    gap: 8px;
  }
`;

const TimeChipsContainer = styled.div`
  margin-top: 31px;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
`;

const TimeChip = styled.div`
  font-size: 13px;
  font-weight: 500;
  min-width: 92px;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
  padding: 6px 15px;
`;
