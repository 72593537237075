import classNames from 'classnames';
import React, { FC, TextareaHTMLAttributes } from 'react';

import styled from 'styled-components';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  icon?: React.ReactNode;
  error?: string;
}

const Textarea: FC<IProps> = props => {
  const { label, icon, error, ...rest } = props;
  return (
    <Container>
      <label htmlFor='txt'>
        <p className='font-size-16 text-secondary semi-bold'>{label}</p>
      </label>
      <InputContainer
        icon={!!icon}
        className={classNames(error ? 'error' : '')}
      >
        <textarea id='txt' {...rest}></textarea>
        {icon && <div className='icon row'>{icon}</div>}
      </InputContainer>
      {error && <p className='text-danger font-size-14'>{error}</p>}
    </Container>
  );
};

export default Textarea;

const Container = styled.div`
  & p.text-danger {
    margin-top: 4px;
  }
`;

const InputContainer = styled.div<{ icon: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  opacity: 0.8;
  margin-top: 16px;
  position: relative;

  &:not(.error):has(textarea:focus) {
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
      rgb(51, 51, 51, 0.2) 0px 0px 0px 3px;
  }

  &:has(textarea:not(:placeholder-shown)) {
    opacity: 1;
  }

  &.error {
    border: 1px solid ${({ theme }) => theme.colors.text.danger};
  }

  & textarea {
    outline: none;
    border: none;
    width: 100%;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.primary};
    resize: none;
    padding: 15px 14px;
    padding-left: ${({ icon }) => (icon ? '54px' : '14px')};
  }

  & .icon {
    position: absolute;
    left: 14px;
    top: 15px;
  }
`;
