const assets = {
  loginBg: require('./img/login-background.png'),
  user: require('./icons/user.svg').default,
  lock: require('./icons/lock.svg').default,
  lockFilled: require('./icons/lock-filled.svg').default,
  checkbox: require('./icons/checkbox.svg').default,
  arrowLeft: require('./icons/arrow-left.svg').default,
  arrowRightWhite: require('./icons/arrow-right-white.svg').default,
  mail: require('./icons/mail.svg').default,
  lawBackground: require('./img/law-background.png'),
  fullLogo: require('./icons/logo-full.svg').default,
  bars: require('./icons/bars.svg').default,
  search: require('./icons/search.svg').default,
  bell: require('./icons/bell.svg').default,
  userProfile: require('./img/user.png'),
  bin: require('./icons/bin.svg').default,
  deadline: require('./icons/deadline.svg').default,
  visa: require('./icons/visa.svg').default,
  handShake: require('./icons/hand-shake.svg').default,
  tax: require('./icons/tax.svg').default,
  employee: require('./icons/employee.svg').default,
  family: require('./icons/family.svg').default,
  card: require('./icons/card.svg').default,
  send: require('./icons/send.svg').default,
  file: require('./icons/file.svg').default,
  video: require('./icons/video.svg').default,
  success: require('./icons/success.svg').default,
  preview: require('./img/preview.png'),
  calender: require('./icons/calender.svg').default,
  time: require('./icons/time.svg').default,
  checkboxChecked: require('./icons/checkbox-checked.svg').default,
  logoSm: require('./icons/logo-sm.svg').default,
  money: require('./icons/money.svg').default,
  arrowRight: require('./icons/arrow-right.svg').default,
  upload: require('./icons/upload.svg').default,
  close: require('./icons/close.svg').default,
  download: require('./icons/download.svg').default,
  jazzCash: require('./img/jazz-cash.png'),
  easyPaisa: require('./img/easy-paisa.png'),
};

export default assets;
