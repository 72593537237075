import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../../store';

import SearchBar from '../../../components/SearchBar/SearchBar';

import assets from '../../../assets';

interface IProps {}

const MyChats: FC<IProps> = () => {
  const dispatch = useDispatch<Dispatch>();

  const { chatUsers, selected } = useSelector((state: RootState) => state.chat);
  const [search, setSearch] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  const filteredChats = chatUsers.filter(chat =>
    chat.full_name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Chats>
      <div className='search-container'>
        <SearchBar
          value={search}
          onChange={handleSearch}
          containerClassName='search'
          placeholder='Search People or Message'
        />
      </div>
      <ChatList>
        {filteredChats?.map(chat => (
          <ChatItem
            key={chat.id}
            className={selected?.id === chat.id ? 'active' : ''}
            onClick={() => dispatch.chat.setSelected(chat)}
          >
            <div className='row' style={{ gap: '14px' }}>
              <div className='img-container'>
                <img
                  src={
                    chat.image_url || assets.preview
                    // users && users![chat.conversationId]
                    //   ? users![chat.conversationId]?.avatarurl
                    //   : assets.preview
                  }
                  alt=''
                />
              </div>
              <div className=''>
                <p className='font-size-16 text-primary semi-bold'>
                  {chat.full_name} {chat.last_name}
                  {/* {users && users![chat.conversationId]
                    ? users![chat.conversationId]?.nickname
                    : ''} */}
                </p>
                <div className='row' style={{ marginTop: '8px' }}>
                  <p className='font-size-12 text-secondary'>
                    {/* {(chat.lastMessage as ILastMessage)?.msg} */}
                  </p>
                </div>
              </div>
              <div className='time-unread'>
                {/* <p className='font-size-10 text-blue'>10.42 AM</p> */}
                {/* {!selectedChat?.conversationId && (
                  <UnreadCount>{chat.unReadCount}</UnreadCount>
                )} */}
              </div>
            </div>
          </ChatItem>
        ))}
      </ChatList>
    </Chats>
  );
};

export default MyChats;

const Chats = styled.div`
  min-width: 338px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & .search-container {
    padding-block: 19px 11px;
    padding-inline: 19px;

    & .search {
      width: 100%;
    }
  }
`;

const ChatList = styled.div`
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
`;

const ChatItem = styled.div`
  height: 66px;
  /* background-color: ${({ theme }) => theme.colors.primary}; */
  padding: 7.5px 16px;

  & .img-container {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    overflow: hidden;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & p {
    line-height: 1;
  }

  & .time-unread {
    margin-left: auto;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    & .text-blue {
      opacity: 0.6;
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.primary};
    & p {
      color: #ffffff !important;
    }
  }
`;
// eslint-disable-next-line
const UnreadCount = styled.div`
  margin-top: 6px;
  border-radius: 6px;
  padding: 3px 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  font-size: 12px;
  font-weight: 600;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
`;
