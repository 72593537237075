export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  CLIENTS: '/clients',
  CATEGORIES: '/categories',
  UPCOMING_APPOINTMENTS: '/upcoming-appointments',
  CLIENT_DETAILS: '/clients/details/:id',
  LAWYERS: '/lawyers',
  LAWYER_DETAILS: '/lawyers/details/:id',
  HELP_CENTER: '/help-center',
  HELP_CENTER_SUB_CATEGORIES: '/help-center/:id',
  HELP_CENTER_SUB_CATEGORIES_QUESTIONS: '/help-center/:id/questions',
  REPORTED_USERS: '/reported-users',
  NOTIFICATIONS: '/notifications',
  MESSAGES: '/messages',
  SETTINTS: '/settings',
  USER_INVOICES: '/user-invoices',
  PAYMENT: '/lawyer-payments',
  PAYMENT_DETAILS: '/lawyer-payments/details',
};
