import { FC } from 'react';

import styled from 'styled-components';

type variant =
  | 'win'
  | 'pending'
  | 'in progress'
  | 'canceled'
  | 'active'
  | 'inactive'
  | 'completed';

const StatusChip: FC<{ label: variant }> = ({ label }) => {
  return <Chip className={label}>{label}</Chip>;
};

export default StatusChip;

const Chip = styled.div`
  padding: 3px 12px;
  font-size: 12px;
  text-transform: capitalize;
  display: inline-block;
  border-radius: 4px;
  white-space: nowrap;

  &.pending {
    color: #f9461c;
    border: 1px solid #f9461c;
    background: #fde8e9;
  }

  &.in.progress {
    color: #fabe10;
    border: 1px solid #fabe10;
    background: #fef7e2;
  }
  &.canceled {
    color: ${({ theme }) => theme.colors.text.primary};
    border: 1px solid ${({ theme }) => theme.colors.text.primary};
    background: #e6e5e5;
  }
  &.win {
    color: #34a853;
    border: 1px solid #34a853;
    background: #e6f4ea;
  }
  &.completed {
    color: #34a853;
    border: 1px solid #34a853;
    background: #e6f4ea;
  }
  &.active {
    color: #35a72be3;
    background: #c9f8c7;
    border-radius: 7px;
  }
  &.inactive {
    color: #ff727b;
    background: rgba(255, 114, 123, 0.32);
    border-radius: 7px;
  }
`;
