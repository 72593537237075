import React, { FC } from 'react';

import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../store';

import assets from '../../../assets';
import theme from '../../../theme';
import { IHelpQuestion } from '../../../types/interfaces';

interface IProps {
  data: IHelpQuestion;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Question: FC<IProps> = ({ data, setOpenModal }) => {
  const dispatch = useDispatch<Dispatch>();

  const handleDelete = () => {
    dispatch.utils.openConfirmationModal({
      action: () => dispatch.help.handleDeleteQuestion(data.id),
      actionTitle: 'Delete',
      message: 'Are you sure you want to delete this question?',
    });
  };

  const handleUpdate = () => {
    dispatch.help.setSelectedQuestion(data);
    setOpenModal(true);
  };

  return (
    <Container>
      <div className='row space-between'>
        <p className='font-size-16 semi-bold title'>{data.question}</p>
        <div className='row actions'>
          <div className='row clickable' role='button' onClick={handleDelete}>
            <img src={assets.bin} style={{ width: '20px' }} alt='' />
          </div>
          <div className='row clickable' role='button' onClick={handleUpdate}>
            <Icon
              icon='mdi:file-edit-outline'
              style={{ fontSize: 25 }}
              color={theme.colors.primary}
            />
          </div>
        </div>
      </div>
      <p className='font-size-13 medium text-secondary'>{data.answer}</p>
    </Container>
  );
};

export default Question;

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  padding: 22px 28px;

  & .title {
    color: ${({ theme }) => theme.colors.primary};
  }

  & .actions {
    gap: 20px;
  }

  & .font-size-13 {
    margin-top: 13px;
    max-width: 90%;
  }
`;
