import React from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import assets from '../../assets';
import { ROUTES } from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const Notifications = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.NOTIFICATIONS);
  };

  const { unreadCount } = useSelector(
    (state: RootState) => state.notifications
  );

  return (
    <Container role='button' onClick={handleClick}>
      <div className='icon row'>
        <img src={assets.bell} alt='' />
      {unreadCount > 0 && <div className='indicator' />}
      </div>
    </Container>
  );
};

export default Notifications;

const Container = styled.div`
  height: 46px;
  width: 46px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.greyBg};
  display: grid;
  place-items: center;
  cursor: pointer;

  & .icon {
    position: relative;
    & .indicator {
      height: 8px;
      width: 8px;
      background-color: ${({ theme }) => theme.colors.orange};
      position: absolute;
      border-radius: 50%;
      top: -2px;
      right: -2px;
    }
  }
`;
