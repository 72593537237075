import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useFormik } from 'formik';
import { object, string, boolean } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import DialogLayout from '../DialogLayout';
import Button from '../../components/Button/Button';
import TextField from '../../components/TextField/TextField';
import navIcons from '../../assets/navIcons';
import Switch from '../../components/Switch/Switch';
import assets from '../../assets';
import { Icon } from '@iconify/react';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const schema = object({
  name: string().min(3).required().label('Name'),
  isActive: boolean().required().label('Active'),
});

const AddCaseCategory: FC<IProps> = props => {
  const { open, setOpen } = props;

  const dispatch = useDispatch<Dispatch>();

  const { loading, selected } = useSelector(
    (state: RootState) => state.categories
  );

  const [image, setImage] = useState<File | string | null>(null);
  const [imageError, setImageError] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      isActive: false,
    },
    validationSchema: schema,
    onSubmit: values => {
      if (!image) {
        setImageError('Image is required');
        return;
      } else {
        if (selected) {
          dispatch.categories.handleUpdateCategory({
            values,
            formik,
            setOpen,
            image: image && typeof image !== 'string' ? image : null,
          });
        } else {
          dispatch.categories.handleAddCategory({
            values,
            formik,
            setOpen,
            image,
          });
        }
      }
    },
  });

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setImageError('');
    if (file) {
      setImage(file);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    formik.setFieldValue('isActive', checked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selected) {
      formik.setValues({
        name: selected.name,
        isActive: selected.isActive,
      });
      setImage(selected.icon_url);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='header'>
          <p className='font-size-24 semi-bold title'>Add Category</p>
        </div>
        <Content>
          <div
            className='justify-center row'
            style={{ flexDirection: 'column', gap: '5px' }}
          >
            <ImagePicker>
              <label htmlFor='image' className='lable'>
                <div className='picker'>
                  <div className='image-wrapper'>
                    <img
                      src={
                        image
                          ? typeof image === 'string'
                            ? image
                            : URL.createObjectURL(image)
                          : assets.preview
                      }
                      alt=''
                    />
                  </div>

                  <div className='icon-container'>
                    <Icon icon='mdi:image-edit' className='icon' />
                  </div>
                </div>
              </label>
              <input
                type='file'
                name=''
                accept='image/jpeg , image/png , image/jpg'
                id='image'
                onChange={handleImageChange}
              />
            </ImagePicker>
            {imageError && (
              <p className='font-size-14 text-danger'>{imageError}</p>
            )}
          </div>
          <TextField
            variant='secondary'
            label='Category'
            type='text'
            name='name'
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Add category'
            leftIcon={
              <IConContainer>
                <img src={navIcons.category} alt='' />
              </IConContainer>
            }
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : ''
            }
          />
          <div className='row space-between pub-row'>
            <p className='font-size-16 semi-bold text-blue'>Publish Category</p>
            <Switch
              checked={formik.values.isActive}
              onChange={handleChange}
              name='isActive'
            />
          </div>
          <ButtonRow>
            <Button
              variant='outlined'
              label='Cancel'
              disabled={loading}
              onClick={handleClose}
            />
            <Button
              variant='contained'
              label={selected ? 'Update' : 'Save'}
              type='submit'
              loading={loading}
              onClick={() => {
                if (!image) {
                  setImageError('Image is required');
                }
                formik.handleSubmit();
              }}
            />
          </ButtonRow>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default AddCaseCategory;

const Container = styled.div`
  width: 100%;
  max-width: 568px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .header {
    background-color: ${({ theme }) => theme.colors.greyBg};
    padding-block: 28px 22px;
    padding-inline: 45px;

    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  max-height: 85vh;
`;

const Content = styled.div`
  padding-block: 31px 48px;
  padding-inline: 45px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  overflow: auto;

  & .pub-row {
    margin-top: 15px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 17px;
  margin-top: 36px;

  & button {
    height: 36px;
    min-width: 0px;
    padding-inline: 22px;
    font-size: 13px;
    font-weight: 600;
  }
`;

const IConContainer = styled.div`
  width: 25px;
  display: grid;
  place-items: center;
`;

const ImagePicker = styled.div`
  & .lable {
    & .picker {
      height: 100px;
      width: 100px;
      background-color: ${({ theme }) => theme.colors.greyBg};
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      padding: 8px;

      & .image-wrapper {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        overflow: hidden;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      & .icon-container {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        right: -1px;
        background-color: ${({ theme }) => theme.colors.border};
        display: grid;
        place-items: center;
        & .icon {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  & input {
    display: none;
  }
`;
