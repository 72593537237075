import React, { FC } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Button from '../../components/Button/Button';
import DialogLayout from '../DialogLayout';

import assets from '../../assets';
interface IProps {}

const Toast: FC<IProps> = () => {
  const dispatch = useDispatch<Dispatch>();
  const { successModal, successMessage } = useSelector(
    (state: RootState) => state.utils
  );

  const handleClose = () => {
    dispatch.utils.closeSuccessModal();
  };

  return (
    <DialogLayout open={successModal}>
      <Container>
        <img src={assets.success} alt='' />
        <p className='font-size-16 text-blue semi-bold message'>
          {successMessage}
        </p>
        <Button label='OK' onClick={handleClose} />
      </Container>
    </DialogLayout>
  );
};

export default Toast;

const Container = styled.div`
  padding-block: 22px 50px;
  padding-inline: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  width: 248px;

  & .message {
    margin-block: 25px 34px;
  }
`;
