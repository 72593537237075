import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ICategory } from '../../types/interfaces';
import {
  addCategory,
  addCategoryImage,
  getCategories,
  updateCategory,
} from '../../http/services/categoryService';
import { Token } from '../../types/enums';
import { apiKey } from '../../app.config';

import { IUpdateCategory } from '../../types/apiInterface';
import {
  IAddCategoryPayload,
  IUpdateCategoryPayload,
} from '../../types/modalInterface';
import axios from 'axios';

interface IState {
  loading: boolean;
  categories: ICategory[];
  selected: ICategory | null;
}

export const categories = createModel<RootModel>()({
  name: 'categories',
  state: {
    loading: false,
    categories: [],
    selected: null,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setCategories(state, payload: ICategory[]) {
      state.categories = payload;
    },
    setSelected(state, payload: ICategory | null) {
      state.selected = payload;
    },
  },
  effects: dispatch => ({
    async getAllCategories() {
      try {
        dispatch.categories.setLoading(true);
        const { data } = await getCategories({
          headers: {
            [Token.API_KEY]: apiKey,
          },
        });
        dispatch.categories.setCategories(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.categories.setLoading(false);
      }
    },
    async handleCategoryStatus(payload: {
      values: IUpdateCategory;
      id: string;
    }) {
      const { values, id } = payload;
      try {
        dispatch.categories.setLoading(true);
        await updateCategory(values, id);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.categories.setLoading(false);
      }
    },
    async handleAddCategory(payload: IAddCategoryPayload) {
      const { values, image, setOpen } = payload;
      try {
        dispatch.categories.setLoading(true);
        const { data } = await addCategory(values);
        const formData = new FormData();
        formData.append('icon', image);
        await addCategoryImage(formData, data.data.id);
        dispatch.categories.getAllCategories();
        setOpen(false);
        dispatch.utils.openSuccessModal('New category added successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.categories.setLoading(false);
      }
    },
    async handleUpdateCategory(payload: IUpdateCategoryPayload, state) {
      const { values, image, setOpen } = payload;
      const { selected } = state.categories;
      try {
        dispatch.categories.setLoading(true);
        await updateCategory(values, selected!.id);
        if (image) {
          const formData = new FormData();
          formData.append('icon', image);
          await addCategoryImage(formData, selected!.id);
        }
        dispatch.categories.setSelected(null);
        dispatch.categories.getAllCategories();
        setOpen(false);
        dispatch.utils.openSuccessModal('Category updated successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.categories.setLoading(false);
      }
    },
  }),
});
