import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button/Button';
import HelpSubCategory from './components/HelpSubCategory';
import AddSubCategory from '../../Dialogs/AddSubCategory/AddSubCategory';

import assets from '../../assets';
import { IHelpCategory } from '../../types/interfaces';
import Loading from '../../components/Loading/Loading';

const SubCategories = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const { helpSubCategories, loading, selectedCategory } = useSelector(
    (state: RootState) => state.help
  );

  const parentCategory: IHelpCategory = useLocation().state;
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
    dispatch.help.setSelectedSubCategory(null);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (parentCategory) {
      dispatch.help.handleGetAllSubCategories(parentCategory.id);
      if (!selectedCategory) {
        dispatch.help.setSelectedCategory(parentCategory);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <BackButton onClick={handleGoBack}>
        <div className='icon-container'>
          <img src={assets.arrowLeft} alt='' />
        </div>
        <p className='font-size-16 text-blue semi-bold'>Back</p>
      </BackButton>
      <div className='row space-between header'>
        <div className='row header'>
          <img
            src={assets.handShake}
            style={{ width: '32px', marginTop: '5px' }}
            alt=''
          />
          <div>
            <p className='font-size-24 title'>{parentCategory?.title}</p>
            <p className='font-size-16 title'>Sub-Categories</p>
          </div>
        </div>
        <Button
          label='+ Add New Sub-Category'
          className='btn'
          onClick={handleOpenModal}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Grid>
          {helpSubCategories.map(category => (
            <HelpSubCategory
              data={category}
              key={category.id}
              setOpenModal={setOpenModal}
            />
          ))}
        </Grid>
      )}
      {openModal && <AddSubCategory open={openModal} setOpen={setOpenModal} />}
    </Container>
  );
};

export default SubCategories;

const Container = styled.div`
  padding-block: 29px 30px;

  & .header {
    align-items: flex-start;
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  & .btn {
    padding-inline: 34px;
  }
`;

const Grid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
`;

const BackButton = styled.button`
  all: unset;
  gap: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  & .icon-container {
    height: 40px;
    width: 40px;
    background-color: ${({ theme }) => theme.colors.greyBg};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
`;
