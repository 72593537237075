import React, { FC, useEffect, useState } from 'react';
import { ILawyer } from '../../types/interfaces';
import assets from '../../assets';
import StatusChip from '../../components/StatusChip/StatusChip';
import Switch from '../../components/Switch/Switch';
import { updateLawyerDocs } from '../../http/services/lawyerService';

interface IProps {
  lawyer: ILawyer;
  handleClick: (lawyer: ILawyer) => void;
}

const LawyerRow: FC<IProps> = ({ lawyer, handleClick }) => {
  const [active, setActive] = useState(false);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setActive(e.currentTarget.checked);
    await updateLawyerDocs(lawyer.id, e.currentTarget.checked);
  };

  useEffect(() => {
    setActive(lawyer.is_doc_verified);
  }, [lawyer]);

  return (
    <tr key={lawyer.id}>
      <td>
        <div className='row' style={{ gap: '17px' }}>
          <img
            src={lawyer.image_url || assets.preview}
            className='user-profile'
            alt=''
          />
          <span style={{ font: 'inherit' }}>
            {lawyer.full_name} {lawyer.last_name}
          </span>
        </div>
      </td>
      <td>{lawyer.email}</td>
      <td>{lawyer.mobile}</td>
      <td>{lawyer.year_of_experince}</td>
      <td>{lawyer.platform_fee_percentage}</td>
      <td>
        <StatusChip label={active ? 'active' : 'inactive'} />
      </td>
      <td>
        <Switch checked={active} onChange={handleChange} />
      </td>
      <td>
        <div
          className='row clickable'
          style={{ gap: '5px' }}
          onClick={() => handleClick(lawyer)}
        >
          <p
            className='font-size-13 text-blue medium'
            style={{ whiteSpace: 'nowrap' }}
          >
            View Details
          </p>
          <img src={assets.arrowRight} alt='' />
        </div>
      </td>
    </tr>
  );
};

export default LawyerRow;
