import { FC, useEffect, useState } from 'react';

import styled from 'styled-components';

import assets from '../../assets';

interface IProps {
  checked?: boolean;
  onChange?: (v: boolean) => void;
}

const Checkbox: FC<IProps> = ({ checked: val, onChange }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (val !== undefined) setChecked(val);
  }, [val]);

  return (
    <Container>
      <div
        className='box'
        onClick={() => {
          setChecked(!checked);
          if (onChange) {
            onChange(!checked);
          }
        }}
      >
        <img src={checked ? assets.checkboxChecked : assets.checkbox} alt='' />
      </div>
    </Container>
  );
};

export default Checkbox;

const Container = styled.div`
  & box {
    line-height: 14px;
    height: 14px;
    width: 14px;
    cursor: pointer;
  }

  & input {
    display: none;
  }
`;
