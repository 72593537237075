import { AxiosRequestConfig } from 'axios';
import api from '..';
import { IApiResponse, IUpdateCategory } from '../../types/apiInterface';
import { ICategory } from '../../types/interfaces';

export const getCategories = (config?: AxiosRequestConfig) =>
  api.get<IApiResponse<ICategory[]>>('/v1/category?isMobile=false', config);

export const updateCategory = (data: IUpdateCategory, categoryId: string) =>
  api.patch(`/v1/category/${categoryId}`, data);

export const addCategory = (data: IUpdateCategory) =>
  api.post<IApiResponse<ICategory>>('/v1/category', data);

export const addCategoryImage = (data: FormData, categoryId: string) =>
  api.patch(`/v1/category/image/${categoryId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
