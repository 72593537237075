import classNames from 'classnames';
import { FC } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import theme from '../../theme';

type SIZE = 'small' | 'large';

interface IProps {
  label: string;
  variant?: 'contained' | 'outlined';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  size?: SIZE;
  [x: string]: any;
}

const Button: FC<IProps> = props => {
  const {
    leftIcon,
    rightIcon,
    variant = 'contained',
    className,
    label,
    size = 'large',
    disabled,
    loading,
    ...rest
  } = props;

  return (
    <StyledButton
      size={size}
      className={classNames(variant, className)}
      {...rest}
      disabled={disabled || loading}
    >
      {loading ? (
        <ClipLoader size={20} color={theme.colors.white} />
      ) : (
        <>
          {leftIcon && leftIcon}
          <span>{label}</span>
          {rightIcon && rightIcon}
        </>
      )}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button<{ size: SIZE }>`
  all: unset;
  height: ${({ size }) => (size === 'small' ? '36px' : '50px')};
  min-width: ${({ size }) => (size === 'small' ? 'unset' : '155px')};
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 7px;
  font-size: ${({ size }) => (size === 'small' ? '13px' : '16px')};
  font-weight: 600;
  padding-inline: ${({ size }) => (size === 'small' ? '22px' : '22px')};
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }

  &.contained {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }

  &.outlined {
    border: 1px solid ${({ theme }) => theme.colors.border};
    background-color: transparent;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;
