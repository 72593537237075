import { createModel } from '@rematch/core';
import { RootModel } from '.';
import axios from 'axios';
import {
  getPaymentMethods,
  getPaymentRequests,
} from '../../http/services/paymentService';
import { IPaymentMethod, ITransaction } from '../../types/interfaces';

interface IState {
  loading: boolean;
  totalPages: number;
  requests: ITransaction[];
  approved: string;
  pending: string;
  paymentMethods: IPaymentMethod[];
}

export const payments = createModel<RootModel>()({
  name: 'payments',
  state: {
    loading: false,
    totalPages: 1,
    requests: [],
    approved: '',
    pending: '',
    paymentMethods: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setTotalPages(state, payload: number) {
      state.totalPages = payload;
    },
    setRequests(state, payload: ITransaction[]) {
      state.requests = payload;
    },
    setApproved(state, payload: string) {
      state.approved = payload;
    },
    setPending(state, payload: string) {
      state.pending = payload;
    },
    setPaymentMethods(state, payload: IPaymentMethod[]) {
      state.paymentMethods = payload;
    },
  },
  effects: dispatch => ({
    async handleGetAllPaymentRequests(payload: {
      page: number;
      limit: number;
      status: 0 | 1;
    }) {
      try {
        dispatch.payments.setLoading(true);
        const { page, limit, status } = payload;
        const { data } = await getPaymentRequests({ page, limit, status });
        dispatch.payments.setRequests(data.data.transactions);
        dispatch.payments.setTotalPages(data.data.totalPages);
        dispatch.payments.setApproved(data.data.approved);
        dispatch.payments.setPending(data.data.pending);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data);
        }
      } finally {
        dispatch.payments.setLoading(false);
      }
    },
    async handleGetPaymentMethods() {
      try {
        dispatch.payments.setLoading(true);
        const { data } = await getPaymentMethods();
        dispatch.payments.setPaymentMethods(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.payments.setLoading(false);
      }
    },
  }),
});
