import React, { FC } from 'react';

import styled from 'styled-components';
import { useFormik } from 'formik';
import { object, string, ref, InferType } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import DialogLayout from '../DialogLayout';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import { IModalProps } from '../../types/interfaces';

const schema = object({
  password: string().required(),
  new_password: string().min(8).required().label('New password'),
  confirm_password: string()
    .oneOf(
      [ref('new_password'), ''],
      'Confirm password must be same as New password'
    )
    .required()
    .label('Confirm password'),
});

interface IProps extends IModalProps {}

export interface IChangePasswordValues extends InferType<typeof schema> {}

const UpdatePassword: FC<IProps> = props => {
  const { open, setOpen } = props;

  const dispatch = useDispatch<Dispatch>();

  const { loading } = useSelector((state: RootState) => state.auth);

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik<IChangePasswordValues>({
    initialValues: {
      password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      console.log(values);
      dispatch.auth.handleChangePassword({ values, formik, setOpen });
    },
  });

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='header'>
          <p className='font-size-24 semi-bold title'>Edit Profile</p>
        </div>
        <Content>
          <TextField
            variant='secondary'
            label='Old Password'
            required
            type='password'
            placeholder='********'
            name='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ''
            }
          />
          <TextField
            variant='secondary'
            label='New Password'
            required
            type='password'
            placeholder='********'
            name='new_password'
            value={formik.values.new_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.new_password && formik.errors.new_password
                ? formik.errors.new_password
                : ''
            }
          />
          <TextField
            variant='secondary'
            label='Confirm Password'
            required
            type='password'
            placeholder='********'
            name='confirm_password'
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirm_password && formik.errors.confirm_password
                ? formik.errors.confirm_password
                : ''
            }
          />
          <ButtonRow>
            <Button
              variant='outlined'
              label='Cancel'
              disabled={loading}
              onClick={handleClose}
            />
            <Button
              variant='contained'
              label='Save'
              type='submit'
              loading={loading}
              onClick={formik.handleSubmit}
            />
          </ButtonRow>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default UpdatePassword;

const Container = styled.div`
  width: 100%;
  max-width: 568px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .header {
    background-color: ${({ theme }) => theme.colors.greyBg};
    padding-block: 28px 22px;
    padding-inline: 45px;

    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  max-height: 85vh;
`;

const Content = styled.div`
  padding-block: 31px 48px;
  padding-inline: 45px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  overflow: auto;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 17px;
  margin-top: 16px;

  & button {
    height: 36px;
    min-width: 0px;
    padding-inline: 22px;
    font-size: 13px;
    font-weight: 600;
  }
`;
