import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { useFormik } from 'formik';
import { object, string, InferType } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useNavigate } from 'react-router-dom';

import DialogLayout from '../DialogLayout';
import TextField from '../../components/TextField/TextField';
import Button from '../../components/Button/Button';
import Textarea from '../../components/Textarea/Textarea';
import Autocomplete from '../../components/Autocomplete/Autocomplete';

import navIcons from '../../assets/navIcons';
import theme from '../../theme';
import assets from '../../assets';
import { ICategory, ICity, ILawyer, IUser } from '../../types/interfaces';
import { IUpdateUser } from '../../types/apiInterface';
import { getCities } from '../../http/services/paymentService';

const schema = object({
  full_name: string().required().label('Username'),
  email: string().email().required().label('Email'),
  address: string().min(3).required().label('Address'),
  mobile: string().min(3).required().label('Phone Number'),
  last_name: string().required(),
  gender: string().required(),
});

export interface IEditUserValues extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLawyer?: boolean;
  user?: IUser | ILawyer;
}

const EditProfile: FC<IProps> = ({ open, setOpen, isLawyer = false, user }) => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const { loading } = useSelector((state: RootState) => state.lawyers);
  const { loading: clientLoading } = useSelector(
    (state: RootState) => state.clients
  );
  const [selectedCategories, setSelectedCategories] = useState<ICategory[]>([]);
  const [error, setError] = useState('');

  const [cities, setCities] = useState<ICity[]>([]);
  const [openMenu, setOpenMeun] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeselectCategory = (option: ICategory) => {
    setSelectedCategories(
      selectedCategories.filter(c => c.name !== option.name)
    );
    setError('');
  };

  const formik = useFormik<IEditUserValues>({
    initialValues: {
      full_name: '',
      email: '',
      address: '',
      mobile: '',
      last_name: '',
      gender: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      if (isLawyer) {
        if (selectedCategories.length !== 3) {
          return setError('Please select 3 categories');
        }
        const apiData: IUpdateUser = {
          full_name: values.full_name,
          last_name: values.last_name,
          gender: values.gender,
          city: values.address,
        };
        dispatch.lawyers.handleUpdateLawyer({
          values: apiData,
          formik,
          setOpen,
          navigate,
          id: user!.id,
        });
        dispatch.lawyers.handleUpdateLawyerCategories({
          categories: selectedCategories.map(c => c.id),
          lawyerId: user!.id,
        });
      } else {
        const apiData: IUpdateUser = {
          full_name: values.full_name,
          last_name: values.last_name,
          gender: values.gender,
          city: values.address,
        };
        dispatch.clients.handleUpdateClient({
          id: user!.id,
          values: apiData,
          formik,
          navigate,
          setOpen,
        });
      }
    },
  });

  const handleFetchCities = async () => {
    try {
      const { data } = await getCities();
      setCities(data.data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    formik.setValues({
      full_name: user?.full_name || '',
      email: user?.email || '',
      address: user?.city || '',
      mobile: user?.mobile || '',
      last_name: user?.last_name || '1',
      gender: user?.gender || 'male',
    });
    if (isLawyer) {
      setSelectedCategories(user?.categories || []);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleFetchCities();
  }, []);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='header'>
          <p className='font-size-24 semi-bold title'>Edit Profile</p>
        </div>
        <Content>
          <TextField
            variant='secondary'
            label='Username'
            name='full_name'
            value={formik.values.full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type='text'
            placeholder='Username'
            leftIcon={
              <IConContainer>
                <img src={navIcons.client} alt='' />
              </IConContainer>
            }
            error={
              formik.touched.full_name && formik.errors.full_name
                ? formik.errors.full_name
                : ''
            }
          />
          <TextField
            variant='secondary'
            label='Email'
            type='email'
            name='email'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='Email'
            readOnly
            leftIcon={
              <IConContainer>
                <Icon
                  icon='fontisto:email'
                  style={{ fontSize: '20px' }}
                  color={theme.colors.primary}
                />
              </IConContainer>
            }
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
          />
          <TextField
            variant='secondary'
            label='Phone Number'
            type='tel'
            name='mobile'
            value={formik.values.mobile}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder='+92 308 1398566'
            readOnly
            leftIcon={
              <IConContainer>
                <Icon
                  icon='solar:phone-outline'
                  style={{ fontSize: '25px' }}
                  color={theme.colors.primary}
                />
              </IConContainer>
            }
            error={
              formik.touched.mobile && formik.errors.mobile
                ? formik.errors.mobile
                : ''
            }
          />
          <CityBox>
            <Textarea
              label='Address'
              name='address'
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={e => {
                formik.handleBlur(e);
              }}
              onFocus={() => setOpenMeun(true)}
              icon={
                <Icon
                  icon='bytesize:location'
                  style={{ fontSize: '20px' }}
                  color={theme.colors.primary}
                />
              }
              error={
                formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : ''
              }
            />
            {openMenu && (
              <div className='menu'>
                {cities.map(city => {
                  if (
                    city.name
                      .toLowerCase()
                      .includes(formik.values.address.toLowerCase())
                  ) {
                    return (
                      <p
                        className='font-size-16 city'
                        key={city.id}
                        style={{ padding: '8px 10px' }}
                        onClick={() => {
                          formik.setFieldValue('address', city.name);
                          setOpenMeun(false);
                        }}
                      >
                        {city.name}
                      </p>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            )}
          </CityBox>

          {isLawyer && (
            <>
              <Autocomplete
                selected={selectedCategories}
                setSelected={setSelectedCategories}
              />
              <ChipsContainer>
                {selectedCategories.map(category => (
                  <Chip key={category.id}>
                    <img src={assets.employee} alt='' />
                    <p className='font-size-13 text-blue'>{category.name}</p>
                    <Icon
                      icon='simple-line-icons:close'
                      className='close-icon'
                      role='button'
                      onClick={() => handleDeselectCategory(category)}
                    />
                  </Chip>
                ))}
              </ChipsContainer>
              {error && <p className='font-size-14 text-danger'>{error}</p>}
            </>
          )}
          <ButtonRow>
            <Button
              variant='outlined'
              label='Cancel'
              disabled={loading || clientLoading}
              onClick={handleClose}
            />
            <Button
              variant='contained'
              label='Save'
              type='submit'
              loading={loading || clientLoading}
              onClick={formik.handleSubmit}
            />
          </ButtonRow>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default EditProfile;

const Container = styled.div`
  width: 100%;
  max-width: 568px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .header {
    background-color: ${({ theme }) => theme.colors.greyBg};
    padding-block: 28px 22px;
    padding-inline: 45px;

    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  max-height: 85vh;
`;

const Content = styled.div`
  padding-block: 31px 48px;
  padding-inline: 45px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  overflow: auto;
`;

const IConContainer = styled.div`
  width: 25px;
  display: grid;
  place-items: center;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 17px;
  margin-top: 16px;

  & button {
    height: 36px;
    min-width: 0px;
    padding-inline: 22px;
    font-size: 13px;
    font-weight: 600;
  }
`;

const ChipsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  flex-wrap: wrap;
`;

const Chip = styled.div`
  padding: 14px 10px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;

  & .close-icon {
    position: absolute;
    right: -5px;
    top: -5px;
    font-size: 13px;
    color: ${({ theme }) => theme.colors.text.secondary};
    background: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    cursor: pointer;
  }
`;

const CityBox = styled.div`
  position: relative;

  & .menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    transform: translateY(calc(100% + 5px));
    overflow: auto;
    max-height: 300px;
    z-index: 100;

    &:empty {
      display: none;
    }
  }

  & .city {
    padding: 8px 10px;
    border-bottom: 1px solid #d1d1d1;
    cursor: pointer;
    &:last-of-type {
      border-bottom: none;
    }
  }
`;
