import { FC, useState } from 'react';

import styled from 'styled-components';

import assets from '../../assets';

const Checkbox: FC = () => {
  const [checked, setChecked] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };
  return (
    <Container>
      <div className='box'>
        <label htmlFor='checkbox'>
          <img
            src={checked ? assets.checkboxChecked : assets.checkbox}
            alt=''
          />
        </label>
      </div>
      <input
        type='checkbox'
        name=''
        id='checkbox'
        onChange={handleChange}
        checked={checked}
      />
    </Container>
  );
};

export default Checkbox;

const Container = styled.div`
  & label {
    line-height: 14px;
    height: 14px;
    width: 14px;
    cursor: pointer;
  }

  & input {
    display: none;
  }
`;
