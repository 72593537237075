import React, { useRef, useEffect, useState, useContext, FC } from 'react';

import styled from 'styled-components';
// import ClickAwayListener from 'react-click-away-listener';
import { AgoraContext } from '../../../contexts/AgoraContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import assets from '../../../assets';
// import { Icon } from '@iconify/react';
// import theme from '../../../theme';
import AC, { AgoraChat } from 'agora-chat';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface IProps {
  setMessages: React.Dispatch<React.SetStateAction<AgoraChat.MessagesType[]>>;
  messages: AgoraChat.MessagesType[];
  loadChats: () => void;
}

const ChatInput: FC<IProps> = ({ messages, setMessages, loadChats }) => {
  const [value, setValue] = useState('');

  const connection = useContext(AgoraContext);

  const { selected } = useSelector((state: RootState) => state.chat);
  // eslint-disable-next-line
  const { user } = useSelector((state: RootState) => state.auth);

  const ref = useRef<HTMLTextAreaElement>(null);

  const [openMenu, setOpenMenu] = useState(false);

  const handleSendMessage = async () => {
    try {
      if (value.trim() === '') {
        return null;
      }
      const msg = AC.message.create({
        to: selected!.agora_user_name,
        msg: value,
        chatType: 'singleChat',
        type: 'txt',
      });
      const newMessages = [...messages, msg];
      setMessages(prev => newMessages);
      await connection.send(msg);
      setValue('');
    } catch (err: any) {
      console.log(err.message);
    }
  };

  // eslint-disable-next-line
  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  // eslint-disable-next-line
  const closeMenu = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '0px';
      const scroll = ref.current.scrollHeight;
      ref.current.style.height = `${scroll}px`;
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <Container>
      <div className='input-container'>
        {/* <IconButton>
          <Icon icon='heroicons:face-smile' className='icon' />
        </IconButton>
        <div className='wrapper modal'>
          <IconButton onClick={toggleMenu}>
            <Icon icon='ant-design:paper-clip-outlined' className='icon' />
          </IconButton>
          {openMenu && (
            <ClickAwayListener onClickAway={closeMenu}>
              <FileAttachmentMenu>
                <div className='row space-between'>
                  <p className='font-size-14 text-blue semi-bold'>
                    Attach File
                  </p>
                  <div
                    className='close-btn clickable'
                    role='button'
                    onClick={closeMenu}
                  >
                    <Icon
                      icon='material-symbols:close'
                      color={theme.colors.text.primary}
                    />
                  </div>
                </div>
                <div className='menu'>
                  <div className='menu-item row'>
                    <img src={assets.file} alt='' />
                    <p className='font-size-13 text-blue semi-bold'>Document</p>
                  </div>
                  <div className='menu-item row'>
                    <img src={assets.video} alt='' />
                    <p className='font-size-13 text-blue semi-bold'>
                      Photo/Video
                    </p>
                  </div>
                </div>
              </FileAttachmentMenu>
            </ClickAwayListener>
          )}
        </div> */}
        <textarea
          ref={ref}
          placeholder='Write a message'
          value={value}
          onChange={e => setValue(e.target.value)}
        ></textarea>
      </div>
      <div className='send-btn clickable row' onClick={handleSendMessage}>
        <img src={assets.send} alt='' />
      </div>
    </Container>
  );
};

export default ChatInput;

const Container = styled.div`
  padding-inline: 28px;
  display: flex;
  align-items: center;
  gap: 10px;
  & .modal {
    position: relative;
    display: inline;
    max-width: 29px;
  }

  & .input-container {
    display: flex;
    align-items: center;
    min-height: 43px;
    border: 1px solid ${({ theme }) => theme.colors.primary + '66'};
    flex-grow: 1;
    flex-shrink: 0px;
    border-radius: 1;
    border-radius: 6px;
    gap: 10px;
    padding-inline: 14px;

    & textarea {
      flex-grow: 1;
      flex-shrink: 0px;
      font-size: 14px;
      height: 10px;
      outline: none;
      border: none;
      resize: none;
      padding-block: 10px;
      max-height: 150px;
    }
  }
`;
// eslint-disable-next-line
const IconButton = styled.div`
  height: 29px;
  width: 29px;
  border-radius: 6px;
  display: grid;
  place-items: center;
  cursor: pointer;

  & .icon {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 20px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary + '1a'};
    & .icon {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
// eslint-disable-next-line
const FileAttachmentMenu = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateY(calc(-100% - 5px));
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 343px;

  & .close-btn {
    background-color: ${({ theme }) => theme.colors.primary + '1a'};
    display: grid;
    place-items: center;
    height: 25px;
    width: 25px;
    border-radius: 4px;
  }

  & .menu {
    margin-top: 28px;
    background-color: ${({ theme }) => theme.colors.primary + '1a'};
    border-radius: 12px;
    padding: 8px 16px;

    & .menu-item {
      gap: 8px;
      height: 50px;
      cursor: pointer;
      & p {
        opacity: 0.6;
      }
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary + '1a'};
      &:last-of-type {
        border: none;
      }
    }
  }
`;
