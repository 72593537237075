import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Button from '../../components/Button/Button';
import HelpCategory from './components/HelpCategory';
import AddCategory from '../../Dialogs/AddCategory/AddCategory';

import assets from '../../assets';
import Loading from '../../components/Loading/Loading';

const HelpCenter = () => {
  const dispatch = useDispatch<Dispatch>();

  const [openModal, setOpenModal] = useState(false);

  const { helpCategories, loading } = useSelector(
    (state: RootState) => state.help
  );

  const handleOpenModal = () => {
    setOpenModal(true);
    dispatch.help.setSelectedCategory(null);
  };

  useEffect(() => {
    dispatch.help.handleGetAllCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className='row space-between'>
        <div className='row header'>
          <img src={assets.handShake} style={{ width: '32px' }} alt='' />
          <p className='font-size-24 title'>Help Center</p>
        </div>
        <Button
          label='+ Add Category'
          className='btn'
          onClick={handleOpenModal}
        />
      </div>
      {helpCategories.length === 0 && loading ? (
        <Loading />
      ) : (
        <Grid>
          {helpCategories.map(category => (
            <HelpCategory
              data={category}
              key={category.id}
              setOpenModal={setOpenModal}
            />
          ))}
        </Grid>
      )}

      {openModal && <AddCategory open={openModal} setOpen={setOpenModal} />}
    </Container>
  );
};

export default HelpCenter;

const Container = styled.div`
  padding-block: 29px 30px;

  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  & .btn {
    padding-inline: 34px;
  }
`;

const Grid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
`;
