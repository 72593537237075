import React from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import assets from '../../assets';
import navIcons from '../../assets/navIcons';
import { NavLink, Outlet } from 'react-router-dom';
import classNames from 'classnames';
// import SearchBar from '../../components/SearchBar/SearchBar';
import Notifications from './Notifications';
import ProfileMenu from './ProfileMenu';
import { ROUTES } from '../../constants';
import ConfirmationDialog from '../../Dialogs/ConfirmationDialog/ConfirmationDialog';
import Toast from '../../Dialogs/Toast/Toast';
import useNotifications from '../../hooks/useNotifications';

const navConfig = [
  {
    icon: navIcons.dashboard,
    activeIcon: navIcons.dashboardActive,
    label: 'Dashboard',
    path: ROUTES.DASHBOARD,
  },
  {
    icon: navIcons.client,
    activeIcon: navIcons.clientActive,
    label: 'Clients',
    path: ROUTES.CLIENTS,
  },
  {
    icon: navIcons.lawyer,
    activeIcon: navIcons.lawyerActive,
    label: 'Lawyers',
    path: ROUTES.LAWYERS,
  },
  {
    icon: navIcons.category,
    activeIcon: navIcons.categoryActive,
    label: 'Categories',
    path: ROUTES.CATEGORIES,
  },
  {
    icon: navIcons.help,
    activeIcon: navIcons.helpActive,
    label: 'Help Center',
    path: ROUTES.HELP_CENTER,
  },
  {
    icon: navIcons.chat,
    activeIcon: navIcons.chatActive,
    label: 'Message',
    path: ROUTES.MESSAGES,
  },
  {
    icon: navIcons.reported,
    activeIcon: navIcons.reportedActive,
    label: 'Reported Users',
    path: ROUTES.REPORTED_USERS,
  },
  {
    icon: navIcons.invoice,
    activeIcon: navIcons.invoiceActive,
    label: 'User Invoices',
    path: ROUTES.USER_INVOICES,
  },
  {
    icon: navIcons.settings,
    activeIcon: navIcons.settingsActive,
    label: 'Settings',
    path: ROUTES.SETTINTS,
  },
  {
    icon: navIcons.payment,
    activeIcon: navIcons.paymentActive,
    label: 'Lawyer Payment',
    path: ROUTES.PAYMENT,
  },
];

const DashboardLayout = () => {
  useNotifications();

  const dispatch = useDispatch<Dispatch>();

  const utils = useSelector((state: RootState) => state.utils);

  const handleLogoutClick = () => {
    dispatch.utils.openConfirmationModal({
      actionTitle: 'Logout',
      action: handleLogout,
      message: 'Are you sure you want to logout?',
    });
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const toggleSideBar = () => {
    dispatch.utils.setSideBarOpen(!utils.isSideBarOpen);
  };

  return (
    <Container>
      <Sidebar className={classNames(utils.isSideBarOpen ? '' : 'sm')}>
        <div className='logo'>
          <img
            src={utils.isSideBarOpen ? assets.fullLogo : assets.logoSm}
            alt=''
          />
        </div>
        <Menu className={classNames(utils.isSideBarOpen ? '' : 'side-bar-sm')}>
          {navConfig.map(item => (
            <NavLink
              key={item.label}
              className='anchor'
              children={navData => (
                <li
                  className={classNames(navData.isActive ? 'active' : '')}
                  role='button'
                >
                  <div
                    className='row'
                    style={{
                      width: '27px',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={navData.isActive ? item.activeIcon : item.icon}
                      alt=''
                      style={{
                        opacity: !utils.isSideBarOpen
                          ? 1
                          : navData.isActive
                          ? 1
                          : 0.38,
                      }}
                    />
                  </div>
                  <p className='font-size-16'>{item.label}</p>
                </li>
              )}
              to={item.path}
            />
          ))}
          <li role='button' onClick={handleLogoutClick}>
            <div
              className='row'
              style={{
                width: '27px',
                justifyContent: 'center',
              }}
            >
              <img
                src={navIcons.logout}
                alt=''
                style={{ opacity: !utils.isSideBarOpen ? 1 : 0.38 }}
              />
            </div>
            <p className='font-size-16'>Log Out</p>
          </li>
        </Menu>
      </Sidebar>
      <ContentContainer
        className={classNames(utils.isSideBarOpen ? '' : 'side-bar-sm')}
      >
        <TopBar
          className={classNames(utils.isSideBarOpen ? '' : 'side-bar-sm')}
        >
          <div className='wrapper'>
            <div className='row space-between'>
              <div
                className='row icon clickable'
                role='button'
                onClick={toggleSideBar}
              >
                <img src={assets.bars} alt='' />
              </div>
              <div className='row' style={{ gap: '20px' }}>
                {/* <SearchBar /> */}
                <Notifications />
                <ProfileMenu />
              </div>
            </div>
          </div>
        </TopBar>
        <Content>
          <Outlet />
        </Content>
      </ContentContainer>
      {utils.confirmationModal && (
        <ConfirmationDialog
          open={utils.confirmationModal}
          message={utils.confirmationMessage}
          actionLabel={utils.confirmationActionTitle}
          onAction={utils.confirmationAction}
        />
      )}
      {utils.successModal && <Toast />}
    </Container>
  );
};

export default DashboardLayout;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Sidebar = styled.div`
  min-width: 257px;
  width: 257px;
  overflow: hidden;
  min-height: 100vh;
  border-right: 1px solid ${({ theme }) => theme.colors.border};
  position: fixed;
  z-index: 2;
  top: 0px;

  padding-top: 32px;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease;

  &.sm {
    min-width: 115px;
    width: 115px;
    & .logo {
      padding-left: 30px;
    }
  }

  & .logo {
    padding-left: 34px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  margin-left: 257px;
  padding-inline: 21px 49px;
  transition: 0.4s ease;
  &.side-bar-sm {
    margin-left: 115px;
  }
`;

const Menu = styled.ul`
  flex-grow: 1;
  height: 0px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-top: 40px;
  gap: 20px;
  padding-bottom: 30px;
  padding-inline: 14px 31px;

  & li {
    padding: 15px 20px;
    border-radius: 7px;
    gap: 7px;
    height: 55px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.4s ease;

    & p {
      color: ${({ theme }) => theme.colors.primary};
      opacity: 0.38;
      white-space: nowrap;
    }
    &.active {
      background-color: ${({ theme }) => theme.colors.primary};
      & p {
        color: ${({ theme }) => theme.colors.white};
        opacity: 1;
      }
    }
  }

  &.side-bar-sm {
    padding-inline: 25px;
    & li {
      width: 62px;
      border-radius: 6px;
      gap: 0px;
      & p {
        white-space: nowrap;
        display: none;
      }
    }
  }
`;

const TopBar = styled.header`
  background-color: ${({ theme }) => theme.colors.white};
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-inline: calc(257px + 21px) 49px;
  transition: all 0.4s ease;
  z-index: 1;
  & .wrapper {
    padding-block: 29px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }

  &.side-bar-sm {
    padding-inline: calc(115px + 21px) 49px;
  }
`;

const Content = styled.main`
  min-height: 100vh;
  padding-top: calc(104px + 0px);
  display: flex;
  flex-direction: column;
`;
