import React, { FC, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

import styled from 'styled-components';
import theme from '../../theme';
import { NotificationIcon } from '../../types/enums';

type NotificationIconType =
  | 'raphael:warning'
  | 'material-symbols:event-upcoming-outline-sharp'
  | 'mingcute:user-3-fill';

interface IProps {
  icon: NotificationIconType;
}

const Notification: FC<IProps> = props => {
  const { icon } = props;
  const [color, setColor] = useState('#000000');

  const handleSetIconColor = () => {
    if (icon === NotificationIcon.WARNING) {
      setColor(theme.colors.text.danger);
    } else if (icon === NotificationIcon.EVENT) {
      setColor(theme.colors.blue);
    } else {
      setColor(theme.colors.text.primary);
    }
  };

  useEffect(() => {
    handleSetIconColor();
    // eslint-disable-next-line
  }, []);

  return (
    <Container className='row'>
      <Icon icon={icon} style={{ fontSize: '28px', color: color }} />
      <p className='font-size-16 text-blue semi-bold'>System Alerts</p>
      <p className='font-size-16 medium text-secondary'>
        Receive real-time alerts for system issues, updates, and important
        announcements.
      </p>
      <p className='font-size-16 text-blue semi-bold time'>9:36 AM</p>
    </Container>
  );
};

export default Notification;

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  padding: 32px 34px;
  margin-top: 20px;
  border-radius: 6px;

  gap: 35px;

  & .time {
    margin-left: auto;
  }
`;
