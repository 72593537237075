import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useNavigate } from 'react-router-dom';

import navIcons from '../../assets/navIcons';
import Pagination from '../../components/Pagination/Pagination';
import { getFormatedDate } from '../../utils';
import { ClipLoader } from 'react-spinners';
import theme from '../../theme';
import Loading from '../../components/Loading/Loading';
import Select from '../../components/Select/Select';
import SearchBar from '../../components/SearchBar/SearchBar';
import Button from '../../components/Button/Button';
import { IReports } from '../../types/interfaces';
import { ROUTES } from '../../constants';
import classNames from 'classnames';
import Switch from '../../components/Switch/Switch';
import { updateLawyerDocs } from '../../http/services/lawyerService';

interface IProps {
  report: IReports;
  handleStartChat: (user: IReports) => void;
}

const ReportedUser: FC<IProps> = ({ report, handleStartChat }) => {
  const [active, setActive] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActive(e.currentTarget.checked);
    handleUpdateStatus(e.currentTarget.checked);
  };

  const handleUpdateStatus = async (s: boolean) => {
    try {
      await updateLawyerDocs(report.reported_to, s);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (report) {
      setActive(report.reported_to_blocked);
    }
  }, [report]);

  return (
    <tr key={report.id}>
      <td>{report.reported_to_name}</td>
      <td>
        {report.full_name} {report?.last_name}
      </td>
      <td>{report.report}</td>
      <td>{getFormatedDate(report.created_at)}</td>
      <td>
        <div className='row' style={{ gap: '12px' }}>
          <div
            className='row clickable'
            role='button'
            onClick={() => handleStartChat(report)}
          >
            <img src={navIcons.chat} style={{ width: '15.75px' }} alt='' />
          </div>
          <Switch checked={active} onChange={handleChange} />
        </div>
      </td>
    </tr>
  );
};

const ReportedUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { reports, totalPages, loading } = useSelector(
    (state: RootState) => state.reportedUsers
  );

  const { isSideBarOpen } = useSelector((state: RootState) => state.utils);

  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [filter, setFilter] = useState('');
  const [search, setSearch] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    setPage(1);
  };

  const handleFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
    setSearch('');
  };

  const handleSearchReportedUsers = () => {
    setPage(1);
    dispatch.reportedUsers.handleGetReportedUsers({
      page,
      limit,
      key: filter,
      value: search,
    });
  };

  const handleStartChat = (user: IReports) => {
    dispatch.chat.setSelected({
      id: user.reported_to,
      agora_user_name: user.t_agora_user_name,
      full_name: user.full_name,
      image_url: user.t_image_url,
      about: '',
      address: '',
      categories: [],
      city: '',
      created_at: '',
      email: '',
      gender: '',
      mobile: '',
      updated_at: '',
      is_doc_verified: false,
      isAnonymous: false,
      last_name: '',
      no_of_clients: 0,
      role: '',
      step: 0,
      year_of_experince: '',
    });
    navigate(ROUTES.MESSAGES);
  };

  useEffect(() => {
    dispatch.reportedUsers.handleGetReportedUsers({
      page,
      limit,
    });
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    if (!filter) {
      handleSearchReportedUsers();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Container>
      <div className='header row'>
        <img src={navIcons.reported} style={{ height: '27px' }} alt='' />
        <p className='font-size-24 text-blue  title'>Reported Users</p>
      </div>
      <div
        className='row'
        style={{ gap: '12px', height: '50px', marginTop: '30px' }}
      >
        <Select
          value={filter}
          onChange={handleFilter}
          containerClassName='select-container'
          search={true}
          options={[
            { label: filter ? 'Remove filter' : 'Select a filter', value: '' },
            { label: 'User Name', value: 'reported_to' },
            { label: 'Reported By', value: 'full_name' },
          ]}
        />
        {filter && (
          <>
            <SearchBar value={search} onChange={handleSearch} />
            <Button
              label='Search'
              size='small'
              style={{ height: '44px', minWidth: '120px' }}
              onClick={handleSearchReportedUsers}
              loading={loading}
            />
          </>
        )}
      </div>
      {reports.length === 0 && loading ? (
        <Loading />
      ) : (
        <>
          <TableContainer className={classNames(isSideBarOpen ? '' : 'large')}>
            <Table>
              <thead>
                <tr>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      User Name
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Reported By
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Reason
                    </p>
                  </th>

                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Reported Date
                    </p>
                  </th>
                  <th>
                    <p className='font-size-14 text-primary semi-bold'>
                      Action
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {reports?.map(report => (
                  <ReportedUser
                    key={report.id}
                    report={report}
                    handleStartChat={handleStartChat}
                  />
                ))}
              </tbody>
            </Table>
            {reports.length > 0 && loading && (
              <Loader>
                <ClipLoader size={50} color={theme.colors.white} />
              </Loader>
            )}
          </TableContainer>
          <div style={{ marginTop: '53px' }}>
            <Pagination
              limit={limit}
              setPageNumber={setPage}
              pageNumber={page}
              pageCount={totalPages}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default ReportedUsers;

const Container = styled.div`
  padding-block: 29px 50px;
  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 327px);
  margin-top: 19px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  position: relative;

  transition: 0s.4s ease;

  &.large {
    max-width: calc(100vw - 180px);
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  min-width: 700px;

  & thead {
    & tr {
      & th {
        background-color: ${({ theme }) => theme.colors.greyBg};
        text-align: left;
        padding-block: 15px 10px;
        padding-inline: 25px;

        & input {
          margin-top: 2px;
        }
      }
    }
  }

  & tbody {
    & tr {
      & td {
        height: 54px;
        padding-inline: 25px;
        text-align: left;
        color: ${({ theme }) => theme.colors.text.secondary};
        border-bottom: 1px solid ${({ theme }) => theme.colors.borderLight};
        font-size: 13px;
        max-width: 250px;
        padding-block: 5px;
        word-wrap: break-word;
      }

      &:hover {
        & td {
          color: ${({ theme }) => theme.colors.text.primary};
        }
      }

      &:last-of-type {
        & td {
          border: none;
        }
      }
    }
  }
`;

const Loader = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;
