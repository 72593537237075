import React, { FC, useEffect } from 'react';

import styled from 'styled-components';

import DialogLayout from '../DialogLayout';
import assets from '../../assets';

interface IProps {
  open: boolean;
  close: () => void;
  image: string;
}

const ImageViewDialog: FC<IProps> = ({ open, close, image }) => {
  const handleClose = () => {
    close();
  };

  useEffect(() => {
    console.log(image);
  }, [image]);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='row close-container'>
          <div className='close clickable' role='button' onClick={handleClose}>
            <img src={assets.close} alt='' />
          </div>
        </div>
        <Content>
          <div className='image-container'>
            <img src={image} alt='' />
            <a download href={image} rel='noopenner noreferror'>
              <div className='download clickable'>
                <img src={assets.download} alt='' />
              </div>
            </a>
          </div>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default ImageViewDialog;

const Container = styled.div`
  width: 100%;
  padding-inline: 47px;

  & .close-container {
    justify-content: flex-end;
  }
`;

const Content = styled.div`
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  & .image-container {
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    & img {
      height: 60vh;
      object-fit: contain;
    }

    & .download {
      position: absolute;
      top: 13px;
      right: 12px;
      width: 43px;
      height: 43px;
      display: grid;
      place-items: center;
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 12px;

      & img {
        width: 33px;
        height: 33px;
      }
    }
  }
`;
