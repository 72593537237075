export enum NotificationIcon {
  'WARNING' = 'raphael:warning',
  'EVENT' = 'material-symbols:event-upcoming-outline-sharp',
  'USER' = 'mingcute:user-3-fill',
}

export enum Token {
  'TOKEN' = 'legel-avenue-auth-token-v1',
  'API_KEY' = 'X-API-KEY',
}
