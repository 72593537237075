import api from '..';
import {
  IApiResponse,
  IPaymentRequestsResponse,
} from '../../types/apiInterface';
import { IPaymentMethod } from '../../types/interfaces';

export const getPaymentRequests = (data: {
  page: number;
  limit: number;
  status: 0 | 1;
}) =>
  api.get<IApiResponse<IPaymentRequestsResponse>>(
    `/v1/withdraw-transaction?page=${data.page}&limit=${data.limit}&status=${data.status}`
  );

export const updatePaymentRequests = (id: string, data: FormData) =>
  api.patch(`/v1/withdraw-transaction/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getPaymentMethods = () =>
  api.get<IApiResponse<IPaymentMethod[]>>('/v1/payment-method');

export const updatePaymentMethod = (id: string, data: any) =>
  api.patch(`/v1/payment-method/${id}`, data);
