import axios from 'axios';
import { baseURL } from '../app.config';
import { Token } from '../types/enums';

const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(Token.TOKEN);
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error?.response &&
      error?.response?.status === 401 &&
      window.location.pathname !== '/login'
    ) {
      localStorage.removeItem(Token.TOKEN);
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

export default api;
