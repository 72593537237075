import api from '..';
import { apiKey } from '../../app.config';
import {
  IApiResponse,
  IPaymentRequestsResponse,
} from '../../types/apiInterface';
import { Token } from '../../types/enums';
import { ICity, IEarnings, IPaymentMethod } from '../../types/interfaces';

export const getPaymentRequests = (data: {
  page: number;
  limit: number;
  status: 0 | 1;
  full_name?: string;
}) =>
  api.get<IApiResponse<IPaymentRequestsResponse>>(
    `/v1/withdraw-transaction?page=${data.page}&limit=${data.limit}&status=${
      data.status
    }${data.full_name ? `&full_name=${data.full_name}` : ''}`
  );

export const updatePaymentRequests = (id: string, data: FormData) =>
  api.patch(`/v1/withdraw-transaction/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getPaymentMethods = () =>
  api.get<IApiResponse<IPaymentMethod[]>>('/v1/payment-method');

export const updatePaymentMethod = (id: string, data: any) =>
  api.patch(`/v1/payment-method/${id}`, data);

export const getEarnings = (data: { page: number; limit: number }) =>
  api.get<IApiResponse<IEarnings>>(
    `/v1/withdraw-transaction/earnings?page=${data.page}&limit=${data.limit}`
  );

export const getCities = () =>
  api.get<IApiResponse<ICity[]>>('/v1/cities', {
    headers: {
      [Token.API_KEY]: apiKey,
    },
  });
