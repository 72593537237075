import React, { useEffect, useContext } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { AgoraContext } from '../../contexts/AgoraContext';

import navIcons from '../../assets/navIcons';
import MyChats from './components/MyChats';
import Chat from './components/Chat';

const Messages = () => {
  const conn = useContext(AgoraContext);

  const dispatch = useDispatch<Dispatch>();

  const { chatUsers } = useSelector((state: RootState) => state.chat);

  const handleGetChats = async () => {
    try {
      const res = await conn.getCon();

      console.log(res);

      const conversations = await conn.getServerConversations({
        pageSize: 50,
        cursor: '',
      });
      console.log(conversations.data);
      if (conversations.data?.conversations.length) {
        dispatch.chat.setConversations(conversations.data.conversations);
      }

      const ids = conversations.data?.conversations.map(c => c.conversationId);
      if (ids?.length) {
        const data = await conn.fetchUserInfoById(ids);
        // @ts-ignore
        dispatch.chat.setUsers(data.data || null);
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    conn.addEventHandler('connection&message', {
      onConnected: () => {
        console.log('Connected');
        handleGetChats();
      },
      onDisconnected: () => {
        console.log('Disconnected');
      },
      onError: error => {
        console.log('on error', error);
      },
    });
    // eslint-disable-next-line
  }, [conn]);

  useEffect(() => {
    if (conn.isOpened()) {
      handleGetChats();
    }
    // eslint-disable-next-line
  }, [conn]);

  useEffect(() => {
    if (chatUsers.length === 0) {
      dispatch.chat.HandleGetChatUsers();
    }
    // eslint-disable-next-line
  }, [chatUsers]);

  return (
    <Container>
      <div className='header row'>
        <img src={navIcons.chat} style={{ height: '25px' }} alt='' />
        <p className='font-size-24  title'>Message</p>
      </div>
      <ChatContainer>
        <div className='wrapper row'>
          <MyChats />
          <Chat loadChats={handleGetChats} />
        </div>
      </ChatContainer>
    </Container>
  );
};

export default Messages;

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-block: 29px 50px;
  & .header {
    gap: 20px;
    & .title {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const ChatContainer = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.greyBg};
  margin-top: 18px;
  border-radius: 6px;
  padding: 17px;
  display: flex;
  flex-direction: column;

  & .wrapper {
    flex-grow: 1;
    gap: 2px;
    align-items: stretch;
  }
`;
