import React, { FC } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

import Button from '../../../components/Button/Button';

import assets from '../../../assets';
import theme from '../../../theme';
import { ROUTES } from '../../../constants';
import { IHelpCategory } from '../../../types/interfaces';

interface IProps {
  data: IHelpCategory;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const HelpCategory: FC<IProps> = ({ data, setOpenModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const handleClick = () => {
    navigate(ROUTES.HELP_CENTER_SUB_CATEGORIES.replace(':id', data.id), {
      state: data,
    });
  };

  const handleDelete = () => {
    dispatch.utils.openConfirmationModal({
      actionTitle: 'Delete',
      message: 'Are you sure you want to delete this help category?',
      action: () => dispatch.help.handleDeleteHelpCategory(data.id),
    });
  };

  const handleEdit = () => {
    dispatch.help.setSelectedCategory(data);
    setOpenModal(true);
  };

  return (
    <Container>
      <div className='row space-between'>
        <div style={{ maxWidth: '80%' }}>
          <p className='font-size-16 semi-bold title'>{data.title}</p>
        </div>
        <div className='row actions'>
          <div className='row clickable' onClick={handleDelete}>
            <img src={assets.bin} style={{ width: '23px' }} alt='' />
          </div>
          <div className='row clickable' onClick={handleEdit}>
            <Icon
              icon='mdi:file-edit-outline'
              style={{ fontSize: 28 }}
              color={theme.colors.primary}
            />
          </div>
        </div>
      </div>
      <p className='font-size-13 text-secondary semi-bold'>
        {data.description}
      </p>
      <Button label='View Details' className='btn' onClick={handleClick} />
    </Container>
  );
};

export default HelpCategory;

const Container = styled.div`
  padding: 20px 28px 34px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;

  p {
    word-wrap: break-word !important;
  }

  & .title {
    word-wrap: break-word;
    color: ${({ theme }) => theme.colors.primary};
  }

  & .actions {
    gap: 20px;
  }

  & p.font-size-13 {
    margin-block: 23px 20px;
    letter-spacing: 0.065px;
    line-height: 22px;
  }

  & .btn {
    height: 36px;
    padding-inline: 22px;
    min-width: unset;
    font-size: 13px;
  }
`;
