import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import TextField from '../../../components/TextField/TextField';
import Switch from '../../../components/Switch/Switch';

import assets from '../../../assets';
import { ILawyer, ILawyerDocs } from '../../../types/interfaces';
import {
  getLawyerDocs,
  updateLawyerDocs,
} from '../../../http/services/lawyerService';
import axios from 'axios';
import Loading from '../../../components/Loading/Loading';
import { getUserById } from '../../../http/services/authService';
import Image from '../../../components/Image/Image';

const LicenseAndCNIC = () => {
  const lawyer: ILawyer = useLocation().state;

  const [cnic, setCnic] = useState<ILawyerDocs[]>([]);
  const [license, setLicense] = useState<ILawyerDocs[]>([]);
  const [is_doc_verified, setIs_doc_verified] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { checked } = e.currentTarget;
      setIs_doc_verified(checked);
      await updateLawyerDocs(lawyer.id, checked);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data.message);
      }
    }
  };

  const handleGetLawyerDocs = async () => {
    try {
      setLoading(true);
      const { data } = await getLawyerDocs(lawyer.id);
      setCnic(data.data.filter(doc => doc.doc_type === 'cnic'));
      setLicense(data.data.filter(doc => doc.doc_type === 'license'));
      const { data: res } = await getUserById(lawyer.id);
      setIs_doc_verified(res.data.is_doc_verified);
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (lawyer) {
      handleGetLawyerDocs();
      setIs_doc_verified(lawyer.is_doc_verified);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      {loading ? (
        <Loading />
      ) : (
        <FlexContainer className='row'>
          <div className='license'>
            <p className='font-size-18 text-blue bold'>License Photo</p>
            <div className='image-block'>
              {license.map(lic => (
                <div className='image-container' key={lic.id}>
                  <Image url={lic.url} />
                </div>
              ))}
            </div>
            <TextField
              variant='secondary'
              value={license.length ? license[0].doc_number : 'Not available'}
              readOnly
              label='License Number'
              leftIcon={<img src={assets.card} alt='' />}
              containerClassName='input'
            />
            <Verification>
              <div className='row space-between '>
                <p className='font-size-18 text-blue medium'>
                  Enable/disable verification of lawyers' CNIC and Licence
                  number.
                </p>
                <div className='switch-container'>
                  <Switch checked={is_doc_verified} onChange={handleChange} />
                </div>
              </div>
              {is_doc_verified && (
                <p className='font-size-16 text-success'>Verified</p>
              )}
            </Verification>
          </div>
          <div className='cnic'>
            <p className='font-size-18 text-blue bold'>CNIC Photo</p>
            <div className='image-block'>
              {cnic.map(c => (
                <div className='image-container' key={c.id}>
                  <Image url={c.url} />
                </div>
              ))}
            </div>
            {/* <TextField
              variant='secondary'
              value={cnic.length ? cnic[0].doc_number : 'Not-available'}
              readOnly
              label='CNIC Number'
              leftIcon={<img src={assets.card} alt='' />}
              containerClassName='input'
            /> */}
          </div>
        </FlexContainer>
      )}
    </Container>
  );
};

export default LicenseAndCNIC;

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;
  padding: 39px 40px;
`;

const FlexContainer = styled.div`
  align-items: flex-start;
  gap: 80px;
  & .license,
  & .cnic {
    flex: 1;
    flex-shrink: 0;

    & .image-block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 7px;
      margin-block: 12px 8px;

      & .image-container {
        width: 100%;
        aspect-ratio: 15/9;
        overflow: hidden;
        border-radius: 6px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  & label {
    & p {
      color: ${({ theme }) => theme.colors.primary}!important;
      line-height: 40px;
    }
  }

  & .input {
    border-color: ${({ theme }) => theme.colors.borderLight};
  }
`;

const Verification = styled.div`
  margin-top: 31px;

  & .row {
    gap: 30px;
  }

  & .switch-container {
    min-width: 40px;
  }

  & .text-success {
    margin-top: 16px;
  }
`;
