import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { AgoraContext } from '../../../contexts/AgoraContext';

import Avatar from '../../../components/Avatar/Avatar';
import ChatInput from './ChatInput';
import Message from './Message';
import { IAgoraUserInfoBlock, ILastMessage } from '../../../types/interfaces';
import assets from '../../../assets';
import { AgoraChat } from 'agora-chat';

interface IProps {
  loadChats: () => void;
}

const Chat: FC<IProps> = ({ loadChats }) => {
  const AC = useContext(AgoraContext);
  const scroller = useRef<HTMLDivElement>(null);

  const { selectedChat, selected } = useSelector(
    (state: RootState) => state.chat
  );

  // eslint-disable-next-line
  const [user, setUser] = useState<IAgoraUserInfoBlock | null>(null);

  const [messages, setMessages] = useState<AgoraChat.MessagesType[]>([]);

  const handleGetChatHistory = (cId: string) => {
    AC.getHistoryMessages({
      targetId: cId,
      chatType: 'singleChat',
      pageSize: 50,
    })
      .then(data => {
        setMessages(data.messages?.reverse());
      })
      .catch(err => console.log('err', err.message));
  };

  useEffect(() => {
    if (selectedChat) {
      AC.fetchUserInfoById(selectedChat.conversationId).then(user =>
        setUser(user.data![selectedChat.conversationId] as IAgoraUserInfoBlock)
      );
    }
    // eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    if (selected) {
      handleGetChatHistory(selected.agora_user_name);
    }
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    AC.addEventHandler('connection&message', {
      onError: error => {
        console.log('on error', error);
      },
      onTextMessage: msg => {
        alert('message recieved');
        console.log('new messag', msg.from, msg.chatType, msg.msg);
      },
      onReceivedMessage: msg => {
        console.log('message came from ', msg.to);
        // handleGetChatHistory(msg.to);
      },
    });
    // eslint-disable-next-line
  }, [AC]);
  useEffect(() => {
    if (scroller.current) {
      scroller.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <Container>
      <Header>
        <div className='wrapper'>
          <div className='row'>
            <Avatar src={selected?.image_url || assets.preview} alt='' />
            <p className='font-size-16 text-primary semi-bold'>
              {selected?.full_name} {selected?.last_name}
            </p>
          </div>
        </div>
      </Header>
      <Messages>
        {messages.map(message => (
          <Message
            key={message.id}
            msg={(message as ILastMessage).msg}
            time={(message as ILastMessage).time}
          />
        ))}
        <div ref={scroller}></div>
      </Messages>
      <ChatInput
        loadChats={loadChats}
        setMessages={setMessages}
        messages={messages}
      />
    </Container>
  );
};

export default Chat;

const Container = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  padding-block: 0px 39px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled.div`
  & .wrapper {
    padding-inline: 28px;
    & .row {
      gap: 20px;
      padding-block: 5px 16px;
    }
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyBg};
  }
`;

const Messages = styled.div`
  flex-grow: 1;
  padding-inline: 28px;
  height: 0px;
  overflow: auto;
  padding-block: 31px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
