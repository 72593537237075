import React, { FC, InputHTMLAttributes } from 'react';

import styled from 'styled-components';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {}

const Switch: FC<IProps> = props => {
  const { checked = false, onChange, ...rest } = props;

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };
  return (
    <SwitchButton
      type='checkbox'
      checked={checked}
      onChange={onChange}
      onClick={handleClick}
      {...rest}
    />
  );
};

export default Switch;

const SwitchButton = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 42px;
  height: 20px;
  border: 1.5px solid ${({ theme }) => theme.colors.text.secondary};
  border-radius: 20px;
  position: relative;
  transition: 0.4s ease;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.text.secondary};
    top: 1.5px;
    left: 1.5px;
    transition: 0.4s ease;
  }

  &:checked::before {
    left: 23.5px;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;
