import React, { FC } from 'react';

import ReactPaginate from 'react-paginate';
import { Icon } from '@iconify/react';
import styled from 'styled-components';
import theme from '../../theme';

interface IProps {
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageCount: number;
  limit: number;
}

const Pagination: FC<IProps> = props => {
  const { pageNumber, setPageNumber, pageCount } = props;
  return (
    <Container>
      <p className='font-size-16 text-primary semi-bold'>
        {/* Showing {(pageNumber - 1) * limit + 1} to {pageNumber * limit} of{' '}
        {pageCount * limit} entries */}
        Showing page {pageNumber} of {pageCount} pages
      </p>
      <ReactPaginate
        containerClassName='pagination-container '
        breakLabel='...'
        forcePage={pageNumber - 1}
        onPageChange={event => {
          setPageNumber(event.selected + 1);
        }}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        activeClassName='active'
        nextLabel={
          <Icon
            icon='charm:chevron-right'
            color={theme.colors.text.secondary}
            style={{ fontSize: '18px' }}
          />
        }
        previousLabel={
          <Icon
            icon='charm:chevron-left'
            color={theme.colors.text.secondary}
            style={{ fontSize: '18px' }}
          />
        }
        previousClassName='prev'
        nextClassName='next'
        renderOnZeroPageCount={null}
      />
    </Container>
  );
};

export default Pagination;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & .pagination-container {
    list-style-type: none;
    display: flex;
    gap: 8px;
    & li {
      height: 40px;
      width: 46px;

      color: ${({ theme }) => theme.colors.text.secondary};
      cursor: pointer;
      & a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      border-radius: 6px;
    }

    & .active {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
    }

    & .prev,
    & .next {
      background-color: transparent;
      cursor: pointer;
    }
  }
`;
