import React, { FC } from 'react';

import styled from 'styled-components';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone.js';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
  msg: string;
  time: number;
}

const Message: FC<IProps> = ({ msg, time }) => {
  return (
    <Container>
      <div className='wrapper'>
        <div className='message'>
          <p className='font-size-13 medium'>{msg}</p>
        </div>
        <div className='row'>
          <p className='font-size-12 text-secondary'>
            {dayjs.utc(time).local().format('hh:mm A')}
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Message;

const Container = styled.div`
  & .wrapper {
    margin-left: auto;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end !important;
    gap: 13px;
    & .message {
      background-color: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
      padding: 12px 16px;
      border-radius: 6px;
      & p {
        line-height: 18px;
      }
    }
  }
`;
