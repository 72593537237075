import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import ClickAwayListener from 'react-click-away-listener';

import SearchBar from '../SearchBar/SearchBar';
import { ICategory } from '../../types/interfaces';
import navIcons from '../../assets/navIcons';

interface IProps {
  selected: ICategory[];
  setSelected: React.Dispatch<React.SetStateAction<ICategory[]>>;
}

const Autocomplete: FC<IProps> = ({ selected, setSelected }) => {
  const dispatch = useDispatch<Dispatch>();

  const { categories } = useSelector((state: RootState) => state.categories);

  const [options, setOptions] = useState<ICategory[]>([]);
  const [search, setSearch] = useState('');
  const [openMenu, setOpenMenu] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
    setSearch('');
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
    setSearch('');
  };

  const handleSelect = (option: ICategory) => {
    setSelected(prev => [...prev, option]);
    setSearch('');
  };

  useEffect(() => {
    if (!categories.length) {
      dispatch.categories.getAllCategories();
    }
    setOptions(categories);
    // eslint-disable-next-line
  }, [categories]);

  return (
    <Container>
      <Selector>
        <p className='font-size-16 text-secondary'>Lawyer Expertise</p>
        <div className='selector row' role='button' onClick={toggleMenu}>
          <img src={navIcons.category} alt='' />
          <p className='font-size-14 text-blue'>Select category</p>
        </div>
      </Selector>
      {openMenu && (
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Menu>
            <div className='top-header'>
              <SearchBar
                containerClassName='search'
                value={search}
                onChange={handleChange}
              />
            </div>
            <div className='menu'>
              {options
                .filter(op => op.isActive === true)
                .map(option => {
                  if (
                    option.name.toLowerCase().includes(search.toLowerCase()) &&
                    !selected.find(s => s.name === option.name)
                  ) {
                    return (
                      <div
                        key={option.id}
                        className='menu-item'
                        onClick={() => handleSelect(option)}
                      >
                        <p className='font-size-13 text-secondary semi-bold'>
                          {option.name}
                        </p>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
            </div>
          </Menu>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default Autocomplete;

const Container = styled.div``;

const Menu = styled.div`
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding-block: 30px;
  margin-top: 10px;

  & .top-header {
    padding-inline: 27px;
    & .search {
      width: 100%;
    }
  }

  & .menu {
    padding-inline: 27px;
    margin-top: 18px;

    & .menu-item {
      margin-bottom: 6px;
      cursor: pointer;
      &:last-of-type {
        margin: 0px;
      }
      &:hover {
        p {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

const Selector = styled.div`
  & .selector {
    height: 51px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 6px;
    padding-inline: 17px;
    margin-top: 16px;
    gap: 10px;
    cursor: pointer;
  }
`;
