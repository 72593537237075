import api from '..';
import {
  IApiResponse,
  ILawyerTimingRes,
  ILawyersResponse,
  IUpdateUser,
} from '../../types/apiInterface';
import {
  IAppointement,
  ILawyerDocs,
  ILawyerTimingDays,
} from '../../types/interfaces';

export const getAllLawyers = (
  page: number,
  limit: number,
  key?: string,
  value?: string,
  isVerified?: boolean
) =>
  api.get<IApiResponse<ILawyersResponse>>(
    `/v1/lawyer?page=${page}&limit=${limit}${
      key && value ? `&key=${key}&value=${encodeURIComponent(value)}` : ''
    }&isMobile=false${
      isVerified !== undefined ? `&isVerified=${isVerified}` : ''
    }`
  );

export const getLawyerTimings = (
  lawyerId: string,
  day: string,
  date?: string,
  isAvailable?: boolean
) =>
  api.get<IApiResponse<ILawyerTimingRes>>(
    `/v1/lawyer/${lawyerId}/timings/detail/${day}/${date}?isAvailable=${
      isAvailable || false
    }`
  );

export const getLawyerDocs = (lawyerId: string) =>
  api.get<IApiResponse<ILawyerDocs[]>>(`/v1/lawyer/${lawyerId}/docs`);

export const updateLawyer = (lawyerId: string, data: IUpdateUser) =>
  api.patch(`/v1/user?id=${lawyerId}`, data);

export const blockUnblockLawyer = (lawyerId: string, is_blocked: boolean) =>
  api.patch(`/v1/user/${lawyerId}/block`, { is_blocked });

export const getLawyerAppointments = (
  lawyer_id: string,
  page: number,
  limit: number,
  time: string,
  isPrevious: boolean
  // date: string
) =>
  api.get<
    IApiResponse<{
      appointments: IAppointement[];
      totalPages: number;
      currentPage: number;
    }>
  >(
    `/v1/appointment/lawyer?isPrevious=${isPrevious}&lawyerId=${lawyer_id}&time=${time}&page=${page}&limit=${limit}`
  );

export const updateLawyerCategory = (categories: string[], lawyerId: string) =>
  api.patch(`/v1/lawyer/${lawyerId}/category`, { categories });

export const updateLawyerDocs = (lawyerId: string, is_doc_verified: boolean) =>
  api.patch(`/v1/lawyer/${lawyerId}/docs`, { is_doc_verified });

export const getLawyerBusinessDays = (lawyerId: string, date: string) =>
  api.get<IApiResponse<{ timingsDetail: Array<ILawyerTimingDays> }>>(
    `/v1/lawyer/${lawyerId}/timings/detail?date=${date}`
  );

export const updatePlatformFee = (id: string, data: any) =>
  api.patch(`/v1/user?id=${id}`, data);

export const updatePlatformFeeForAll = (value: number) =>
  api.patch('/v1/lawyer/platform-fee', {
    platform_fee_percentage: value,
  });
