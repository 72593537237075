import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *,*::before,*::after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    body{
        overflow-x: hidden;
    }

    .row{
        display: flex;
        align-items: center;
    }

    .space-between{
        justify-content: space-between;
    }

    .justify-center{
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }

    .clickable{
        cursor: pointer;
    }

    .text-primary{
        color: ${({ theme }) => theme.colors.text.primary}
    }
    .text-secondary{
        color: ${({ theme }) => theme.colors.text.secondary};
    }

    .text-success{
        color: ${({ theme }) => theme.colors.text.success};
    }

    .text-danger{
        color: ${({ theme }) => theme.colors.text.danger};
    }

    .text-blue{
        color: ${({ theme }) => theme.colors.primary};
    }

    .regular{
        font-weight: 400!important;
    }

    .medium{
        font-weight: 500!important;
    }

    .semi-bold{
        font-weight: 600!important;
    }

    .bold{
        font-weight: 700!important;
    }

    .w-100{
        width: 100%;
    }

    .font-size-10{
        font-size: 10px;
        font-weight: 400;
        line-height: normal;
    }

    .font-size-12{
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
    }

    .font-size-13{
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
    }

    .font-size-14{
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
    }

    .font-size-16{
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
    }
    .font-size-18{
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
    }

    .font-size-22{
        font-size: 22px;
        font-weight: 600;
        line-height: normal;
    }
    .font-size-24{
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
    }

    .font-size-25{
        font-size: 25px;
        font-weight: 500;
        line-height: normal;
    }

    .anchor{
        text-decoration: none;
        text-transform: none;
        color: inherit;
    }

`;
