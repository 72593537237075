import React, { FC, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import assets from '../../../assets';
import Switch from '../../../components/Switch/Switch';
import { IUserInvoiceTr } from '../../../types/interfaces';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../store';

import dayjs from 'dayjs';
import { getFormatedHours } from '../../../utils';
import axios from 'axios';
import { updateAppointMentStatus } from '../../../http/services/appointmentService';
import Image from '../../../components/Image/Image';

interface IProps {
  tr: IUserInvoiceTr;
  handleSearchInvoices: () => void;
  setSelectedImage: React.Dispatch<React.SetStateAction<string>>;
}

const Invoice: FC<IProps> = ({
  tr,
  handleSearchInvoices,
  setSelectedImage,
}) => {
  const infoRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch<Dispatch>();

  const [imageWidth, setImageWidth] = useState(0);
  const [approved, setApproved] = useState(false);

  const handleImageClick = () => {
    setSelectedImage(tr.imageUrl || assets.preview);
  };

  const handleUpdateStatus = async () => {
    try {
      await updateAppointMentStatus({
        appiontmentId: tr.appointment_id,
        status: tr.status === 0 ? 4 : 0,
      });
      setApproved(tr.status === 0 ? true : false);
      dispatch.utils.closeConfirmationModal();
      handleSearchInvoices();
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        console.log(err.response?.data);
      }
    }
  };

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch.utils.openConfirmationModal({
      actionTitle: 'Yes',
      message:
        tr.status === 0
          ? 'Are you sure you want to confirm this appointment'
          : 'Are you sure you want to unconfirm this appointment',
      action: handleUpdateStatus,
    });
  };

  useEffect(() => {
    if (infoRef.current) {
      setImageWidth(infoRef.current.offsetHeight);
    }
    setApproved(tr.status === 4 ? true : false);
  }, [tr]);

  return (
    <Container>
      <div className='main row'>
        <div
          className='image-box clickable'
          onClick={handleImageClick}
          style={{ width: imageWidth + 'px' }}
        >
          <Image url={tr.imageUrl} />
        </div>
        <div className='info' ref={infoRef}>
          <div className='col'>
            <div className='info-item'>
              <p className='font-size-14 text-primary semi-bold'>Invoice ID</p>
              <p className='font-size-13 text-secondary medium'>
                {tr.invoice_id}
              </p>
            </div>
            <div className='info-item'>
              <p className='font-size-14 text-primary semi-bold'>User Name</p>
              <p className='font-size-13 text-secondary medium'>
                {tr.client_name} {tr.client_last_name}
              </p>
            </div>
            <div className='info-item'>
              <p className='font-size-14 text-primary semi-bold'>
                User Phone Number
              </p>
              <p className='font-size-13 text-secondary medium'>
                {tr.client_mobile}
              </p>
            </div>
            <div className='info-item'>
              <p className='font-size-14 text-primary semi-bold'>User Email</p>
              <p className='font-size-13 text-secondary medium'>
                {tr.client_email}
              </p>
            </div>
          </div>
          <div className='col'>
            <div className='info-item'>
              <p className='font-size-14 text-primary semi-bold'>Lawyer Name</p>
              <p className='font-size-13 text-secondary medium'>
                {tr.lawyer_name} {tr.lawyer_last_name}
              </p>
            </div>
            <div className='info-item'>
              <p className='font-size-14 text-primary semi-bold'>
                Lawyer Phone Number
              </p>
              <p className='font-size-13 text-secondary medium'>
                {tr.lawyer_mobile}
              </p>
            </div>
            <div className='info-item'>
              <p className='font-size-14 text-primary semi-bold'>
                Lawyer Email
              </p>
              <p className='font-size-13 text-secondary medium'>
                {tr.lawyer_email}
              </p>
            </div>
          </div>
          <div className='col'>
            <div className='wrapper'>
              <div className='info-item'>
                <p className='font-size-14 text-primary semi-bold'>
                  Appointment Type
                </p>
                <p className='font-size-13 text-secondary medium'>
                  {tr.category_name}
                </p>
              </div>
              <div className='info-item'>
                <p className='font-size-14 text-primary semi-bold'>Date</p>
                <p className='font-size-13 text-secondary medium'>
                  {dayjs(tr.date).format('dd MM, YYYY')} ,{' '}
                  {getFormatedHours(tr.time)}
                </p>
              </div>
            </div>
            <div>
              <p
                className='font-size-14 text-primary semi-bold'
                style={{ marginBottom: '3px' }}
              >
                Approved Payment
              </p>
              <Switch checked={approved} onChange={handleClick} />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Invoice;

const Container = styled.div`
  padding: 30px 33px;
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 6px;

  & .main {
    gap: 33px;
    align-items: stretch;

    & .image-box {
      aspect-ratio: 1/1;
      flex-shrink: 0;
      border-radius: 7px;
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  & .info {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    gap: 10px;

    & .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-block: 1px 18px;

      &:last-of-type {
        justify-content: space-between;
      }

      & .wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
      }
    }
  }
`;
