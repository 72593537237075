const navIcons = {
  dashboard: require('./dashboard.svg').default,
  dashboardActive: require('./dashboardActive.svg').default,
  client: require('./client.svg').default,
  lawyer: require('./lawyer.svg').default,
  category: require('./category.svg').default,
  help: require('./help.svg').default,
  chat: require('./chat.svg').default,
  reported: require('./reported.svg').default,
  settings: require('./settings.svg').default,
  logout: require('./logout.svg').default,
  clientActive: require('./clientActive.svg').default,
  categoryActive: require('./categoryActive.svg').default,
  lawyerActive: require('./lawyerActive.svg').default,
  helpActive: require('./helpActive.svg').default,
  reportedActive: require('./reportedActive.svg').default,
  chatActive: require('./chatActive.svg').default,
  settingsActive: require('./settingActive.svg').default,
  invoice: require('./invoice.svg').default,
  invoiceActive: require('./invoiceActive.svg').default,
  payment: require('./payment.svg').default,
  paymentActive: require('./payment-active.svg').default,
};

export default navIcons;
