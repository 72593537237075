import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  changePassword,
  forgotPassword,
  getAgoraChatToken,
  login,
  resetPassword,
  updateAdminUser,
} from '../../http/services/authService';
import {
  ILoginPayload,
  IForgotPasswordPayload,
  IResetPasswordPayload,
  IUpdateAdminPayload,
  IChangePasswordPayload,
} from '../../types/modalInterface';
import { IUser } from '../../types/interfaces';
import { Token } from '../../types/enums';
import { apiKey } from '../../app.config';
import { currentUser, getStats } from '../../http/services/userService';
import axios from 'axios';
import { ROUTES } from '../../constants';
import { IStats } from '../../types/apiInterface';

interface IState {
  loading: boolean;
  user: IUser | null;
  isLoggedIn: boolean;
  stats: IStats | null;
  agoraToken: string;
}

export const auth = createModel<RootModel>()({
  name: 'auth',
  state: {
    loading: false,
    user: null,
    isLoggedIn: !!localStorage.getItem(Token.TOKEN),
    stats: null,
    agoraToken: '',
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setIsLoggedIn(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    setUser(state, payload: IUser) {
      state.user = payload;
    },
    setStats(state, payload: IStats) {
      state.stats = payload;
    },
    setAgoraToken(state, payload: string) {
      state.agoraToken = payload;
    },
  },
  effects: dispatch => ({
    async handleLogin(payload: ILoginPayload) {
      const { values, navigate, formik } = payload;
      try {
        dispatch.auth.setLoading(true);
        const { data } = await login(values, {
          headers: {
            [Token.API_KEY]: apiKey,
          },
        });
        const { accessToken, refreshToken, ...user } = data.data;
        localStorage.setItem(Token.TOKEN, accessToken);
        const { data: res } = await getAgoraChatToken();
        dispatch.auth.setUser(user);
        dispatch.auth.setIsLoggedIn(true);
        dispatch.auth.setAgoraToken(res.data);
        navigate('/dashboard');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          formik.setErrors({
            email: 'Invalid credentials',
            password: 'Invalid credentials',
          });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleForgotPassword(payload: IForgotPasswordPayload) {
      const { values, formik, navigate } = payload;
      try {
        dispatch.auth.setLoading(true);
        const { data } = await forgotPassword(values, {
          headers: {
            [Token.API_KEY]: apiKey,
          },
        });
        navigate(ROUTES.RESET_PASSWORD, {
          state: {
            token: data.data.token,
          },
        });
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const message = err.response?.data.message;
          formik.setErrors({ email: message });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleResetPassword(payload: IResetPasswordPayload) {
      const { values, token, navigate, formik } = payload;
      try {
        dispatch.auth.setLoading(true);
        await resetPassword(values, token, {
          headers: {
            [Token.API_KEY]: apiKey,
          },
        });
        navigate(ROUTES.LOGIN);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const message = err.response?.data.message;
          formik.setErrors({ code: message });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleGetCurrentUser() {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await currentUser();
        const { data: res } = await getAgoraChatToken();
        dispatch.auth.setAgoraToken(res.data);
        dispatch.auth.setUser(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleUpdateAdminUser(payload: IUpdateAdminPayload, state) {
      const { values } = payload;
      const { user } = state.auth;
      try {
        dispatch.auth.setLoading(true);
        await updateAdminUser(values, user!.id);
        dispatch.auth.handleGetCurrentUser();
        dispatch.utils.openSuccessModal('Profile updated successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleChangePassword(payload: IChangePasswordPayload) {
      const { values, formik, setOpen } = payload;
      try {
        dispatch.auth.setLoading(true);
        await changePassword(values);
        setOpen(false);
        dispatch.utils.openSuccessModal('Password updated successfully.');
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          formik.setErrors({ password: err.response?.data.message });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async handleGetStats() {
      try {
        dispatch.auth.setLoading(true);
        const { data } = await getStats();
        dispatch.auth.setStats(data.data);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log(err.response?.data.message);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
  }),
});
