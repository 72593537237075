import { FC, InputHTMLAttributes, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { Icon } from '@iconify/react';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  variant: 'primary' | 'secondary';
  leftIcon?: React.ReactNode | string;
  rightIcon?: React.ReactNode | string;
  label?: string;
  inputClassName?: string;
  containerClassName?: string;
  labelClassName?: string;
  required?: boolean;
  [x: string]: any;
}

const TextField: FC<IProps> = props => {
  const {
    variant = 'primary',
    leftIcon,
    rightIcon,
    placeholder,
    onChange,
    name,
    error,
    type,
    value,
    label,
    inputClassName,
    containerClassName,
    labelClassName,
    required,
    ...rest
  } = props;

  const [inputType, setInputType] = useState(type);

  const handleEyeClick = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <Container>
      {label && (
        <label htmlFor={`${name}`}>
          <p
            className={classNames(
              'font-size-16 text-secondary semi-bold',
              labelClassName
            )}
          >
            {label} {required && <span className='text-danger'>*</span>}
          </p>
        </label>
      )}
      <InputContainer
        className={classNames(
          'row',
          variant,
          containerClassName,
          error ? 'error' : ''
        )}
      >
        {variant === 'primary' && leftIcon && (
          <div className={classNames('icon-container', 'left-icon', variant)}>
            {typeof leftIcon === 'string' && <img src={leftIcon} alt='' />}
          </div>
        )}
        {variant === 'secondary' && leftIcon && leftIcon}
        <input
          id={name}
          type={inputType}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={inputClassName}
          {...rest}
        />
        {type === 'password' && (
          <EyeIconContainer onClick={handleEyeClick}>
            <Icon
              icon={
                inputType === 'password' ? 'ph:eye-light' : 'ph:eye-slash-light'
              }
              className='eye-icon'
            />
          </EyeIconContainer>
        )}
        {rightIcon && rightIcon}
      </InputContainer>
      {error && <p className='font-size-14 text-danger'>{error}</p>}
    </Container>
  );
};

export default TextField;

const Container = styled.div`
  width: 100%;
  max-width: 650px;

  & .text-danger {
    margin-top: 3px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  height: 51px;
  border-radius: 7px;
  overflow: hidden;
  opacity: 0.6;
  &:not(.error):has(input:focus) {
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px,
      rgb(51, 51, 51, 0.2) 0px 0px 0px 3px;
  }

  &:has(input:not(:placeholder-shown)) {
    opacity: 1;
  }

  & input {
    flex-grow: 1;
    outline: none;
    border: none;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    font-weight: 400;
    background-color: transparent;

    &::placeholder {
      color: ${({ theme }) => theme.colors.primary + '66'};
    }
  }

  &.secondary {
    gap: 10px;
    padding: 0px 15px;
    margin-top: 16px;
    &::placeholder {
      color: ${({ theme }) => theme.colors.primary};
      opacity: 0.1;
    }
  }

  & .left-icon.secondary {
    height: 51px;
    width: 58px;
    display: grid;
    place-items: center;
    border-right: 1px solid ${({ theme }) => theme.colors.primary};
  }

  &.error {
    border-color: ${({ theme }) => theme.colors.text.danger}!important;
  }
`;

const EyeIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & .eye-icon {
    font-size: 25px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
