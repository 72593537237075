import React, { FC, createContext, useEffect } from 'react';

import AC from 'agora-chat';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { agoraKey } from '../app.config';

const connection = new AC.connection({
  appKey: agoraKey!,
});

export const AgoraContext = createContext(connection);

interface IProps {
  children: React.ReactNode;
}

const AgoraProvider: FC<IProps> = ({ children }) => {
  const { user, agoraToken } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (connection && user) {
      connection.open({
        user: user.agora_user_name,
        agoraToken: agoraToken,
      });
    }
    return () => {
      if (connection.isOpened()) {
        connection.close();
      }
    };
    // eslint-disable-next-line
  }, [user, connection]);

  return (
    <AgoraContext.Provider value={connection}>{children}</AgoraContext.Provider>
  );
};

export default AgoraProvider;
